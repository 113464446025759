@font-face {
  font-family: 'sharp';
  src:  url('fonts/sharp.eot?ilotcq');
  src:  url('fonts/sharp.eot?ilotcq#iefix') format('embedded-opentype'),
    url('fonts/sharp.ttf?ilotcq') format('truetype'),
    url('fonts/sharp.woff?ilotcq') format('woff'),
    url('fonts/sharp.svg?ilotcq#sharp') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-sharp"], [class*=" icon-sharp"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sharp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sharpd_rotation:before {
  content: "\e900";
}
.icon-sharpk:before {
  content: "\e901";
}
.icon-sharpg:before {
  content: "\e902";
}
.icon-sharpft_apart:before {
  content: "\e903";
}
.icon-sharp360:before {
  content: "\e904";
}
.icon-sharpac_unit:before {
  content: "\e905";
}
.icon-sharpaccess_alarm:before {
  content: "\e906";
}
.icon-sharpaccess_alarms:before {
  content: "\e907";
}
.icon-sharpaccess_time:before {
  content: "\e908";
}
.icon-sharpaccessibility:before {
  content: "\e909";
}
.icon-sharpaccessibility_new:before {
  content: "\e90a";
}
.icon-sharpaccessible:before {
  content: "\e90b";
}
.icon-sharpaccessible_forward:before {
  content: "\e90c";
}
.icon-sharpaccount_balance:before {
  content: "\e90d";
}
.icon-sharpaccount_balance_wallet:before {
  content: "\e90e";
}
.icon-sharpaccount_box:before {
  content: "\e90f";
}
.icon-sharpaccount_circle:before {
  content: "\e910";
}
.icon-sharpaccount_tree:before {
  content: "\e911";
}
.icon-sharpad_units:before {
  content: "\e912";
}
.icon-sharpadb:before {
  content: "\e913";
}
.icon-sharpadd:before {
  content: "\e914";
}
.icon-sharpadd_a_photo:before {
  content: "\e915";
}
.icon-sharpadd_alarm:before {
  content: "\e916";
}
.icon-sharpadd_alert:before {
  content: "\e917";
}
.icon-sharpadd_box:before {
  content: "\e918";
}
.icon-sharpadd_business:before {
  content: "\e919";
}
.icon-sharpadd_circle:before {
  content: "\e91a";
}
.icon-sharpadd_circle_outline:before {
  content: "\e91b";
}
.icon-sharpadd_comment:before {
  content: "\e91c";
}
.icon-sharpadd_ic_call:before {
  content: "\e91d";
}
.icon-sharpadd_location:before {
  content: "\e91e";
}
.icon-sharpadd_location_alt:before {
  content: "\e91f";
}
.icon-sharpadd_photo_alternate:before {
  content: "\e920";
}
.icon-sharpadd_road:before {
  content: "\e921";
}
.icon-sharpadd_shopping_cart:before {
  content: "\e922";
}
.icon-sharpadd_task:before {
  content: "\e923";
}
.icon-sharpadd_to_home_screen:before {
  content: "\e924";
}
.icon-sharpadd_to_photos:before {
  content: "\e925";
}
.icon-sharpadd_to_queue:before {
  content: "\e926";
}
.icon-sharpaddchart:before {
  content: "\e927";
}
.icon-sharpadjust:before {
  content: "\e928";
}
.icon-sharpadmin_panel_settings:before {
  content: "\e929";
}
.icon-sharpagriculture:before {
  content: "\e92a";
}
.icon-sharpairline_seat_flat:before {
  content: "\e92b";
}
.icon-sharpairline_seat_flat_angled:before {
  content: "\e92c";
}
.icon-sharpairline_seat_individual_suite:before {
  content: "\e92d";
}
.icon-sharpairline_seat_legroom_extra:before {
  content: "\e92e";
}
.icon-sharpairline_seat_legroom_normal:before {
  content: "\e92f";
}
.icon-sharpairline_seat_legroom_reduced:before {
  content: "\e930";
}
.icon-sharpairline_seat_recline_extra:before {
  content: "\e931";
}
.icon-sharpairline_seat_recline_normal:before {
  content: "\e932";
}
.icon-sharpairplanemode_active:before {
  content: "\e933";
}
.icon-sharpairplanemode_inactive:before {
  content: "\e934";
}
.icon-sharpairplay:before {
  content: "\e935";
}
.icon-sharpairport_shuttle:before {
  content: "\e936";
}
.icon-sharpalarm:before {
  content: "\e937";
}
.icon-sharpalarm_add:before {
  content: "\e938";
}
.icon-sharpalarm_off:before {
  content: "\e939";
}
.icon-sharpalarm_on:before {
  content: "\e93a";
}
.icon-sharpalbum:before {
  content: "\e93b";
}
.icon-sharpall_inbox:before {
  content: "\e93c";
}
.icon-sharpall_inclusive:before {
  content: "\e93d";
}
.icon-sharpall_out:before {
  content: "\e93e";
}
.icon-sharpalt_route:before {
  content: "\e93f";
}
.icon-sharpalternate_email:before {
  content: "\e940";
}
.icon-sharpamp_stories:before {
  content: "\e941";
}
.icon-sharpanalytics:before {
  content: "\e942";
}
.icon-sharpanchor:before {
  content: "\e943";
}
.icon-sharpandroid:before {
  content: "\e944";
}
.icon-sharpannouncement:before {
  content: "\e945";
}
.icon-sharpapartment:before {
  content: "\e946";
}
.icon-sharpapi:before {
  content: "\e947";
}
.icon-sharpapp_blocking:before {
  content: "\e948";
}
.icon-sharpapp_settings_alt:before {
  content: "\e949";
}
.icon-sharpapps:before {
  content: "\e94a";
}
.icon-sharparchitecture:before {
  content: "\e94b";
}
.icon-sharparchive:before {
  content: "\e94c";
}
.icon-sharparrow_back:before {
  content: "\e94d";
}
.icon-sharparrow_back_ios:before {
  content: "\e94e";
}
.icon-sharparrow_circle_down:before {
  content: "\e94f";
}
.icon-sharparrow_circle_up:before {
  content: "\e950";
}
.icon-sharparrow_downward:before {
  content: "\e951";
}
.icon-sharparrow_drop_down:before {
  content: "\e952";
}
.icon-sharparrow_drop_down_circle:before {
  content: "\e953";
}
.icon-sharparrow_drop_up:before {
  content: "\e954";
}
.icon-sharparrow_forward:before {
  content: "\e955";
}
.icon-sharparrow_forward_ios:before {
  content: "\e956";
}
.icon-sharparrow_left:before {
  content: "\e957";
}
.icon-sharparrow_right:before {
  content: "\e958";
}
.icon-sharparrow_right_alt:before {
  content: "\e959";
}
.icon-sharparrow_upward:before {
  content: "\e95a";
}
.icon-sharpart_track:before {
  content: "\e95b";
}
.icon-sharparticle:before {
  content: "\e95c";
}
.icon-sharpaspect_ratio:before {
  content: "\e95d";
}
.icon-sharpassessment:before {
  content: "\e95e";
}
.icon-sharpassignment:before {
  content: "\e95f";
}
.icon-sharpassignment_ind:before {
  content: "\e960";
}
.icon-sharpassignment_late:before {
  content: "\e961";
}
.icon-sharpassignment_return:before {
  content: "\e962";
}
.icon-sharpassignment_returned:before {
  content: "\e963";
}
.icon-sharpassignment_turned_in:before {
  content: "\e964";
}
.icon-sharpassistant:before {
  content: "\e965";
}
.icon-sharpassistant_photo:before {
  content: "\e966";
}
.icon-sharpatm:before {
  content: "\e967";
}
.icon-sharpattach_email:before {
  content: "\e968";
}
.icon-sharpattach_file:before {
  content: "\e969";
}
.icon-sharpattach_money:before {
  content: "\e96a";
}
.icon-sharpattachment:before {
  content: "\e96b";
}
.icon-sharpattribution:before {
  content: "\e96c";
}
.icon-sharpaudiotrack:before {
  content: "\e96d";
}
.icon-sharpauto_delete:before {
  content: "\e96e";
}
.icon-sharpautorenew:before {
  content: "\e96f";
}
.icon-sharpav_timer:before {
  content: "\e970";
}
.icon-sharpbaby_changing_station:before {
  content: "\e971";
}
.icon-sharpbackpack:before {
  content: "\e972";
}
.icon-sharpbackspace:before {
  content: "\e973";
}
.icon-sharpbackup:before {
  content: "\e974";
}
.icon-sharpbackup_table:before {
  content: "\e975";
}
.icon-sharpballot:before {
  content: "\e976";
}
.icon-sharpbar_chart:before {
  content: "\e977";
}
.icon-sharpbatch_prediction:before {
  content: "\e978";
}
.icon-sharpbathtub:before {
  content: "\e979";
}
.icon-sharpbattery_alert:before {
  content: "\e97a";
}
.icon-sharpbattery_charging_full:before {
  content: "\e97b";
}
.icon-sharpbattery_full:before {
  content: "\e97c";
}
.icon-sharpbattery_std:before {
  content: "\e97d";
}
.icon-sharpbattery_unknown:before {
  content: "\e97e";
}
.icon-sharpbeach_access:before {
  content: "\e97f";
}
.icon-sharpbedtime:before {
  content: "\e980";
}
.icon-sharpbeenhere:before {
  content: "\e981";
}
.icon-sharpbento:before {
  content: "\e982";
}
.icon-sharpbike_scooter:before {
  content: "\e983";
}
.icon-sharpbiotech:before {
  content: "\e984";
}
.icon-sharpblock:before {
  content: "\e985";
}
.icon-sharpbluetooth:before {
  content: "\e986";
}
.icon-sharpbluetooth_audio:before {
  content: "\e987";
}
.icon-sharpbluetooth_connected:before {
  content: "\e988";
}
.icon-sharpbluetooth_disabled:before {
  content: "\e989";
}
.icon-sharpbluetooth_searching:before {
  content: "\e98a";
}
.icon-sharpblur_circular:before {
  content: "\e98b";
}
.icon-sharpblur_linear:before {
  content: "\e98c";
}
.icon-sharpblur_off:before {
  content: "\e98d";
}
.icon-sharpblur_on:before {
  content: "\e98e";
}
.icon-sharpbook:before {
  content: "\e98f";
}
.icon-sharpbook_online:before {
  content: "\e990";
}
.icon-sharpbookmark:before {
  content: "\e991";
}
.icon-sharpbookmark_border:before {
  content: "\e992";
}
.icon-sharpbookmarks:before {
  content: "\e993";
}
.icon-sharpborder_all:before {
  content: "\e994";
}
.icon-sharpborder_bottom:before {
  content: "\e995";
}
.icon-sharpborder_clear:before {
  content: "\e996";
}
.icon-sharpborder_horizontal:before {
  content: "\e997";
}
.icon-sharpborder_inner:before {
  content: "\e998";
}
.icon-sharpborder_left:before {
  content: "\e999";
}
.icon-sharpborder_outer:before {
  content: "\e99a";
}
.icon-sharpborder_right:before {
  content: "\e99b";
}
.icon-sharpborder_style:before {
  content: "\e99c";
}
.icon-sharpborder_top:before {
  content: "\e99d";
}
.icon-sharpborder_vertical:before {
  content: "\e99e";
}
.icon-sharpbranding_watermark:before {
  content: "\e99f";
}
.icon-sharpbrightness_1:before {
  content: "\e9a0";
}
.icon-sharpbrightness_2:before {
  content: "\e9a1";
}
.icon-sharpbrightness_3:before {
  content: "\e9a2";
}
.icon-sharpbrightness_4:before {
  content: "\e9a3";
}
.icon-sharpbrightness_5:before {
  content: "\e9a4";
}
.icon-sharpbrightness_6:before {
  content: "\e9a5";
}
.icon-sharpbrightness_7:before {
  content: "\e9a6";
}
.icon-sharpbrightness_auto:before {
  content: "\e9a7";
}
.icon-sharpbrightness_high:before {
  content: "\e9a8";
}
.icon-sharpbrightness_low:before {
  content: "\e9a9";
}
.icon-sharpbrightness_medium:before {
  content: "\e9aa";
}
.icon-sharpbroken_image:before {
  content: "\e9ab";
}
.icon-sharpbrowser_not_supported:before {
  content: "\e9ac";
}
.icon-sharpbrush:before {
  content: "\e9ad";
}
.icon-sharpbubble_chart:before {
  content: "\e9ae";
}
.icon-sharpbug_report:before {
  content: "\e9af";
}
.icon-sharpbuild:before {
  content: "\e9b0";
}
.icon-sharpbuild_circle:before {
  content: "\e9b1";
}
.icon-sharpburst_mode:before {
  content: "\e9b2";
}
.icon-sharpbusiness:before {
  content: "\e9b3";
}
.icon-sharpbusiness_center:before {
  content: "\e9b4";
}
.icon-sharpcached:before {
  content: "\e9b5";
}
.icon-sharpcake:before {
  content: "\e9b6";
}
.icon-sharpcalculate:before {
  content: "\e9b7";
}
.icon-sharpcalendar_today:before {
  content: "\e9b8";
}
.icon-sharpcalendar_view_day:before {
  content: "\e9b9";
}
.icon-sharpcall:before {
  content: "\e9ba";
}
.icon-sharpcall_end:before {
  content: "\e9bb";
}
.icon-sharpcall_made:before {
  content: "\e9bc";
}
.icon-sharpcall_merge:before {
  content: "\e9bd";
}
.icon-sharpcall_missed:before {
  content: "\e9be";
}
.icon-sharpcall_missed_outgoing:before {
  content: "\e9bf";
}
.icon-sharpcall_received:before {
  content: "\e9c0";
}
.icon-sharpcall_split:before {
  content: "\e9c1";
}
.icon-sharpcall_to_action:before {
  content: "\e9c2";
}
.icon-sharpcamera:before {
  content: "\e9c3";
}
.icon-sharpcamera_alt:before {
  content: "\e9c4";
}
.icon-sharpcamera_enhance:before {
  content: "\e9c5";
}
.icon-sharpcamera_front:before {
  content: "\e9c6";
}
.icon-sharpcamera_rear:before {
  content: "\e9c7";
}
.icon-sharpcamera_roll:before {
  content: "\e9c8";
}
.icon-sharpcampaign:before {
  content: "\e9c9";
}
.icon-sharpcancel:before {
  content: "\e9ca";
}
.icon-sharpcancel_presentation:before {
  content: "\e9cb";
}
.icon-sharpcancel_schedule_send:before {
  content: "\e9cc";
}
.icon-sharpcard_giftcard:before {
  content: "\e9cd";
}
.icon-sharpcard_membership:before {
  content: "\e9ce";
}
.icon-sharpcard_travel:before {
  content: "\e9cf";
}
.icon-sharpcarpenter:before {
  content: "\e9d0";
}
.icon-sharpcasino:before {
  content: "\e9d1";
}
.icon-sharpcast:before {
  content: "\e9d2";
}
.icon-sharpcast_connected:before {
  content: "\e9d3";
}
.icon-sharpcast_for_education:before {
  content: "\e9d4";
}
.icon-sharpcategory:before {
  content: "\e9d5";
}
.icon-sharpcenter_focus_strong:before {
  content: "\e9d6";
}
.icon-sharpcenter_focus_weak:before {
  content: "\e9d7";
}
.icon-sharpchange_history:before {
  content: "\e9d8";
}
.icon-sharpcharging_station:before {
  content: "\e9d9";
}
.icon-sharpchat:before {
  content: "\e9da";
}
.icon-sharpchat_bubble:before {
  content: "\e9db";
}
.icon-sharpchat_bubble_outline:before {
  content: "\e9dc";
}
.icon-sharpcheck:before {
  content: "\e9dd";
}
.icon-sharpcheck_box:before {
  content: "\e9de";
}
.icon-sharpcheck_box_outline_blank:before {
  content: "\e9df";
}
.icon-sharpcheck_circle:before {
  content: "\e9e0";
}
.icon-sharpcheck_circle_outline:before {
  content: "\e9e1";
}
.icon-sharpcheckroom:before {
  content: "\e9e2";
}
.icon-sharpchevron_left:before {
  content: "\e9e3";
}
.icon-sharpchevron_right:before {
  content: "\e9e4";
}
.icon-sharpchild_care:before {
  content: "\e9e5";
}
.icon-sharpchild_friendly:before {
  content: "\e9e6";
}
.icon-sharpchrome_reader_mode:before {
  content: "\e9e7";
}
.icon-sharpclass:before {
  content: "\e9e8";
}
.icon-sharpclean_hands:before {
  content: "\e9e9";
}
.icon-sharpcleaning_services:before {
  content: "\e9ea";
}
.icon-sharpclear:before {
  content: "\e9eb";
}
.icon-sharpclear_all:before {
  content: "\e9ec";
}
.icon-sharpclose:before {
  content: "\e9ed";
}
.icon-sharpclose_fullscreen:before {
  content: "\e9ee";
}
.icon-sharpclosed_caption:before {
  content: "\e9ef";
}
.icon-sharpclosed_caption_disabled:before {
  content: "\e9f0";
}
.icon-sharpcloud:before {
  content: "\e9f1";
}
.icon-sharpcloud_circle:before {
  content: "\e9f2";
}
.icon-sharpcloud_done:before {
  content: "\e9f3";
}
.icon-sharpcloud_download:before {
  content: "\e9f4";
}
.icon-sharpcloud_off:before {
  content: "\e9f5";
}
.icon-sharpcloud_queue:before {
  content: "\e9f6";
}
.icon-sharpcloud_upload:before {
  content: "\e9f7";
}
.icon-sharpcode:before {
  content: "\e9f8";
}
.icon-sharpcollections:before {
  content: "\e9f9";
}
.icon-sharpcollections_bookmark:before {
  content: "\e9fa";
}
.icon-sharpcolor_lens:before {
  content: "\e9fb";
}
.icon-sharpcolorize:before {
  content: "\e9fc";
}
.icon-sharpcomment:before {
  content: "\e9fd";
}
.icon-sharpcomment_bank:before {
  content: "\e9fe";
}
.icon-sharpcommute:before {
  content: "\e9ff";
}
.icon-sharpcompare:before {
  content: "\ea00";
}
.icon-sharpcompare_arrows:before {
  content: "\ea01";
}
.icon-sharpcompass_calibration:before {
  content: "\ea02";
}
.icon-sharpcomputer:before {
  content: "\ea03";
}
.icon-sharpconfirmation_number:before {
  content: "\ea04";
}
.icon-sharpconnect_without_contact:before {
  content: "\ea05";
}
.icon-sharpconstruction:before {
  content: "\ea06";
}
.icon-sharpcontact_mail:before {
  content: "\ea07";
}
.icon-sharpcontact_page:before {
  content: "\ea08";
}
.icon-sharpcontact_phone:before {
  content: "\ea09";
}
.icon-sharpcontact_support:before {
  content: "\ea0a";
}
.icon-sharpcontactless:before {
  content: "\ea0b";
}
.icon-sharpcontacts:before {
  content: "\ea0c";
}
.icon-sharpcontent_copy:before {
  content: "\ea0d";
}
.icon-sharpcontent_cut:before {
  content: "\ea0e";
}
.icon-sharpcontent_paste:before {
  content: "\ea0f";
}
.icon-sharpcontrol_camera:before {
  content: "\ea10";
}
.icon-sharpcontrol_point:before {
  content: "\ea11";
}
.icon-sharpcontrol_point_duplicate:before {
  content: "\ea12";
}
.icon-sharpcopyright:before {
  content: "\ea13";
}
.icon-sharpcoronavirus:before {
  content: "\ea14";
}
.icon-sharpcorporate_fare:before {
  content: "\ea15";
}
.icon-sharpcountertops:before {
  content: "\ea16";
}
.icon-sharpcreate:before {
  content: "\ea17";
}
.icon-sharpcreate_new_folder:before {
  content: "\ea18";
}
.icon-sharpcredit_card:before {
  content: "\ea19";
}
.icon-sharpcrop:before {
  content: "\ea1a";
}
.icon-sharpcrop_3_2:before {
  content: "\ea1b";
}
.icon-sharpcrop_5_4:before {
  content: "\ea1c";
}
.icon-sharpcrop_7_5:before {
  content: "\ea1d";
}
.icon-sharpcrop_16_9:before {
  content: "\ea1e";
}
.icon-sharpcrop_din:before {
  content: "\ea1f";
}
.icon-sharpcrop_free:before {
  content: "\ea20";
}
.icon-sharpcrop_landscape:before {
  content: "\ea21";
}
.icon-sharpcrop_original:before {
  content: "\ea22";
}
.icon-sharpcrop_portrait:before {
  content: "\ea23";
}
.icon-sharpcrop_rotate:before {
  content: "\ea24";
}
.icon-sharpcrop_square:before {
  content: "\ea25";
}
.icon-sharpdashboard:before {
  content: "\ea26";
}
.icon-sharpdata_usage:before {
  content: "\ea27";
}
.icon-sharpdate_range:before {
  content: "\ea28";
}
.icon-sharpdeck:before {
  content: "\ea29";
}
.icon-sharpdehaze:before {
  content: "\ea2a";
}
.icon-sharpdelete:before {
  content: "\ea2b";
}
.icon-sharpdelete_forever:before {
  content: "\ea2c";
}
.icon-sharpdelete_outline:before {
  content: "\ea2d";
}
.icon-sharpdelete_sweep:before {
  content: "\ea2e";
}
.icon-sharpdeparture_board:before {
  content: "\ea2f";
}
.icon-sharpdescription:before {
  content: "\ea30";
}
.icon-sharpdesign_services:before {
  content: "\ea31";
}
.icon-sharpdesktop_access_disabled:before {
  content: "\ea32";
}
.icon-sharpdesktop_mac:before {
  content: "\ea33";
}
.icon-sharpdesktop_windows:before {
  content: "\ea34";
}
.icon-sharpdetails:before {
  content: "\ea35";
}
.icon-sharpdeveloper_board:before {
  content: "\ea36";
}
.icon-sharpdeveloper_mode:before {
  content: "\ea37";
}
.icon-sharpdevice_hub:before {
  content: "\ea38";
}
.icon-sharpdevice_unknown:before {
  content: "\ea39";
}
.icon-sharpdevices:before {
  content: "\ea3a";
}
.icon-sharpdevices_other:before {
  content: "\ea3b";
}
.icon-sharpdialer_sip:before {
  content: "\ea3c";
}
.icon-sharpdialpad:before {
  content: "\ea3d";
}
.icon-sharpdirections:before {
  content: "\ea3e";
}
.icon-sharpdirections_bike:before {
  content: "\ea3f";
}
.icon-sharpdirections_boat:before {
  content: "\ea40";
}
.icon-sharpdirections_bus:before {
  content: "\ea41";
}
.icon-sharpdirections_car:before {
  content: "\ea42";
}
.icon-sharpdirections_off:before {
  content: "\ea43";
}
.icon-sharpdirections_railway:before {
  content: "\ea44";
}
.icon-sharpdirections_run:before {
  content: "\ea45";
}
.icon-sharpdirections_subway:before {
  content: "\ea46";
}
.icon-sharpdirections_transit:before {
  content: "\ea47";
}
.icon-sharpdirections_walk:before {
  content: "\ea48";
}
.icon-sharpdisabled_by_default:before {
  content: "\ea49";
}
.icon-sharpdisc_full:before {
  content: "\ea4a";
}
.icon-sharpdns:before {
  content: "\ea4b";
}
.icon-sharpdo_disturb:before {
  content: "\ea4c";
}
.icon-sharpdo_disturb_alt:before {
  content: "\ea4d";
}
.icon-sharpdo_disturb_off:before {
  content: "\ea4e";
}
.icon-sharpdo_disturb_on:before {
  content: "\ea4f";
}
.icon-sharpdo_not_step:before {
  content: "\ea50";
}
.icon-sharpdo_not_touch:before {
  content: "\ea51";
}
.icon-sharpdock:before {
  content: "\ea52";
}
.icon-sharpdomain:before {
  content: "\ea53";
}
.icon-sharpdomain_disabled:before {
  content: "\ea54";
}
.icon-sharpdomain_verification:before {
  content: "\ea55";
}
.icon-sharpdone:before {
  content: "\ea56";
}
.icon-sharpdone_all:before {
  content: "\ea57";
}
.icon-sharpdone_outline:before {
  content: "\ea58";
}
.icon-sharpdonut_large:before {
  content: "\ea59";
}
.icon-sharpdonut_small:before {
  content: "\ea5a";
}
.icon-sharpdouble_arrow:before {
  content: "\ea5b";
}
.icon-sharpdownload:before {
  content: "\ea5c";
}
.icon-sharpdownload_done:before {
  content: "\ea5d";
}
.icon-sharpdrafts:before {
  content: "\ea5e";
}
.icon-sharpdrag_handle:before {
  content: "\ea5f";
}
.icon-sharpdrag_indicator:before {
  content: "\ea60";
}
.icon-sharpdrive_eta:before {
  content: "\ea61";
}
.icon-sharpdry:before {
  content: "\ea62";
}
.icon-sharpduo:before {
  content: "\ea63";
}
.icon-sharpdvr:before {
  content: "\ea64";
}
.icon-sharpdynamic_feed:before {
  content: "\ea65";
}
.icon-sharpdynamic_form:before {
  content: "\ea66";
}
.icon-sharpeast:before {
  content: "\ea67";
}
.icon-sharpeco:before {
  content: "\ea68";
}
.icon-sharpedit:before {
  content: "\ea69";
}
.icon-sharpedit_attributes:before {
  content: "\ea6a";
}
.icon-sharpedit_location:before {
  content: "\ea6b";
}
.icon-sharpedit_road:before {
  content: "\ea6c";
}
.icon-sharpeject:before {
  content: "\ea6d";
}
.icon-sharpelderly:before {
  content: "\ea6e";
}
.icon-sharpelectric_bike:before {
  content: "\ea6f";
}
.icon-sharpelectric_car:before {
  content: "\ea70";
}
.icon-sharpelectric_moped:before {
  content: "\ea71";
}
.icon-sharpelectric_rickshaw:before {
  content: "\ea72";
}
.icon-sharpelectric_scooter:before {
  content: "\ea73";
}
.icon-sharpelectrical_services:before {
  content: "\ea74";
}
.icon-sharpelevator:before {
  content: "\ea75";
}
.icon-sharpemail:before {
  content: "\ea76";
}
.icon-sharpemoji_emotions:before {
  content: "\ea77";
}
.icon-sharpemoji_events:before {
  content: "\ea78";
}
.icon-sharpemoji_flags:before {
  content: "\ea79";
}
.icon-sharpemoji_food_beverage:before {
  content: "\ea7a";
}
.icon-sharpemoji_nature:before {
  content: "\ea7b";
}
.icon-sharpemoji_objects:before {
  content: "\ea7c";
}
.icon-sharpemoji_people:before {
  content: "\ea7d";
}
.icon-sharpemoji_symbols:before {
  content: "\ea7e";
}
.icon-sharpemoji_transportation:before {
  content: "\ea7f";
}
.icon-sharpengineering:before {
  content: "\ea80";
}
.icon-sharpenhanced_encryption:before {
  content: "\ea81";
}
.icon-sharpequalizer:before {
  content: "\ea82";
}
.icon-sharperror:before {
  content: "\ea83";
}
.icon-sharperror_outline:before {
  content: "\ea84";
}
.icon-sharpescalator:before {
  content: "\ea85";
}
.icon-sharpescalator_warning:before {
  content: "\ea86";
}
.icon-sharpeuro:before {
  content: "\ea87";
}
.icon-sharpeuro_symbol:before {
  content: "\ea88";
}
.icon-sharpev_station:before {
  content: "\ea89";
}
.icon-sharpevent:before {
  content: "\ea8a";
}
.icon-sharpevent_available:before {
  content: "\ea8b";
}
.icon-sharpevent_busy:before {
  content: "\ea8c";
}
.icon-sharpevent_note:before {
  content: "\ea8d";
}
.icon-sharpevent_seat:before {
  content: "\ea8e";
}
.icon-sharpexit_to_app:before {
  content: "\ea8f";
}
.icon-sharpexpand_less:before {
  content: "\ea90";
}
.icon-sharpexpand_more:before {
  content: "\ea91";
}
.icon-sharpexplicit:before {
  content: "\ea92";
}
.icon-sharpexplore:before {
  content: "\ea93";
}
.icon-sharpexplore_off:before {
  content: "\ea94";
}
.icon-sharpexposure:before {
  content: "\ea95";
}
.icon-sharpexposure_neg_1:before {
  content: "\ea96";
}
.icon-sharpexposure_neg_2:before {
  content: "\ea97";
}
.icon-sharpexposure_plus_1:before {
  content: "\ea98";
}
.icon-sharpexposure_plus_2:before {
  content: "\ea99";
}
.icon-sharpexposure_zero:before {
  content: "\ea9a";
}
.icon-sharpextension:before {
  content: "\ea9b";
}
.icon-sharpface:before {
  content: "\ea9c";
}
.icon-sharpface_unlock:before {
  content: "\ea9d";
}
.icon-sharpfacebook:before {
  content: "\ea9e";
}
.icon-sharpfact_check:before {
  content: "\ea9f";
}
.icon-sharpfamily_restroom:before {
  content: "\eaa0";
}
.icon-sharpfast_forward:before {
  content: "\eaa1";
}
.icon-sharpfast_rewind:before {
  content: "\eaa2";
}
.icon-sharpfastfood:before {
  content: "\eaa3";
}
.icon-sharpfavorite:before {
  content: "\eaa4";
}
.icon-sharpfavorite_border:before {
  content: "\eaa5";
}
.icon-sharpfeatured_play_list:before {
  content: "\eaa6";
}
.icon-sharpfeatured_video:before {
  content: "\eaa7";
}
.icon-sharpfeedback:before {
  content: "\eaa8";
}
.icon-sharpfence:before {
  content: "\eaa9";
}
.icon-sharpfiber_dvr:before {
  content: "\eaaa";
}
.icon-sharpfiber_manual_record:before {
  content: "\eaab";
}
.icon-sharpfiber_new:before {
  content: "\eaac";
}
.icon-sharpfiber_pin:before {
  content: "\eaad";
}
.icon-sharpfiber_smart_record:before {
  content: "\eaae";
}
.icon-sharpfile_copy:before {
  content: "\eaaf";
}
.icon-sharpfilter:before {
  content: "\eab0";
}
.icon-sharpfilter_1:before {
  content: "\eab1";
}
.icon-sharpfilter_2:before {
  content: "\eab2";
}
.icon-sharpfilter_3:before {
  content: "\eab3";
}
.icon-sharpfilter_4:before {
  content: "\eab4";
}
.icon-sharpfilter_5:before {
  content: "\eab5";
}
.icon-sharpfilter_6:before {
  content: "\eab6";
}
.icon-sharpfilter_7:before {
  content: "\eab7";
}
.icon-sharpfilter_8:before {
  content: "\eab8";
}
.icon-sharpfilter_9:before {
  content: "\eab9";
}
.icon-sharpfilter_9_plus:before {
  content: "\eaba";
}
.icon-sharpfilter_alt:before {
  content: "\eabb";
}
.icon-sharpfilter_b_and_w:before {
  content: "\eabc";
}
.icon-sharpfilter_center_focus:before {
  content: "\eabd";
}
.icon-sharpfilter_drama:before {
  content: "\eabe";
}
.icon-sharpfilter_frames:before {
  content: "\eabf";
}
.icon-sharpfilter_hdr:before {
  content: "\eac0";
}
.icon-sharpfilter_list:before {
  content: "\eac1";
}
.icon-sharpfilter_none:before {
  content: "\eac2";
}
.icon-sharpfilter_tilt_shift:before {
  content: "\eac3";
}
.icon-sharpfilter_vintage:before {
  content: "\eac4";
}
.icon-sharpfind_in_page:before {
  content: "\eac5";
}
.icon-sharpfind_replace:before {
  content: "\eac6";
}
.icon-sharpfingerprint:before {
  content: "\eac7";
}
.icon-sharpfire_extinguisher:before {
  content: "\eac8";
}
.icon-sharpfireplace:before {
  content: "\eac9";
}
.icon-sharpfirst_page:before {
  content: "\eaca";
}
.icon-sharpfitness_center:before {
  content: "\eacb";
}
.icon-sharpflag:before {
  content: "\eacc";
}
.icon-sharpflaky:before {
  content: "\eacd";
}
.icon-sharpflare:before {
  content: "\eace";
}
.icon-sharpflash_auto:before {
  content: "\eacf";
}
.icon-sharpflash_off:before {
  content: "\ead0";
}
.icon-sharpflash_on:before {
  content: "\ead1";
}
.icon-sharpflight:before {
  content: "\ead2";
}
.icon-sharpflight_land:before {
  content: "\ead3";
}
.icon-sharpflight_takeoff:before {
  content: "\ead4";
}
.icon-sharpflip:before {
  content: "\ead5";
}
.icon-sharpflip_camera_android:before {
  content: "\ead6";
}
.icon-sharpflip_camera_ios:before {
  content: "\ead7";
}
.icon-sharpflip_to_back:before {
  content: "\ead8";
}
.icon-sharpflip_to_front:before {
  content: "\ead9";
}
.icon-sharpfolder:before {
  content: "\eada";
}
.icon-sharpfolder_open:before {
  content: "\eadb";
}
.icon-sharpfolder_shared:before {
  content: "\eadc";
}
.icon-sharpfolder_special:before {
  content: "\eadd";
}
.icon-sharpfollow_the_signs:before {
  content: "\eade";
}
.icon-sharpfont_download:before {
  content: "\eadf";
}
.icon-sharpfood_bank:before {
  content: "\eae0";
}
.icon-sharpformat_align_center:before {
  content: "\eae1";
}
.icon-sharpformat_align_justify:before {
  content: "\eae2";
}
.icon-sharpformat_align_left:before {
  content: "\eae3";
}
.icon-sharpformat_align_right:before {
  content: "\eae4";
}
.icon-sharpformat_bold:before {
  content: "\eae5";
}
.icon-sharpformat_clear:before {
  content: "\eae6";
}
.icon-sharpformat_color_reset:before {
  content: "\eae7";
}
.icon-sharpformat_indent_decrease:before {
  content: "\eae8";
}
.icon-sharpformat_indent_increase:before {
  content: "\eae9";
}
.icon-sharpformat_italic:before {
  content: "\eaea";
}
.icon-sharpformat_line_spacing:before {
  content: "\eaeb";
}
.icon-sharpformat_list_bulleted:before {
  content: "\eaec";
}
.icon-sharpformat_list_numbered:before {
  content: "\eaed";
}
.icon-sharpformat_list_numbered_rtl:before {
  content: "\eaee";
}
.icon-sharpformat_paint:before {
  content: "\eaef";
}
.icon-sharpformat_quote:before {
  content: "\eaf0";
}
.icon-sharpformat_shapes:before {
  content: "\eaf1";
}
.icon-sharpformat_size:before {
  content: "\eaf2";
}
.icon-sharpformat_strikethrough:before {
  content: "\eaf3";
}
.icon-sharpformat_textdirection_l_to_r:before {
  content: "\eaf4";
}
.icon-sharpformat_textdirection_r_to_l:before {
  content: "\eaf5";
}
.icon-sharpformat_underlined:before {
  content: "\eaf6";
}
.icon-sharpforum:before {
  content: "\eaf7";
}
.icon-sharpforward:before {
  content: "\eaf8";
}
.icon-sharpforward_5:before {
  content: "\eaf9";
}
.icon-sharpforward_10:before {
  content: "\eafa";
}
.icon-sharpforward_30:before {
  content: "\eafb";
}
.icon-sharpforward_to_inbox:before {
  content: "\eafc";
}
.icon-sharpfoundation:before {
  content: "\eafd";
}
.icon-sharpfree_breakfast:before {
  content: "\eafe";
}
.icon-sharpfullscreen:before {
  content: "\eaff";
}
.icon-sharpfullscreen_exit:before {
  content: "\eb00";
}
.icon-sharpfunctions:before {
  content: "\eb01";
}
.icon-sharpg_translate:before {
  content: "\eb02";
}
.icon-sharpgamepad:before {
  content: "\eb03";
}
.icon-sharpgames:before {
  content: "\eb04";
}
.icon-sharpgavel:before {
  content: "\eb05";
}
.icon-sharpgesture:before {
  content: "\eb06";
}
.icon-sharpget_app:before {
  content: "\eb07";
}
.icon-sharpgif:before {
  content: "\eb08";
}
.icon-sharpgolf_course:before {
  content: "\eb09";
}
.icon-sharpgps_fixed:before {
  content: "\eb0a";
}
.icon-sharpgps_not_fixed:before {
  content: "\eb0b";
}
.icon-sharpgps_off:before {
  content: "\eb0c";
}
.icon-sharpgrade:before {
  content: "\eb0d";
}
.icon-sharpgradient:before {
  content: "\eb0e";
}
.icon-sharpgrading:before {
  content: "\eb0f";
}
.icon-sharpgrain:before {
  content: "\eb10";
}
.icon-sharpgraphic_eq:before {
  content: "\eb11";
}
.icon-sharpgrass:before {
  content: "\eb12";
}
.icon-sharpgrid_off:before {
  content: "\eb13";
}
.icon-sharpgrid_on:before {
  content: "\eb14";
}
.icon-sharpgroup:before {
  content: "\eb15";
}
.icon-sharpgroup_add:before {
  content: "\eb16";
}
.icon-sharpgroup_work:before {
  content: "\eb17";
}
.icon-sharpgroups:before {
  content: "\eb18";
}
.icon-sharphandyman:before {
  content: "\eb19";
}
.icon-sharphd:before {
  content: "\eb1a";
}
.icon-sharphdr_off:before {
  content: "\eb1b";
}
.icon-sharphdr_on:before {
  content: "\eb1c";
}
.icon-sharphdr_strong:before {
  content: "\eb1d";
}
.icon-sharphdr_weak:before {
  content: "\eb1e";
}
.icon-sharpheadset:before {
  content: "\eb1f";
}
.icon-sharpheadset_mic:before {
  content: "\eb20";
}
.icon-sharphealing:before {
  content: "\eb21";
}
.icon-sharphearing:before {
  content: "\eb22";
}
.icon-sharphearing_disabled:before {
  content: "\eb23";
}
.icon-sharpheight:before {
  content: "\eb24";
}
.icon-sharphelp:before {
  content: "\eb25";
}
.icon-sharphelp_center:before {
  content: "\eb26";
}
.icon-sharphelp_outline:before {
  content: "\eb27";
}
.icon-sharphigh_quality:before {
  content: "\eb28";
}
.icon-sharphighlight:before {
  content: "\eb29";
}
.icon-sharphighlight_alt:before {
  content: "\eb2a";
}
.icon-sharphighlight_off:before {
  content: "\eb2b";
}
.icon-sharphistory:before {
  content: "\eb2c";
}
.icon-sharphistory_edu:before {
  content: "\eb2d";
}
.icon-sharphistory_toggle_off:before {
  content: "\eb2e";
}
.icon-sharphome:before {
  content: "\eb2f";
}
.icon-sharphome_repair_service:before {
  content: "\eb30";
}
.icon-sharphome_work:before {
  content: "\eb31";
}
.icon-sharphorizontal_rule:before {
  content: "\eb32";
}
.icon-sharphorizontal_split:before {
  content: "\eb33";
}
.icon-sharphot_tub:before {
  content: "\eb34";
}
.icon-sharphotel:before {
  content: "\eb35";
}
.icon-sharphourglass_bottom:before {
  content: "\eb36";
}
.icon-sharphourglass_disabled:before {
  content: "\eb37";
}
.icon-sharphourglass_empty:before {
  content: "\eb38";
}
.icon-sharphourglass_full:before {
  content: "\eb39";
}
.icon-sharphourglass_top:before {
  content: "\eb3a";
}
.icon-sharphouse:before {
  content: "\eb3b";
}
.icon-sharphouse_siding:before {
  content: "\eb3c";
}
.icon-sharphow_to_reg:before {
  content: "\eb3d";
}
.icon-sharphow_to_vote:before {
  content: "\eb3e";
}
.icon-sharphttp:before {
  content: "\eb3f";
}
.icon-sharphttps:before {
  content: "\eb40";
}
.icon-sharphvac:before {
  content: "\eb41";
}
.icon-sharpimage:before {
  content: "\eb42";
}
.icon-sharpimage_aspect_ratio:before {
  content: "\eb43";
}
.icon-sharpimage_not_supported:before {
  content: "\eb44";
}
.icon-sharpimage_search:before {
  content: "\eb45";
}
.icon-sharpimport_contacts:before {
  content: "\eb46";
}
.icon-sharpimport_export:before {
  content: "\eb47";
}
.icon-sharpimportant_devices:before {
  content: "\eb48";
}
.icon-sharpinbox:before {
  content: "\eb49";
}
.icon-sharpindeterminate_check_box:before {
  content: "\eb4a";
}
.icon-sharpinfo:before {
  content: "\eb4b";
}
.icon-sharpinfo_outline:before {
  content: "\eb4c";
}
.icon-sharpinput:before {
  content: "\eb4d";
}
.icon-sharpinsert_chart:before {
  content: "\eb4e";
}
.icon-sharpinsert_chart_outlined:before {
  content: "\eb4f";
}
.icon-sharpinsert_comment:before {
  content: "\eb50";
}
.icon-sharpinsert_drive_file:before {
  content: "\eb51";
}
.icon-sharpinsert_emoticon:before {
  content: "\eb52";
}
.icon-sharpinsert_invitation:before {
  content: "\eb53";
}
.icon-sharpinsert_link:before {
  content: "\eb54";
}
.icon-sharpinsert_photo:before {
  content: "\eb55";
}
.icon-sharpinsights:before {
  content: "\eb56";
}
.icon-sharpintegration_instructions:before {
  content: "\eb57";
}
.icon-sharpinvert_colors:before {
  content: "\eb58";
}
.icon-sharpinvert_colors_off:before {
  content: "\eb59";
}
.icon-sharpiso:before {
  content: "\eb5a";
}
.icon-sharpkeyboard:before {
  content: "\eb5b";
}
.icon-sharpkeyboard_arrow_down:before {
  content: "\eb5c";
}
.icon-sharpkeyboard_arrow_left:before {
  content: "\eb5d";
}
.icon-sharpkeyboard_arrow_right:before {
  content: "\eb5e";
}
.icon-sharpkeyboard_arrow_up:before {
  content: "\eb5f";
}
.icon-sharpkeyboard_backspace:before {
  content: "\eb60";
}
.icon-sharpkeyboard_capslock:before {
  content: "\eb61";
}
.icon-sharpkeyboard_hide:before {
  content: "\eb62";
}
.icon-sharpkeyboard_return:before {
  content: "\eb63";
}
.icon-sharpkeyboard_tab:before {
  content: "\eb64";
}
.icon-sharpkeyboard_voice:before {
  content: "\eb65";
}
.icon-sharpking_bed:before {
  content: "\eb66";
}
.icon-sharpkitchen:before {
  content: "\eb67";
}
.icon-sharplabel:before {
  content: "\eb68";
}
.icon-sharplabel_important:before {
  content: "\eb69";
}
.icon-sharplabel_important_outline:before {
  content: "\eb6a";
}
.icon-sharplabel_off:before {
  content: "\eb6b";
}
.icon-sharplabel_outline:before {
  content: "\eb6c";
}
.icon-sharplandscape:before {
  content: "\eb6d";
}
.icon-sharplanguage:before {
  content: "\eb6e";
}
.icon-sharplaptop:before {
  content: "\eb6f";
}
.icon-sharplaptop_chromebook:before {
  content: "\eb70";
}
.icon-sharplaptop_mac:before {
  content: "\eb71";
}
.icon-sharplaptop_windows:before {
  content: "\eb72";
}
.icon-sharplast_page:before {
  content: "\eb73";
}
.icon-sharplaunch:before {
  content: "\eb74";
}
.icon-sharplayers:before {
  content: "\eb75";
}
.icon-sharplayers_clear:before {
  content: "\eb76";
}
.icon-sharpleaderboard:before {
  content: "\eb77";
}
.icon-sharpleak_add:before {
  content: "\eb78";
}
.icon-sharpleak_remove:before {
  content: "\eb79";
}
.icon-sharplegend_toggle:before {
  content: "\eb7a";
}
.icon-sharplens:before {
  content: "\eb7b";
}
.icon-sharplibrary_add:before {
  content: "\eb7c";
}
.icon-sharplibrary_add_check:before {
  content: "\eb7d";
}
.icon-sharplibrary_books:before {
  content: "\eb7e";
}
.icon-sharplibrary_music:before {
  content: "\eb7f";
}
.icon-sharplightbulb_outline:before {
  content: "\eb80";
}
.icon-sharpline_style:before {
  content: "\eb81";
}
.icon-sharpline_weight:before {
  content: "\eb82";
}
.icon-sharplinear_scale:before {
  content: "\eb83";
}
.icon-sharplink:before {
  content: "\eb84";
}
.icon-sharplink_off:before {
  content: "\eb85";
}
.icon-sharplinked_camera:before {
  content: "\eb86";
}
.icon-sharplist:before {
  content: "\eb87";
}
.icon-sharplist_alt:before {
  content: "\eb88";
}
.icon-sharplive_help:before {
  content: "\eb89";
}
.icon-sharplive_tv:before {
  content: "\eb8a";
}
.icon-sharplocal_activity:before {
  content: "\eb8b";
}
.icon-sharplocal_airport:before {
  content: "\eb8c";
}
.icon-sharplocal_atm:before {
  content: "\eb8d";
}
.icon-sharplocal_bar:before {
  content: "\eb8e";
}
.icon-sharplocal_cafe:before {
  content: "\eb8f";
}
.icon-sharplocal_car_wash:before {
  content: "\eb90";
}
.icon-sharplocal_convenience_store:before {
  content: "\eb91";
}
.icon-sharplocal_dining:before {
  content: "\eb92";
}
.icon-sharplocal_drink:before {
  content: "\eb93";
}
.icon-sharplocal_fire_department:before {
  content: "\eb94";
}
.icon-sharplocal_florist:before {
  content: "\eb95";
}
.icon-sharplocal_gas_station:before {
  content: "\eb96";
}
.icon-sharplocal_grocery_store:before {
  content: "\eb97";
}
.icon-sharplocal_hospital:before {
  content: "\eb98";
}
.icon-sharplocal_hotel:before {
  content: "\eb99";
}
.icon-sharplocal_laundry_service:before {
  content: "\eb9a";
}
.icon-sharplocal_library:before {
  content: "\eb9b";
}
.icon-sharplocal_mall:before {
  content: "\eb9c";
}
.icon-sharplocal_movies:before {
  content: "\eb9d";
}
.icon-sharplocal_offer:before {
  content: "\eb9e";
}
.icon-sharplocal_parking:before {
  content: "\eb9f";
}
.icon-sharplocal_pharmacy:before {
  content: "\eba0";
}
.icon-sharplocal_phone:before {
  content: "\eba1";
}
.icon-sharplocal_pizza:before {
  content: "\eba2";
}
.icon-sharplocal_play:before {
  content: "\eba3";
}
.icon-sharplocal_police:before {
  content: "\eba4";
}
.icon-sharplocal_post_office:before {
  content: "\eba5";
}
.icon-sharplocal_printshop:before {
  content: "\eba6";
}
.icon-sharplocal_see:before {
  content: "\eba7";
}
.icon-sharplocal_shipping:before {
  content: "\eba8";
}
.icon-sharplocal_taxi:before {
  content: "\eba9";
}
.icon-sharplocation_city:before {
  content: "\ebaa";
}
.icon-sharplocation_disabled:before {
  content: "\ebab";
}
.icon-sharplocation_off:before {
  content: "\ebac";
}
.icon-sharplocation_on:before {
  content: "\ebad";
}
.icon-sharplocation_searching:before {
  content: "\ebae";
}
.icon-sharplock:before {
  content: "\ebaf";
}
.icon-sharplock_open:before {
  content: "\ebb0";
}
.icon-sharplock_outline:before {
  content: "\ebb1";
}
.icon-sharplogin:before {
  content: "\ebb2";
}
.icon-sharplooks:before {
  content: "\ebb3";
}
.icon-sharplooks_3:before {
  content: "\ebb4";
}
.icon-sharplooks_4:before {
  content: "\ebb5";
}
.icon-sharplooks_5:before {
  content: "\ebb6";
}
.icon-sharplooks_6:before {
  content: "\ebb7";
}
.icon-sharplooks_one:before {
  content: "\ebb8";
}
.icon-sharplooks_two:before {
  content: "\ebb9";
}
.icon-sharploop:before {
  content: "\ebba";
}
.icon-sharploupe:before {
  content: "\ebbb";
}
.icon-sharplow_priority:before {
  content: "\ebbc";
}
.icon-sharployalty:before {
  content: "\ebbd";
}
.icon-sharpluggage:before {
  content: "\ebbe";
}
.icon-sharpmail:before {
  content: "\ebbf";
}
.icon-sharpmail_outline:before {
  content: "\ebc0";
}
.icon-sharpmap:before {
  content: "\ebc1";
}
.icon-sharpmaps_ugc:before {
  content: "\ebc2";
}
.icon-sharpmark_chat_read:before {
  content: "\ebc3";
}
.icon-sharpmark_chat_unread:before {
  content: "\ebc4";
}
.icon-sharpmark_email_read:before {
  content: "\ebc5";
}
.icon-sharpmark_email_unread:before {
  content: "\ebc6";
}
.icon-sharpmarkunread:before {
  content: "\ebc7";
}
.icon-sharpmarkunread_mailbox:before {
  content: "\ebc8";
}
.icon-sharpmasks:before {
  content: "\ebc9";
}
.icon-sharpmaximize:before {
  content: "\ebca";
}
.icon-sharpmediation:before {
  content: "\ebcb";
}
.icon-sharpmedical_services:before {
  content: "\ebcc";
}
.icon-sharpmeeting_room:before {
  content: "\ebcd";
}
.icon-sharpmemory:before {
  content: "\ebce";
}
.icon-sharpmenu:before {
  content: "\ebcf";
}
.icon-sharpmenu_book:before {
  content: "\ebd0";
}
.icon-sharpmenu_open:before {
  content: "\ebd1";
}
.icon-sharpmerge_type:before {
  content: "\ebd2";
}
.icon-sharpmessage:before {
  content: "\ebd3";
}
.icon-sharpmic:before {
  content: "\ebd4";
}
.icon-sharpmic_none:before {
  content: "\ebd5";
}
.icon-sharpmic_off:before {
  content: "\ebd6";
}
.icon-sharpmicrowave:before {
  content: "\ebd7";
}
.icon-sharpmilitary_tech:before {
  content: "\ebd8";
}
.icon-sharpminimize:before {
  content: "\ebd9";
}
.icon-sharpmiscellaneous_services:before {
  content: "\ebda";
}
.icon-sharpmissed_video_call:before {
  content: "\ebdb";
}
.icon-sharpmms:before {
  content: "\ebdc";
}
.icon-sharpmobile_friendly:before {
  content: "\ebdd";
}
.icon-sharpmobile_off:before {
  content: "\ebde";
}
.icon-sharpmobile_screen_share:before {
  content: "\ebdf";
}
.icon-sharpmode:before {
  content: "\ebe0";
}
.icon-sharpmode_comment:before {
  content: "\ebe1";
}
.icon-sharpmodel_training:before {
  content: "\ebe2";
}
.icon-sharpmonetization_on:before {
  content: "\ebe3";
}
.icon-sharpmoney:before {
  content: "\ebe4";
}
.icon-sharpmoney_off:before {
  content: "\ebe5";
}
.icon-sharpmoney_off_csred:before {
  content: "\ebe6";
}
.icon-sharpmonochrome_photos:before {
  content: "\ebe7";
}
.icon-sharpmood:before {
  content: "\ebe8";
}
.icon-sharpmood_bad:before {
  content: "\ebe9";
}
.icon-sharpmoped:before {
  content: "\ebea";
}
.icon-sharpmore:before {
  content: "\ebeb";
}
.icon-sharpmore_horiz:before {
  content: "\ebec";
}
.icon-sharpmore_time:before {
  content: "\ebed";
}
.icon-sharpmore_vert:before {
  content: "\ebee";
}
.icon-sharpmotion_photos_on:before {
  content: "\ebef";
}
.icon-sharpmotion_photos_pause:before {
  content: "\ebf0";
}
.icon-sharpmotion_photos_paused:before {
  content: "\ebf1";
}
.icon-sharpmouse:before {
  content: "\ebf2";
}
.icon-sharpmove_to_inbox:before {
  content: "\ebf3";
}
.icon-sharpmovie:before {
  content: "\ebf4";
}
.icon-sharpmovie_creation:before {
  content: "\ebf5";
}
.icon-sharpmovie_filter:before {
  content: "\ebf6";
}
.icon-sharpmultiline_chart:before {
  content: "\ebf7";
}
.icon-sharpmultiple_stop:before {
  content: "\ebf8";
}
.icon-sharpmuseum:before {
  content: "\ebf9";
}
.icon-sharpmusic_note:before {
  content: "\ebfa";
}
.icon-sharpmusic_off:before {
  content: "\ebfb";
}
.icon-sharpmusic_video:before {
  content: "\ebfc";
}
.icon-sharpmy_location:before {
  content: "\ebfd";
}
.icon-sharpnat:before {
  content: "\ebfe";
}
.icon-sharpnature:before {
  content: "\ebff";
}
.icon-sharpnature_people:before {
  content: "\ec00";
}
.icon-sharpnavigate_before:before {
  content: "\ec01";
}
.icon-sharpnavigate_next:before {
  content: "\ec02";
}
.icon-sharpnavigation:before {
  content: "\ec03";
}
.icon-sharpnear_me:before {
  content: "\ec04";
}
.icon-sharpnear_me_disabled:before {
  content: "\ec05";
}
.icon-sharpnetwork_check:before {
  content: "\ec06";
}
.icon-sharpnetwork_locked:before {
  content: "\ec07";
}
.icon-sharpnew_releases:before {
  content: "\ec08";
}
.icon-sharpnext_plan:before {
  content: "\ec09";
}
.icon-sharpnext_week:before {
  content: "\ec0a";
}
.icon-sharpnfc:before {
  content: "\ec0b";
}
.icon-sharpnight_shelter:before {
  content: "\ec0c";
}
.icon-sharpnights_stay:before {
  content: "\ec0d";
}
.icon-sharpno_backpack:before {
  content: "\ec0e";
}
.icon-sharpno_cell:before {
  content: "\ec0f";
}
.icon-sharpno_drinks:before {
  content: "\ec10";
}
.icon-sharpno_encryption:before {
  content: "\ec11";
}
.icon-sharpno_encryption_gmailerrorred:before {
  content: "\ec12";
}
.icon-sharpno_flash:before {
  content: "\ec13";
}
.icon-sharpno_food:before {
  content: "\ec14";
}
.icon-sharpno_luggage:before {
  content: "\ec15";
}
.icon-sharpno_meals:before {
  content: "\ec16";
}
.icon-sharpno_meeting_room:before {
  content: "\ec17";
}
.icon-sharpno_photography:before {
  content: "\ec18";
}
.icon-sharpno_sim:before {
  content: "\ec19";
}
.icon-sharpno_stroller:before {
  content: "\ec1a";
}
.icon-sharpno_transfer:before {
  content: "\ec1b";
}
.icon-sharpnorth:before {
  content: "\ec1c";
}
.icon-sharpnorth_east:before {
  content: "\ec1d";
}
.icon-sharpnorth_west:before {
  content: "\ec1e";
}
.icon-sharpnot_accessible:before {
  content: "\ec1f";
}
.icon-sharpnot_interested:before {
  content: "\ec20";
}
.icon-sharpnot_listed_location:before {
  content: "\ec21";
}
.icon-sharpnot_started:before {
  content: "\ec22";
}
.icon-sharpnote:before {
  content: "\ec23";
}
.icon-sharpnote_add:before {
  content: "\ec24";
}
.icon-sharpnotes:before {
  content: "\ec25";
}
.icon-sharpnotification_important:before {
  content: "\ec26";
}
.icon-sharpnotifications:before {
  content: "\ec27";
}
.icon-sharpnotifications_active:before {
  content: "\ec28";
}
.icon-sharpnotifications_none:before {
  content: "\ec29";
}
.icon-sharpnotifications_off:before {
  content: "\ec2a";
}
.icon-sharpnotifications_paused:before {
  content: "\ec2b";
}
.icon-sharpoffline_bolt:before {
  content: "\ec2c";
}
.icon-sharpoffline_pin:before {
  content: "\ec2d";
}
.icon-sharpondemand_video:before {
  content: "\ec2e";
}
.icon-sharponline_prediction:before {
  content: "\ec2f";
}
.icon-sharpopacity:before {
  content: "\ec30";
}
.icon-sharpopen_in_browser:before {
  content: "\ec31";
}
.icon-sharpopen_in_full:before {
  content: "\ec32";
}
.icon-sharpopen_in_new:before {
  content: "\ec33";
}
.icon-sharpopen_with:before {
  content: "\ec34";
}
.icon-sharpoutbond:before {
  content: "\ec35";
}
.icon-sharpoutdoor_grill:before {
  content: "\ec36";
}
.icon-sharpoutlet:before {
  content: "\ec37";
}
.icon-sharpoutlined_flag:before {
  content: "\ec38";
}
.icon-sharppages:before {
  content: "\ec39";
}
.icon-sharppageview:before {
  content: "\ec3a";
}
.icon-sharppalette:before {
  content: "\ec3b";
}
.icon-sharppan_tool:before {
  content: "\ec3c";
}
.icon-sharppanorama:before {
  content: "\ec3d";
}
.icon-sharppanorama_fish_eye:before {
  content: "\ec3e";
}
.icon-sharppanorama_horizontal:before {
  content: "\ec3f";
}
.icon-sharppanorama_vertical:before {
  content: "\ec40";
}
.icon-sharppanorama_wide_angle:before {
  content: "\ec41";
}
.icon-sharpparty_mode:before {
  content: "\ec42";
}
.icon-sharppause:before {
  content: "\ec43";
}
.icon-sharppause_circle_filled:before {
  content: "\ec44";
}
.icon-sharppause_circle_outline:before {
  content: "\ec45";
}
.icon-sharppause_presentation:before {
  content: "\ec46";
}
.icon-sharppayment:before {
  content: "\ec47";
}
.icon-sharppayments:before {
  content: "\ec48";
}
.icon-sharppedal_bike:before {
  content: "\ec49";
}
.icon-sharppending:before {
  content: "\ec4a";
}
.icon-sharppending_actions:before {
  content: "\ec4b";
}
.icon-sharppeople:before {
  content: "\ec4c";
}
.icon-sharppeople_alt:before {
  content: "\ec4d";
}
.icon-sharppeople_outline:before {
  content: "\ec4e";
}
.icon-sharpperm_camera_mic:before {
  content: "\ec4f";
}
.icon-sharpperm_contact_calendar:before {
  content: "\ec50";
}
.icon-sharpperm_data_setting:before {
  content: "\ec51";
}
.icon-sharpperm_device_information:before {
  content: "\ec52";
}
.icon-sharpperm_identity:before {
  content: "\ec53";
}
.icon-sharpperm_media:before {
  content: "\ec54";
}
.icon-sharpperm_phone_msg:before {
  content: "\ec55";
}
.icon-sharpperm_scan_wifi:before {
  content: "\ec56";
}
.icon-sharpperson:before {
  content: "\ec57";
}
.icon-sharpperson_add:before {
  content: "\ec58";
}
.icon-sharpperson_add_alt_1:before {
  content: "\ec59";
}
.icon-sharpperson_add_disabled:before {
  content: "\ec5a";
}
.icon-sharpperson_outline:before {
  content: "\ec5b";
}
.icon-sharpperson_pin:before {
  content: "\ec5c";
}
.icon-sharpperson_pin_circle:before {
  content: "\ec5d";
}
.icon-sharpperson_remove:before {
  content: "\ec5e";
}
.icon-sharpperson_remove_alt_1:before {
  content: "\ec5f";
}
.icon-sharpperson_search:before {
  content: "\ec60";
}
.icon-sharppersonal_video:before {
  content: "\ec61";
}
.icon-sharppest_control:before {
  content: "\ec62";
}
.icon-sharppest_control_rodent:before {
  content: "\ec63";
}
.icon-sharppets:before {
  content: "\ec64";
}
.icon-sharpphone:before {
  content: "\ec65";
}
.icon-sharpphone_android:before {
  content: "\ec66";
}
.icon-sharpphone_bluetooth_speaker:before {
  content: "\ec67";
}
.icon-sharpphone_callback:before {
  content: "\ec68";
}
.icon-sharpphone_disabled:before {
  content: "\ec69";
}
.icon-sharpphone_enabled:before {
  content: "\ec6a";
}
.icon-sharpphone_forwarded:before {
  content: "\ec6b";
}
.icon-sharpphone_in_talk:before {
  content: "\ec6c";
}
.icon-sharpphone_iphone:before {
  content: "\ec6d";
}
.icon-sharpphone_locked:before {
  content: "\ec6e";
}
.icon-sharpphone_missed:before {
  content: "\ec6f";
}
.icon-sharpphone_paused:before {
  content: "\ec70";
}
.icon-sharpphonelink:before {
  content: "\ec71";
}
.icon-sharpphonelink_erase:before {
  content: "\ec72";
}
.icon-sharpphonelink_lock:before {
  content: "\ec73";
}
.icon-sharpphonelink_off:before {
  content: "\ec74";
}
.icon-sharpphonelink_ring:before {
  content: "\ec75";
}
.icon-sharpphonelink_setup:before {
  content: "\ec76";
}
.icon-sharpphoto:before {
  content: "\ec77";
}
.icon-sharpphoto_album:before {
  content: "\ec78";
}
.icon-sharpphoto_camera:before {
  content: "\ec79";
}
.icon-sharpphoto_filter:before {
  content: "\ec7a";
}
.icon-sharpphoto_library:before {
  content: "\ec7b";
}
.icon-sharpphoto_size_select_actual:before {
  content: "\ec7c";
}
.icon-sharpphoto_size_select_large:before {
  content: "\ec7d";
}
.icon-sharpphoto_size_select_small:before {
  content: "\ec7e";
}
.icon-sharppicture_as_pdf:before {
  content: "\ec7f";
}
.icon-sharppicture_in_picture:before {
  content: "\ec80";
}
.icon-sharppicture_in_picture_alt:before {
  content: "\ec81";
}
.icon-sharppie_chart:before {
  content: "\ec82";
}
.icon-sharppie_chart_outline:before {
  content: "\ec83";
}
.icon-sharppin_drop:before {
  content: "\ec84";
}
.icon-sharpplace:before {
  content: "\ec85";
}
.icon-sharpplagiarism:before {
  content: "\ec86";
}
.icon-sharpplay_arrow:before {
  content: "\ec87";
}
.icon-sharpplay_circle_filled:before {
  content: "\ec88";
}
.icon-sharpplay_circle_outline:before {
  content: "\ec89";
}
.icon-sharpplay_for_work:before {
  content: "\ec8a";
}
.icon-sharpplaylist_add:before {
  content: "\ec8b";
}
.icon-sharpplaylist_add_check:before {
  content: "\ec8c";
}
.icon-sharpplaylist_play:before {
  content: "\ec8d";
}
.icon-sharpplumbing:before {
  content: "\ec8e";
}
.icon-sharpplus_one:before {
  content: "\ec8f";
}
.icon-sharppoint_of_sale:before {
  content: "\ec90";
}
.icon-sharppolicy:before {
  content: "\ec91";
}
.icon-sharppoll:before {
  content: "\ec92";
}
.icon-sharppolymer:before {
  content: "\ec93";
}
.icon-sharppool:before {
  content: "\ec94";
}
.icon-sharpportable_wifi_off:before {
  content: "\ec95";
}
.icon-sharpportrait:before {
  content: "\ec96";
}
.icon-sharppost_add:before {
  content: "\ec97";
}
.icon-sharppower:before {
  content: "\ec98";
}
.icon-sharppower_input:before {
  content: "\ec99";
}
.icon-sharppower_off:before {
  content: "\ec9a";
}
.icon-sharppower_settings_new:before {
  content: "\ec9b";
}
.icon-sharpprecision_manufacturing:before {
  content: "\ec9c";
}
.icon-sharppregnant_woman:before {
  content: "\ec9d";
}
.icon-sharppresent_to_all:before {
  content: "\ec9e";
}
.icon-sharppreview:before {
  content: "\ec9f";
}
.icon-sharpprint:before {
  content: "\eca0";
}
.icon-sharpprint_disabled:before {
  content: "\eca1";
}
.icon-sharppriority_high:before {
  content: "\eca2";
}
.icon-sharpprivacy_tip:before {
  content: "\eca3";
}
.icon-sharppsychology:before {
  content: "\eca4";
}
.icon-sharppublic:before {
  content: "\eca5";
}
.icon-sharppublic_off:before {
  content: "\eca6";
}
.icon-sharppublish:before {
  content: "\eca7";
}
.icon-sharppublished_with_changes:before {
  content: "\eca8";
}
.icon-sharppush_pin:before {
  content: "\eca9";
}
.icon-sharpqr_code:before {
  content: "\ecaa";
}
.icon-sharpqr_code_scanner:before {
  content: "\ecab";
}
.icon-sharpquery_builder:before {
  content: "\ecac";
}
.icon-sharpquestion_answer:before {
  content: "\ecad";
}
.icon-sharpqueue:before {
  content: "\ecae";
}
.icon-sharpqueue_music:before {
  content: "\ecaf";
}
.icon-sharpqueue_play_next:before {
  content: "\ecb0";
}
.icon-sharpquickreply:before {
  content: "\ecb1";
}
.icon-sharpradio:before {
  content: "\ecb2";
}
.icon-sharpradio_button_checked:before {
  content: "\ecb3";
}
.icon-sharpradio_button_unchecked:before {
  content: "\ecb4";
}
.icon-sharprate_review:before {
  content: "\ecb5";
}
.icon-sharpread_more:before {
  content: "\ecb6";
}
.icon-sharpreceipt:before {
  content: "\ecb7";
}
.icon-sharpreceipt_long:before {
  content: "\ecb8";
}
.icon-sharprecent_actors:before {
  content: "\ecb9";
}
.icon-sharprecord_voice_over:before {
  content: "\ecba";
}
.icon-sharpredeem:before {
  content: "\ecbb";
}
.icon-sharpredo:before {
  content: "\ecbc";
}
.icon-sharpreduce_capacity:before {
  content: "\ecbd";
}
.icon-sharprefresh:before {
  content: "\ecbe";
}
.icon-sharpremove:before {
  content: "\ecbf";
}
.icon-sharpremove_circle:before {
  content: "\ecc0";
}
.icon-sharpremove_circle_outline:before {
  content: "\ecc1";
}
.icon-sharpremove_from_queue:before {
  content: "\ecc2";
}
.icon-sharpremove_red_eye:before {
  content: "\ecc3";
}
.icon-sharpremove_shopping_cart:before {
  content: "\ecc4";
}
.icon-sharpreorder:before {
  content: "\ecc5";
}
.icon-sharprepeat:before {
  content: "\ecc6";
}
.icon-sharprepeat_one:before {
  content: "\ecc7";
}
.icon-sharpreplay:before {
  content: "\ecc8";
}
.icon-sharpreplay_5:before {
  content: "\ecc9";
}
.icon-sharpreplay_10:before {
  content: "\ecca";
}
.icon-sharpreplay_30:before {
  content: "\eccb";
}
.icon-sharpreply:before {
  content: "\eccc";
}
.icon-sharpreply_all:before {
  content: "\eccd";
}
.icon-sharpreport:before {
  content: "\ecce";
}
.icon-sharpreport_gmailerrorred:before {
  content: "\eccf";
}
.icon-sharpreport_off:before {
  content: "\ecd0";
}
.icon-sharpreport_problem:before {
  content: "\ecd1";
}
.icon-sharprequest_page:before {
  content: "\ecd2";
}
.icon-sharprequest_quote:before {
  content: "\ecd3";
}
.icon-sharprestaurant:before {
  content: "\ecd4";
}
.icon-sharprestaurant_menu:before {
  content: "\ecd5";
}
.icon-sharprestore:before {
  content: "\ecd6";
}
.icon-sharprestore_from_trash:before {
  content: "\ecd7";
}
.icon-sharprestore_page:before {
  content: "\ecd8";
}
.icon-sharprice_bowl:before {
  content: "\ecd9";
}
.icon-sharpring_volume:before {
  content: "\ecda";
}
.icon-sharproofing:before {
  content: "\ecdb";
}
.icon-sharproom:before {
  content: "\ecdc";
}
.icon-sharproom_preferences:before {
  content: "\ecdd";
}
.icon-sharproom_service:before {
  content: "\ecde";
}
.icon-sharprotate_90_degrees_ccw:before {
  content: "\ecdf";
}
.icon-sharprotate_left:before {
  content: "\ece0";
}
.icon-sharprotate_right:before {
  content: "\ece1";
}
.icon-sharprounded_corner:before {
  content: "\ece2";
}
.icon-sharprouter:before {
  content: "\ece3";
}
.icon-sharprowing:before {
  content: "\ece4";
}
.icon-sharprss_feed:before {
  content: "\ece5";
}
.icon-sharprule:before {
  content: "\ece6";
}
.icon-sharprule_folder:before {
  content: "\ece7";
}
.icon-sharprun_circle:before {
  content: "\ece8";
}
.icon-sharprv_hookup:before {
  content: "\ece9";
}
.icon-sharpsanitizer:before {
  content: "\ecea";
}
.icon-sharpsatellite:before {
  content: "\eceb";
}
.icon-sharpsave:before {
  content: "\ecec";
}
.icon-sharpsave_alt:before {
  content: "\eced";
}
.icon-sharpscanner:before {
  content: "\ecee";
}
.icon-sharpscatter_plot:before {
  content: "\ecef";
}
.icon-sharpschedule:before {
  content: "\ecf0";
}
.icon-sharpschool:before {
  content: "\ecf1";
}
.icon-sharpscience:before {
  content: "\ecf2";
}
.icon-sharpscore:before {
  content: "\ecf3";
}
.icon-sharpscreen_lock_landscape:before {
  content: "\ecf4";
}
.icon-sharpscreen_lock_portrait:before {
  content: "\ecf5";
}
.icon-sharpscreen_lock_rotation:before {
  content: "\ecf6";
}
.icon-sharpscreen_rotation:before {
  content: "\ecf7";
}
.icon-sharpscreen_share:before {
  content: "\ecf8";
}
.icon-sharpsd_card:before {
  content: "\ecf9";
}
.icon-sharpsd_card_alert:before {
  content: "\ecfa";
}
.icon-sharpsd_storage:before {
  content: "\ecfb";
}
.icon-sharpsearch:before {
  content: "\ecfc";
}
.icon-sharpsearch_off:before {
  content: "\ecfd";
}
.icon-sharpsecurity:before {
  content: "\ecfe";
}
.icon-sharpselect_all:before {
  content: "\ecff";
}
.icon-sharpself_improvement:before {
  content: "\ed00";
}
.icon-sharpsend:before {
  content: "\ed01";
}
.icon-sharpsensor_door:before {
  content: "\ed02";
}
.icon-sharpsensor_window:before {
  content: "\ed03";
}
.icon-sharpsentiment_dissatisfied:before {
  content: "\ed04";
}
.icon-sharpsentiment_satisfied:before {
  content: "\ed05";
}
.icon-sharpsentiment_satisfied_alt:before {
  content: "\ed06";
}
.icon-sharpsentiment_very_dissatisfied:before {
  content: "\ed07";
}
.icon-sharpsentiment_very_satisfied:before {
  content: "\ed08";
}
.icon-sharpset_meal:before {
  content: "\ed09";
}
.icon-sharpsettings:before {
  content: "\ed0a";
}
.icon-sharpsettings_applications:before {
  content: "\ed0b";
}
.icon-sharpsettings_backup_restore:before {
  content: "\ed0c";
}
.icon-sharpsettings_bluetooth:before {
  content: "\ed0d";
}
.icon-sharpsettings_brightness:before {
  content: "\ed0e";
}
.icon-sharpsettings_cell:before {
  content: "\ed0f";
}
.icon-sharpsettings_ethernet:before {
  content: "\ed10";
}
.icon-sharpsettings_input_antenna:before {
  content: "\ed11";
}
.icon-sharpsettings_input_component:before {
  content: "\ed12";
}
.icon-sharpsettings_input_composite:before {
  content: "\ed13";
}
.icon-sharpsettings_input_hdmi:before {
  content: "\ed14";
}
.icon-sharpsettings_input_svideo:before {
  content: "\ed15";
}
.icon-sharpsettings_overscan:before {
  content: "\ed16";
}
.icon-sharpsettings_phone:before {
  content: "\ed17";
}
.icon-sharpsettings_power:before {
  content: "\ed18";
}
.icon-sharpsettings_remote:before {
  content: "\ed19";
}
.icon-sharpsettings_system_daydream:before {
  content: "\ed1a";
}
.icon-sharpsettings_voice:before {
  content: "\ed1b";
}
.icon-sharpshare:before {
  content: "\ed1c";
}
.icon-sharpshop:before {
  content: "\ed1d";
}
.icon-sharpshop_two:before {
  content: "\ed1e";
}
.icon-sharpshopping_bag:before {
  content: "\ed1f";
}
.icon-sharpshopping_basket:before {
  content: "\ed20";
}
.icon-sharpshopping_cart:before {
  content: "\ed21";
}
.icon-sharpshort_text:before {
  content: "\ed22";
}
.icon-sharpshow_chart:before {
  content: "\ed23";
}
.icon-sharpshuffle:before {
  content: "\ed24";
}
.icon-sharpshutter_speed:before {
  content: "\ed25";
}
.icon-sharpsick:before {
  content: "\ed26";
}
.icon-sharpsignal_cellular_4_bar:before {
  content: "\ed27";
}
.icon-sharpsignal_cellular_alt:before {
  content: "\ed28";
}
.icon-sharpsignal_cellular_connected_no_internet_4_bar:before {
  content: "\ed29";
}
.icon-sharpsignal_cellular_no_sim:before {
  content: "\ed2a";
}
.icon-sharpsignal_cellular_null:before {
  content: "\ed2b";
}
.icon-sharpsignal_cellular_off:before {
  content: "\ed2c";
}
.icon-sharpsignal_wifi_4_bar:before {
  content: "\ed2d";
}
.icon-sharpsignal_wifi_4_bar_lock:before {
  content: "\ed2e";
}
.icon-sharpsignal_wifi_off:before {
  content: "\ed2f";
}
.icon-sharpsim_card:before {
  content: "\ed30";
}
.icon-sharpsingle_bed:before {
  content: "\ed31";
}
.icon-sharpskip_next:before {
  content: "\ed32";
}
.icon-sharpskip_previous:before {
  content: "\ed33";
}
.icon-sharpslideshow:before {
  content: "\ed34";
}
.icon-sharpslow_motion_video:before {
  content: "\ed35";
}
.icon-sharpsmart_button:before {
  content: "\ed36";
}
.icon-sharpsmartphone:before {
  content: "\ed37";
}
.icon-sharpsmoke_free:before {
  content: "\ed38";
}
.icon-sharpsmoking_rooms:before {
  content: "\ed39";
}
.icon-sharpsms:before {
  content: "\ed3a";
}
.icon-sharpsms_failed:before {
  content: "\ed3b";
}
.icon-sharpsnippet_folder:before {
  content: "\ed3c";
}
.icon-sharpsnooze:before {
  content: "\ed3d";
}
.icon-sharpsoap:before {
  content: "\ed3e";
}
.icon-sharpsort:before {
  content: "\ed3f";
}
.icon-sharpsort_by_alpha:before {
  content: "\ed40";
}
.icon-sharpsource:before {
  content: "\ed41";
}
.icon-sharpsouth:before {
  content: "\ed42";
}
.icon-sharpsouth_east:before {
  content: "\ed43";
}
.icon-sharpsouth_west:before {
  content: "\ed44";
}
.icon-sharpspa:before {
  content: "\ed45";
}
.icon-sharpspace_bar:before {
  content: "\ed46";
}
.icon-sharpspeaker:before {
  content: "\ed47";
}
.icon-sharpspeaker_group:before {
  content: "\ed48";
}
.icon-sharpspeaker_notes:before {
  content: "\ed49";
}
.icon-sharpspeaker_notes_off:before {
  content: "\ed4a";
}
.icon-sharpspeaker_phone:before {
  content: "\ed4b";
}
.icon-sharpspeed:before {
  content: "\ed4c";
}
.icon-sharpspellcheck:before {
  content: "\ed4d";
}
.icon-sharpsports:before {
  content: "\ed4e";
}
.icon-sharpsports_bar:before {
  content: "\ed4f";
}
.icon-sharpsports_baseball:before {
  content: "\ed50";
}
.icon-sharpsports_basketball:before {
  content: "\ed51";
}
.icon-sharpsports_cricket:before {
  content: "\ed52";
}
.icon-sharpsports_esports:before {
  content: "\ed53";
}
.icon-sharpsports_football:before {
  content: "\ed54";
}
.icon-sharpsports_golf:before {
  content: "\ed55";
}
.icon-sharpsports_handball:before {
  content: "\ed56";
}
.icon-sharpsports_hockey:before {
  content: "\ed57";
}
.icon-sharpsports_kabaddi:before {
  content: "\ed58";
}
.icon-sharpsports_mma:before {
  content: "\ed59";
}
.icon-sharpsports_motorsports:before {
  content: "\ed5a";
}
.icon-sharpsports_rugby:before {
  content: "\ed5b";
}
.icon-sharpsports_soccer:before {
  content: "\ed5c";
}
.icon-sharpsports_tennis:before {
  content: "\ed5d";
}
.icon-sharpsports_volleyball:before {
  content: "\ed5e";
}
.icon-sharpsquare_foot:before {
  content: "\ed5f";
}
.icon-sharpstacked_line_chart:before {
  content: "\ed60";
}
.icon-sharpstairs:before {
  content: "\ed61";
}
.icon-sharpstar:before {
  content: "\ed62";
}
.icon-sharpstar_border:before {
  content: "\ed63";
}
.icon-sharpstar_border_purple500:before {
  content: "\ed64";
}
.icon-sharpstar_half:before {
  content: "\ed65";
}
.icon-sharpstar_outline:before {
  content: "\ed66";
}
.icon-sharpstar_purple500:before {
  content: "\ed67";
}
.icon-sharpstar_rate:before {
  content: "\ed68";
}
.icon-sharpstars:before {
  content: "\ed69";
}
.icon-sharpstay_current_landscape:before {
  content: "\ed6a";
}
.icon-sharpstay_current_portrait:before {
  content: "\ed6b";
}
.icon-sharpstay_primary_landscape:before {
  content: "\ed6c";
}
.icon-sharpstay_primary_portrait:before {
  content: "\ed6d";
}
.icon-sharpsticky_note_2:before {
  content: "\ed6e";
}
.icon-sharpstop:before {
  content: "\ed6f";
}
.icon-sharpstop_circle:before {
  content: "\ed70";
}
.icon-sharpstop_screen_share:before {
  content: "\ed71";
}
.icon-sharpstorage:before {
  content: "\ed72";
}
.icon-sharpstore:before {
  content: "\ed73";
}
.icon-sharpstore_mall_directory:before {
  content: "\ed74";
}
.icon-sharpstorefront:before {
  content: "\ed75";
}
.icon-sharpstraighten:before {
  content: "\ed76";
}
.icon-sharpstreetview:before {
  content: "\ed77";
}
.icon-sharpstrikethrough_s:before {
  content: "\ed78";
}
.icon-sharpstroller:before {
  content: "\ed79";
}
.icon-sharpstyle:before {
  content: "\ed7a";
}
.icon-sharpsubdirectory_arrow_left:before {
  content: "\ed7b";
}
.icon-sharpsubdirectory_arrow_right:before {
  content: "\ed7c";
}
.icon-sharpsubject:before {
  content: "\ed7d";
}
.icon-sharpsubscript:before {
  content: "\ed7e";
}
.icon-sharpsubscriptions:before {
  content: "\ed7f";
}
.icon-sharpsubtitles:before {
  content: "\ed80";
}
.icon-sharpsubtitles_off:before {
  content: "\ed81";
}
.icon-sharpsubway:before {
  content: "\ed82";
}
.icon-sharpsuperscript:before {
  content: "\ed83";
}
.icon-sharpsupervised_user_circle:before {
  content: "\ed84";
}
.icon-sharpsupervisor_account:before {
  content: "\ed85";
}
.icon-sharpsupport:before {
  content: "\ed86";
}
.icon-sharpsupport_agent:before {
  content: "\ed87";
}
.icon-sharpsurround_sound:before {
  content: "\ed88";
}
.icon-sharpswap_calls:before {
  content: "\ed89";
}
.icon-sharpswap_horiz:before {
  content: "\ed8a";
}
.icon-sharpswap_horizontal_circle:before {
  content: "\ed8b";
}
.icon-sharpswap_vert:before {
  content: "\ed8c";
}
.icon-sharpswap_vertical_circle:before {
  content: "\ed8d";
}
.icon-sharpswitch_camera:before {
  content: "\ed8e";
}
.icon-sharpswitch_left:before {
  content: "\ed8f";
}
.icon-sharpswitch_right:before {
  content: "\ed90";
}
.icon-sharpswitch_video:before {
  content: "\ed91";
}
.icon-sharpsync:before {
  content: "\ed92";
}
.icon-sharpsync_alt:before {
  content: "\ed93";
}
.icon-sharpsync_disabled:before {
  content: "\ed94";
}
.icon-sharpsync_problem:before {
  content: "\ed95";
}
.icon-sharpsystem_update:before {
  content: "\ed96";
}
.icon-sharpsystem_update_alt:before {
  content: "\ed97";
}
.icon-sharptab:before {
  content: "\ed98";
}
.icon-sharptab_unselected:before {
  content: "\ed99";
}
.icon-sharptable_chart:before {
  content: "\ed9a";
}
.icon-sharptable_rows:before {
  content: "\ed9b";
}
.icon-sharptable_view:before {
  content: "\ed9c";
}
.icon-sharptablet:before {
  content: "\ed9d";
}
.icon-sharptablet_android:before {
  content: "\ed9e";
}
.icon-sharptablet_mac:before {
  content: "\ed9f";
}
.icon-sharptag_faces:before {
  content: "\eda0";
}
.icon-sharptap_and_play:before {
  content: "\eda1";
}
.icon-sharptapas:before {
  content: "\eda2";
}
.icon-sharpterrain:before {
  content: "\eda3";
}
.icon-sharptext_fields:before {
  content: "\eda4";
}
.icon-sharptext_format:before {
  content: "\eda5";
}
.icon-sharptext_rotate_up:before {
  content: "\eda6";
}
.icon-sharptext_rotate_vertical:before {
  content: "\eda7";
}
.icon-sharptext_rotation_angledown:before {
  content: "\eda8";
}
.icon-sharptext_rotation_angleup:before {
  content: "\eda9";
}
.icon-sharptext_rotation_down:before {
  content: "\edaa";
}
.icon-sharptext_rotation_none:before {
  content: "\edab";
}
.icon-sharptext_snippet:before {
  content: "\edac";
}
.icon-sharptextsms:before {
  content: "\edad";
}
.icon-sharptexture:before {
  content: "\edae";
}
.icon-sharptheaters:before {
  content: "\edaf";
}
.icon-sharpthermostat:before {
  content: "\edb0";
}
.icon-sharpthumb_down:before {
  content: "\edb1";
}
.icon-sharpthumb_down_alt:before {
  content: "\edb2";
}
.icon-sharpthumb_up:before {
  content: "\edb3";
}
.icon-sharpthumb_up_alt:before {
  content: "\edb4";
}
.icon-sharpthumbs_up_down:before {
  content: "\edb5";
}
.icon-sharptime_to_leave:before {
  content: "\edb6";
}
.icon-sharptimelapse:before {
  content: "\edb7";
}
.icon-sharptimeline:before {
  content: "\edb8";
}
.icon-sharptimer:before {
  content: "\edb9";
}
.icon-sharptimer_3:before {
  content: "\edba";
}
.icon-sharptimer_10:before {
  content: "\edbb";
}
.icon-sharptimer_off:before {
  content: "\edbc";
}
.icon-sharptitle:before {
  content: "\edbd";
}
.icon-sharptoc:before {
  content: "\edbe";
}
.icon-sharptoday:before {
  content: "\edbf";
}
.icon-sharptoggle_off:before {
  content: "\edc0";
}
.icon-sharptoggle_on:before {
  content: "\edc1";
}
.icon-sharptoll:before {
  content: "\edc2";
}
.icon-sharptonality:before {
  content: "\edc3";
}
.icon-sharptopic:before {
  content: "\edc4";
}
.icon-sharptouch_app:before {
  content: "\edc5";
}
.icon-sharptour:before {
  content: "\edc6";
}
.icon-sharptoys:before {
  content: "\edc7";
}
.icon-sharptrack_changes:before {
  content: "\edc8";
}
.icon-sharptraffic:before {
  content: "\edc9";
}
.icon-sharptrain:before {
  content: "\edca";
}
.icon-sharptram:before {
  content: "\edcb";
}
.icon-sharptransfer_within_a_station:before {
  content: "\edcc";
}
.icon-sharptransform:before {
  content: "\edcd";
}
.icon-sharptransit_enterexit:before {
  content: "\edce";
}
.icon-sharptranslate:before {
  content: "\edcf";
}
.icon-sharptrending_down:before {
  content: "\edd0";
}
.icon-sharptrending_flat:before {
  content: "\edd1";
}
.icon-sharptrending_up:before {
  content: "\edd2";
}
.icon-sharptrip_origin:before {
  content: "\edd3";
}
.icon-sharptty:before {
  content: "\edd4";
}
.icon-sharptune:before {
  content: "\edd5";
}
.icon-sharpturned_in:before {
  content: "\edd6";
}
.icon-sharpturned_in_not:before {
  content: "\edd7";
}
.icon-sharptv:before {
  content: "\edd8";
}
.icon-sharptv_off:before {
  content: "\edd9";
}
.icon-sharptwo_wheeler:before {
  content: "\edda";
}
.icon-sharpumbrella:before {
  content: "\eddb";
}
.icon-sharpunarchive:before {
  content: "\eddc";
}
.icon-sharpundo:before {
  content: "\eddd";
}
.icon-sharpunfold_less:before {
  content: "\edde";
}
.icon-sharpunfold_more:before {
  content: "\eddf";
}
.icon-sharpunpublished:before {
  content: "\ede0";
}
.icon-sharpunsubscribe:before {
  content: "\ede1";
}
.icon-sharpupdate:before {
  content: "\ede2";
}
.icon-sharpupgrade:before {
  content: "\ede3";
}
.icon-sharpupload:before {
  content: "\ede4";
}
.icon-sharpusb:before {
  content: "\ede5";
}
.icon-sharpverified:before {
  content: "\ede6";
}
.icon-sharpverified_user:before {
  content: "\ede7";
}
.icon-sharpvertical_align_bottom:before {
  content: "\ede8";
}
.icon-sharpvertical_align_center:before {
  content: "\ede9";
}
.icon-sharpvertical_align_top:before {
  content: "\edea";
}
.icon-sharpvertical_split:before {
  content: "\edeb";
}
.icon-sharpvibration:before {
  content: "\edec";
}
.icon-sharpvideo_call:before {
  content: "\eded";
}
.icon-sharpvideo_label:before {
  content: "\edee";
}
.icon-sharpvideo_library:before {
  content: "\edef";
}
.icon-sharpvideo_settings:before {
  content: "\edf0";
}
.icon-sharpvideocam:before {
  content: "\edf1";
}
.icon-sharpvideocam_off:before {
  content: "\edf2";
}
.icon-sharpvideogame_asset:before {
  content: "\edf3";
}
.icon-sharpview_agenda:before {
  content: "\edf4";
}
.icon-sharpview_array:before {
  content: "\edf5";
}
.icon-sharpview_carousel:before {
  content: "\edf6";
}
.icon-sharpview_column:before {
  content: "\edf7";
}
.icon-sharpview_comfy:before {
  content: "\edf8";
}
.icon-sharpview_compact:before {
  content: "\edf9";
}
.icon-sharpview_day:before {
  content: "\edfa";
}
.icon-sharpview_headline:before {
  content: "\edfb";
}
.icon-sharpview_list:before {
  content: "\edfc";
}
.icon-sharpview_module:before {
  content: "\edfd";
}
.icon-sharpview_quilt:before {
  content: "\edfe";
}
.icon-sharpview_sidebar:before {
  content: "\edff";
}
.icon-sharpview_stream:before {
  content: "\ee00";
}
.icon-sharpview_week:before {
  content: "\ee01";
}
.icon-sharpvignette:before {
  content: "\ee02";
}
.icon-sharpvisibility:before {
  content: "\ee03";
}
.icon-sharpvisibility_off:before {
  content: "\ee04";
}
.icon-sharpvoice_chat:before {
  content: "\ee05";
}
.icon-sharpvoice_over_off:before {
  content: "\ee06";
}
.icon-sharpvoicemail:before {
  content: "\ee07";
}
.icon-sharpvolume_down:before {
  content: "\ee08";
}
.icon-sharpvolume_mute:before {
  content: "\ee09";
}
.icon-sharpvolume_off:before {
  content: "\ee0a";
}
.icon-sharpvolume_up:before {
  content: "\ee0b";
}
.icon-sharpvpn_key:before {
  content: "\ee0c";
}
.icon-sharpvpn_lock:before {
  content: "\ee0d";
}
.icon-sharpwallpaper:before {
  content: "\ee0e";
}
.icon-sharpwarning:before {
  content: "\ee0f";
}
.icon-sharpwarning_amber:before {
  content: "\ee10";
}
.icon-sharpwash:before {
  content: "\ee11";
}
.icon-sharpwatch:before {
  content: "\ee12";
}
.icon-sharpwatch_later:before {
  content: "\ee13";
}
.icon-sharpwater_damage:before {
  content: "\ee14";
}
.icon-sharpwaves:before {
  content: "\ee15";
}
.icon-sharpwb_auto:before {
  content: "\ee16";
}
.icon-sharpwb_cloudy:before {
  content: "\ee17";
}
.icon-sharpwb_incandescent:before {
  content: "\ee18";
}
.icon-sharpwb_iridescent:before {
  content: "\ee19";
}
.icon-sharpwb_sunny:before {
  content: "\ee1a";
}
.icon-sharpwc:before {
  content: "\ee1b";
}
.icon-sharpweb:before {
  content: "\ee1c";
}
.icon-sharpweb_asset:before {
  content: "\ee1d";
}
.icon-sharpweekend:before {
  content: "\ee1e";
}
.icon-sharpwest:before {
  content: "\ee1f";
}
.icon-sharpwhatshot:before {
  content: "\ee20";
}
.icon-sharpwheelchair_pickup:before {
  content: "\ee21";
}
.icon-sharpwhere_to_vote:before {
  content: "\ee22";
}
.icon-sharpwidgets:before {
  content: "\ee23";
}
.icon-sharpwifi:before {
  content: "\ee24";
}
.icon-sharpwifi_calling:before {
  content: "\ee25";
}
.icon-sharpwifi_lock:before {
  content: "\ee26";
}
.icon-sharpwifi_off:before {
  content: "\ee27";
}
.icon-sharpwifi_protected_setup:before {
  content: "\ee28";
}
.icon-sharpwifi_tethering:before {
  content: "\ee29";
}
.icon-sharpwine_bar:before {
  content: "\ee2a";
}
.icon-sharpwork:before {
  content: "\ee2b";
}
.icon-sharpwork_off:before {
  content: "\ee2c";
}
.icon-sharpwork_outline:before {
  content: "\ee2d";
}
.icon-sharpwrap_text:before {
  content: "\ee2e";
}
.icon-sharpwrong_location:before {
  content: "\ee2f";
}
.icon-sharpwysiwyg:before {
  content: "\ee30";
}
.icon-sharpyoutube_searched_for:before {
  content: "\ee31";
}
.icon-sharpzoom_in:before {
  content: "\ee32";
}
.icon-sharpzoom_out:before {
  content: "\ee33";
}
.icon-sharpzoom_out_map:before {
  content: "\ee34";
}
