/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

  @font-face {
    font-family: "GE-SS";
    src: url("src/assets/fonts/GE_SS_UltraLight.otf") format("opentype");
    font-weight: 300;
    text-align: right;
    direction: rtl;
    /* Repeat the following block for each font weight */
  }

  @font-face {
    font-family: "GE-SS";
    src: url("src/assets/fonts/GE_SS_Light.otf") format("opentype");
    font-weight: 400;
    text-align: right;
    direction: rtl;
    /* Repeat the following block for each font weight */
  }

  @font-face {
    font-family: "GE-SS";
    src: url("src/assets/fonts/GE_SS_Medium.otf") format("opentype");
    font-weight: 500;
    text-align: right;
    direction: rtl;
    /* Repeat the following block for each font weight */
  }

  @font-face {
    font-family: "GE-SS";
    src: url("src/assets/fonts/GE_SS_Bold.otf") format("opentype");
    font-weight: 600;
    text-align: right;
    direction: rtl;
    /* Repeat the above block for each font weight */
  }
  html {
    font-family: "Roboto", "GE-SS" !important;
  }
}
@layer utilities {
  .errorInput:focus {
    box-shadow: none;
    border-color: red;
  }
  .errorInputed {
    border-color: red;
  }
  .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .grid-container-lg {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 50% 50%;
  }

  .grid-item-lg {
    text-align: center;
    grid-column: span 2;
  }

  .large {
    grid-column: 2 / span 2;
  }

  .grid-container-md {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .grid-item-md {
    text-align: center;
    grid-column: span 3;
  }

  .mid {
    grid-column: 2 / span 4;
  }

  .empty {
    grid-column: 4 / span 2;
  }

  .country-selector {
    opacity: 1 !important;
  }

  .country-selector input {
    /* height: 40px;*/
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    border-width: 1px;
    display: block;
    --tw-border-opacity: 1;
    border-color: rgb(221 226 228 / var(--tw-border-opacity));
    border-radius: 0.375rem /* 6px */;
    font-size: 0.875rem /* 14px */;
    font-family: "Roboto", "GE-SS" !important;
    line-height: 1.25rem /* 20px */;
    width: 100%;
  }

  .country-selector .ngx-mat-tel-input-container span .flag {
    display: none;
  }

  .country-selector .ngx-mat-tel-input-container button {
    border-width: 1px;
    border-color: rgb(221 226 228 / var(--tw-border-opacity));
    border-radius: 0.375rem /* 6px */;
    display: block;
  }

  .country-selector input:focus {
    box-shadow: none;
    border-color: red;
  }

  .num ul {
    display: flex;
  }

  .num ul li {
    padding: 10px;
  }

  .h1 {
    font-family: "Roboto", "GE-SS" !important;
  }

  .h2 {
    font-family: "Roboto", "GE-SS" !important;
  }

  .h3 {
    font-family: "Roboto", "GE-SS" !important;
  }

  .h4 {
    font-family: "Roboto", "GE-SS" !important;
  }

  .p {
    font-family: "Roboto", "GE-SS" !important;
  }

  .body {
    font-family: "Roboto", "GE-SS" !important;
  }
  .text-2xl {
    font-family: "Roboto", "GE-SS" !important;
  }

  .font-normal {
    font-family: "Roboto", "GE-SS" !important;
  }
  .html {
    font-family: "Roboto", "GE-SS" !important;
  }
  .button,
  input,
  optgroup,
  select,
  textarea {
    font-family: "Roboto", "GE-SS" !important;
  }

  .button:disabled {
    background-color: #59c7ed;
  }
  .btn:disabled {
    background-color: #59c7ed;
  }

  .num ul li a {
    border-color: transparent;
    border-radius: 0.25rem /* 4px */;
    --tw-text-opacity: 1;
    color: rgb(37 44 50 / var(--tw-text-opacity));
    margin-right: 0.25rem /* 4px */;
    font-size: 0.875rem /* 14px */;
    font-family: "Roboto", "GE-SS" !important;
    line-height: 1.25rem /* 20px */;
  }

  .num ul li a:hover {
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(238 240 242 / var(--tw-bg-opacity));
    border-color: transparent;
    border-radius: 0.25rem /* 4px */;
    padding: 5px;
  }

  .bgimage {
    background-size: cover;
    background-position-x: right;
  }

  .bgimagesmall {
    background-position-x: right;
    background-size: cover;
  }

  @keyframes color-me-in {
    0% {
      background-color: #00a9e7;
    }
    /* Adding a step in the middle */
    25% {
      background-color: #8cc63e;
    }
    75% {
      background-color: #00384d;
    }
    100% {
      background-color: #f7931d;
    }
  }
  .btn.btn-light-blue {
    margin: 0 10px 10px 0;
    padding: 4px 12px;
    min-width: 130px;
    height: 40px;
    background: #00a9e7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.006em;
    color: #f6f8f9;
    text-transform: capitalize;
  }

  /* .btn.btn-light-blue:hover,
  .btn.btn-light-blue:active{background: #000;color: #F6F8F9;} */

  .btn.btn-light-blue:hover,
  .btn.btn-light-secondary:hover {
    background: #008dc0;
    color: #fff;
  }

  .btn.btn-light-blue:focus,
  .btn.btn-light-secondary:focus {
    background-color: #00a9e7;
    color: #fff;
  }

  .btn.btn-light-blue:active,
  .btn.btn-light-secondary:active {
    background: #00719a;
    color: #fff;
  }

  .btn.btn-light-secondary {
    margin: 0 10px 10px 0;
    padding: 4px 12px;
    min-width: 170px;
    height: 40px;
    background: #fff;
    border: 1px solid #dde2e4;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.006em;
    color: #252c32;
  }

  /* .btn.btn-light-secondary:hover,
  .btn.btn-light-secondary:active{background: #000;color: #F6F8F9;} */

  .btn-light-transparent {
    background: #ffffff;
    border: 1px solid #00a9e7;
    border-radius: 6px;
    color: #00a9e7;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 7px 16px;
  }

  .btn-light-transparent:hover {
    background: #00a9e7;
    color: #fff;
  }

  button:focus:not(:focus-visible),
  .btn:focus-visible,
  .btn:active:focus {
    outline: none;
    box-shadow: none;
  }
  /* modal styling  */

  .modal-backdrop.show {
    opacity: 0.6;
  }

  .modal-dialog {
    max-width: 560px;
  }

  .solidarity-modal {
    padding: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
      0px 40px 64px rgba(91, 104, 113, 0.24);
    border-radius: 6px;
  }

  .solidarity-modal h2.modal-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #252c32;
  }

  .solidarity-modal p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #5b6871;
    margin-bottom: 8px;
  }

  .solidarity-modal .modal-footer {
    border: none;
    padding: 0 24px 24px;
  }

  .solidarity-modaldoc {
    padding: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
      0px 40px 64px rgba(91, 104, 113, 0.24);
    border-radius: 6px;
  }

  .solidarity-modaldoc h2.modal-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #252c32;
  }

  .solidarity-modaldoc p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #5b6871;
    margin-bottom: 8px;
  }

  .solidarity-modaldoc .modal-footer {
    border: none;
    padding: 0 24px 24px;
  }
  @keyframes color-me-on {
    0% {
      background-color: #8cc63e;
    }
    /* Adding a step in the middle */
    33.3% {
      background-color: #00384d;
    }
    66.6% {
      background-color: #f7931d;
    }
    100% {
      background-color: #00a9e7;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* This is just to transition when you change the viewport size. */
  * {
    transition: all 0.05s ease-out;
  }

  .grad1 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 85 116 / var(--tw-bg-opacity));
  }

  .rotatingBlue {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;
    right: 10%;
    top: 50%;
    bottom: -19.48%;
    animation: color-me-in 5s alternate infinite;
    background-color: #00a9e7;
    filter: blur(111.143px);
    animation-delay: 0s;
  }
  .rotatingBlueMd {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;
    right: 10%;
    top: 50%;
    bottom: -19.48%;
    animation: color-me-in 5s alternate infinite;
    background-color: #00a9e7;
    filter: blur(111.143px);
    animation-delay: 0s;
  }
  .rotatingBlue2 {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;
    right: 0%;
    top: 30%;
    bottom: -19.48%;
    animation: color-me-on 5s alternate infinite;
    background-color: #00a9e7;
    filter: blur(111.143px);
    animation-delay: 0s;
  }
  .rotatingBlue2Md {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;
    right: 0%;
    top: 30%;
    bottom: -19.48%;
    animation: color-me-on 5s alternate infinite;
    background-color: #00a9e7;
    filter: blur(111.143px);
    animation-delay: 0s;
  }

  .rotatingGreen {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;

    right: 25%;
    top: 50%;
    bottom: -19.48%;
    animation: rotate var(--speed) alternate infinite;
    background-color: #8cc63e;
    filter: blur(111.143px);
    animation-delay: 2s;
  }

  .rotatingDarBlue {
    --size: 250px;
    --speed: 5s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    --tw-bg-opacity: 1;
    position: absolute;

    right: 1%;
    top: 20%;
    bottom: -19.48%;
    animation: rotate var(--speed) alternate infinite;
    background-color: #00384d;
    filter: blur(177.829px);
    animation-delay: 4s;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup {
    background-color: #fff;
    max-width: 400px;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }

  .popup-title {
    font-size: 24px;
    font-family: "Roboto", "GE-SS" !important;
    margin-bottom: 20px;
  }

  .popup-message {
    margin-bottom: 20px;
  }

  .popup-message p {
    margin: 0;
  }

  .popup-button {
    background-color: #0486c2;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }

  /*Table Styling */
  .table-responsive-parent {
    border: 1px solid #e5e9eb;
    border-radius: 7px;
    overflow: hidden;
  }
  .table-responsive table {
    margin: 0;
  }
  .table-responsive table tbody tr:last-child td {
    border-bottom: none;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: none !important;
  }
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #00a9e7;
    border: none;
  }
  .table-responsive::-webkit-scrollbar-track {
    background: #9e9e9e57 !important;
    border: none !important;
  }

  .xp {
    /* padding-top:px; */
    border: 1px solid #e5e9eb;
    border-radius: 7px;
    overflow: hidden;
    width: 100%;
  }

  .container-table100 {
    max-width: 1366px;
    margin: 0 auto;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 33px 100px;
  }

  /* .image-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px; 
  margin: 0 auto;
}

.slide {
  display: none;
  width: 100%;
  text-align: center;
  
}

.slide img {
  width: 100%;
  height: auto;
}

.slider-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #888;
  margin: 0 5px;
  cursor: pointer;
}

.slider-button.active {
  background-color: #333;
} */

  .slider {
    overflow: hidden;
    width: 100%;
  }

  .slides-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    flex: 0 0 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* toggle-switch.component.css or your global CSS file */
  .toggle__line {
    width: 60px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */ /* Your desired background color for the line */
    border-radius: 9999px; /* Makes it a circle */
    position: relative;
  }

  .toggle__dot {
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Should be the same as the line's height for proper alignment */
    border-radius: 50%; /* Makes it a circle */
    position: absolute;
    transition: transform 0.3s; /* Add a smooth transition effect when moving the dot */
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .fade-out {
    animation: fadeOut 5s ease-out; /* Adjust the duration and timing function as needed */
  }
}
