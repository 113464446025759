@font-face {
  font-family: 'rounded';
  src:  url('fonts/rounded.eot?1aurdq');
  src:  url('fonts/rounded.eot?1aurdq#iefix') format('embedded-opentype'),
    url('fonts/rounded.ttf?1aurdq') format('truetype'),
    url('fonts/rounded.woff?1aurdq') format('woff'),
    url('fonts/rounded.svg?1aurdq#rounded') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-rounded"], [class*=" icon-rounded"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'rounded' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-roundedd_rotation:before {
  content: "\e900";
}
.icon-roundedk:before {
  content: "\e901";
}
.icon-roundedg:before {
  content: "\e902";
}
.icon-roundedft_apart:before {
  content: "\e903";
}
.icon-rounded360:before {
  content: "\e904";
}
.icon-roundedac_unit:before {
  content: "\e905";
}
.icon-roundedaccess_alarm:before {
  content: "\e906";
}
.icon-roundedaccess_alarms:before {
  content: "\e907";
}
.icon-roundedaccess_time:before {
  content: "\e908";
}
.icon-roundedaccessibility:before {
  content: "\e909";
}
.icon-roundedaccessibility_new:before {
  content: "\e90a";
}
.icon-roundedaccessible:before {
  content: "\e90b";
}
.icon-roundedaccessible_forward:before {
  content: "\e90c";
}
.icon-roundedaccount_balance:before {
  content: "\e90d";
}
.icon-roundedaccount_balance_wallet:before {
  content: "\e90e";
}
.icon-roundedaccount_box:before {
  content: "\e90f";
}
.icon-roundedaccount_circle:before {
  content: "\e910";
}
.icon-roundedaccount_tree:before {
  content: "\e911";
}
.icon-roundedad_units:before {
  content: "\e912";
}
.icon-roundedadb:before {
  content: "\e913";
}
.icon-roundedadd:before {
  content: "\e914";
}
.icon-roundedadd_a_photo:before {
  content: "\e915";
}
.icon-roundedadd_alarm:before {
  content: "\e916";
}
.icon-roundedadd_alert:before {
  content: "\e917";
}
.icon-roundedadd_box:before {
  content: "\e918";
}
.icon-roundedadd_business:before {
  content: "\e919";
}
.icon-roundedadd_circle:before {
  content: "\e91a";
}
.icon-roundedadd_circle_outline:before {
  content: "\e91b";
}
.icon-roundedadd_comment:before {
  content: "\e91c";
}
.icon-roundedadd_ic_call:before {
  content: "\e91d";
}
.icon-roundedadd_location:before {
  content: "\e91e";
}
.icon-roundedadd_location_alt:before {
  content: "\e91f";
}
.icon-roundedadd_photo_alternate:before {
  content: "\e920";
}
.icon-roundedadd_road:before {
  content: "\e921";
}
.icon-roundedadd_shopping_cart:before {
  content: "\e922";
}
.icon-roundedadd_task:before {
  content: "\e923";
}
.icon-roundedadd_to_home_screen:before {
  content: "\e924";
}
.icon-roundedadd_to_photos:before {
  content: "\e925";
}
.icon-roundedadd_to_queue:before {
  content: "\e926";
}
.icon-roundedaddchart:before {
  content: "\e927";
}
.icon-roundedadjust:before {
  content: "\e928";
}
.icon-roundedadmin_panel_settings:before {
  content: "\e929";
}
.icon-roundedagriculture:before {
  content: "\e92a";
}
.icon-roundedairline_seat_flat:before {
  content: "\e92b";
}
.icon-roundedairline_seat_flat_angled:before {
  content: "\e92c";
}
.icon-roundedairline_seat_individual_suite:before {
  content: "\e92d";
}
.icon-roundedairline_seat_legroom_extra:before {
  content: "\e92e";
}
.icon-roundedairline_seat_legroom_normal:before {
  content: "\e92f";
}
.icon-roundedairline_seat_legroom_reduced:before {
  content: "\e930";
}
.icon-roundedairline_seat_recline_extra:before {
  content: "\e931";
}
.icon-roundedairline_seat_recline_normal:before {
  content: "\e932";
}
.icon-roundedairplanemode_active:before {
  content: "\e933";
}
.icon-roundedairplanemode_inactive:before {
  content: "\e934";
}
.icon-roundedairplay:before {
  content: "\e935";
}
.icon-roundedairport_shuttle:before {
  content: "\e936";
}
.icon-roundedalarm:before {
  content: "\e937";
}
.icon-roundedalarm_add:before {
  content: "\e938";
}
.icon-roundedalarm_off:before {
  content: "\e939";
}
.icon-roundedalarm_on:before {
  content: "\e93a";
}
.icon-roundedalbum:before {
  content: "\e93b";
}
.icon-roundedall_inbox:before {
  content: "\e93c";
}
.icon-roundedall_inclusive:before {
  content: "\e93d";
}
.icon-roundedall_out:before {
  content: "\e93e";
}
.icon-roundedalt_route:before {
  content: "\e93f";
}
.icon-roundedalternate_email:before {
  content: "\e940";
}
.icon-roundedamp_stories:before {
  content: "\e941";
}
.icon-roundedanalytics:before {
  content: "\e942";
}
.icon-roundedanchor:before {
  content: "\e943";
}
.icon-roundedandroid:before {
  content: "\e944";
}
.icon-roundedannouncement:before {
  content: "\e945";
}
.icon-roundedapartment:before {
  content: "\e946";
}
.icon-roundedapi:before {
  content: "\e947";
}
.icon-roundedapp_blocking:before {
  content: "\e948";
}
.icon-roundedapp_settings_alt:before {
  content: "\e949";
}
.icon-roundedapps:before {
  content: "\e94a";
}
.icon-roundedarchitecture:before {
  content: "\e94b";
}
.icon-roundedarchive:before {
  content: "\e94c";
}
.icon-roundedarrow_back:before {
  content: "\e94d";
}
.icon-roundedarrow_back_ios:before {
  content: "\e94e";
}
.icon-roundedarrow_circle_down:before {
  content: "\e94f";
}
.icon-roundedarrow_circle_up:before {
  content: "\e950";
}
.icon-roundedarrow_downward:before {
  content: "\e951";
}
.icon-roundedarrow_drop_down:before {
  content: "\e952";
}
.icon-roundedarrow_drop_down_circle:before {
  content: "\e953";
}
.icon-roundedarrow_drop_up:before {
  content: "\e954";
}
.icon-roundedarrow_forward:before {
  content: "\e955";
}
.icon-roundedarrow_forward_ios:before {
  content: "\e956";
}
.icon-roundedarrow_left:before {
  content: "\e957";
}
.icon-roundedarrow_right:before {
  content: "\e958";
}
.icon-roundedarrow_right_alt:before {
  content: "\e959";
}
.icon-roundedarrow_upward:before {
  content: "\e95a";
}
.icon-roundedart_track:before {
  content: "\e95b";
}
.icon-roundedarticle:before {
  content: "\e95c";
}
.icon-roundedaspect_ratio:before {
  content: "\e95d";
}
.icon-roundedassessment:before {
  content: "\e95e";
}
.icon-roundedassignment:before {
  content: "\e95f";
}
.icon-roundedassignment_ind:before {
  content: "\e960";
}
.icon-roundedassignment_late:before {
  content: "\e961";
}
.icon-roundedassignment_return:before {
  content: "\e962";
}
.icon-roundedassignment_returned:before {
  content: "\e963";
}
.icon-roundedassignment_turned_in:before {
  content: "\e964";
}
.icon-roundedassistant:before {
  content: "\e965";
}
.icon-roundedassistant_photo:before {
  content: "\e966";
}
.icon-roundedatm:before {
  content: "\e967";
}
.icon-roundedattach_email:before {
  content: "\e968";
}
.icon-roundedattach_file:before {
  content: "\e969";
}
.icon-roundedattach_money:before {
  content: "\e96a";
}
.icon-roundedattachment:before {
  content: "\e96b";
}
.icon-roundedattribution:before {
  content: "\e96c";
}
.icon-roundedaudiotrack:before {
  content: "\e96d";
}
.icon-roundedauto_delete:before {
  content: "\e96e";
}
.icon-roundedautorenew:before {
  content: "\e96f";
}
.icon-roundedav_timer:before {
  content: "\e970";
}
.icon-roundedbaby_changing_station:before {
  content: "\e971";
}
.icon-roundedbackpack:before {
  content: "\e972";
}
.icon-roundedbackspace:before {
  content: "\e973";
}
.icon-roundedbackup:before {
  content: "\e974";
}
.icon-roundedbackup_table:before {
  content: "\e975";
}
.icon-roundedballot:before {
  content: "\e976";
}
.icon-roundedbar_chart:before {
  content: "\e977";
}
.icon-roundedbatch_prediction:before {
  content: "\e978";
}
.icon-roundedbathtub:before {
  content: "\e979";
}
.icon-roundedbattery_alert:before {
  content: "\e97a";
}
.icon-roundedbattery_charging_full:before {
  content: "\e97b";
}
.icon-roundedbattery_full:before {
  content: "\e97c";
}
.icon-roundedbattery_std:before {
  content: "\e97d";
}
.icon-roundedbattery_unknown:before {
  content: "\e97e";
}
.icon-roundedbeach_access:before {
  content: "\e97f";
}
.icon-roundedbedtime:before {
  content: "\e980";
}
.icon-roundedbeenhere:before {
  content: "\e981";
}
.icon-roundedbento:before {
  content: "\e982";
}
.icon-roundedbike_scooter:before {
  content: "\e983";
}
.icon-roundedbiotech:before {
  content: "\e984";
}
.icon-roundedblock:before {
  content: "\e985";
}
.icon-roundedbluetooth:before {
  content: "\e986";
}
.icon-roundedbluetooth_audio:before {
  content: "\e987";
}
.icon-roundedbluetooth_connected:before {
  content: "\e988";
}
.icon-roundedbluetooth_disabled:before {
  content: "\e989";
}
.icon-roundedbluetooth_searching:before {
  content: "\e98a";
}
.icon-roundedblur_circular:before {
  content: "\e98b";
}
.icon-roundedblur_linear:before {
  content: "\e98c";
}
.icon-roundedblur_off:before {
  content: "\e98d";
}
.icon-roundedblur_on:before {
  content: "\e98e";
}
.icon-roundedbook:before {
  content: "\e98f";
}
.icon-roundedbook_online:before {
  content: "\e990";
}
.icon-roundedbookmark:before {
  content: "\e991";
}
.icon-roundedbookmark_border:before {
  content: "\e992";
}
.icon-roundedbookmarks:before {
  content: "\e993";
}
.icon-roundedborder_all:before {
  content: "\e994";
}
.icon-roundedborder_bottom:before {
  content: "\e995";
}
.icon-roundedborder_clear:before {
  content: "\e996";
}
.icon-roundedborder_horizontal:before {
  content: "\e997";
}
.icon-roundedborder_inner:before {
  content: "\e998";
}
.icon-roundedborder_left:before {
  content: "\e999";
}
.icon-roundedborder_outer:before {
  content: "\e99a";
}
.icon-roundedborder_right:before {
  content: "\e99b";
}
.icon-roundedborder_style:before {
  content: "\e99c";
}
.icon-roundedborder_top:before {
  content: "\e99d";
}
.icon-roundedborder_vertical:before {
  content: "\e99e";
}
.icon-roundedbranding_watermark:before {
  content: "\e99f";
}
.icon-roundedbrightness_1:before {
  content: "\e9a0";
}
.icon-roundedbrightness_2:before {
  content: "\e9a1";
}
.icon-roundedbrightness_3:before {
  content: "\e9a2";
}
.icon-roundedbrightness_4:before {
  content: "\e9a3";
}
.icon-roundedbrightness_5:before {
  content: "\e9a4";
}
.icon-roundedbrightness_6:before {
  content: "\e9a5";
}
.icon-roundedbrightness_7:before {
  content: "\e9a6";
}
.icon-roundedbrightness_auto:before {
  content: "\e9a7";
}
.icon-roundedbrightness_high:before {
  content: "\e9a8";
}
.icon-roundedbrightness_low:before {
  content: "\e9a9";
}
.icon-roundedbrightness_medium:before {
  content: "\e9aa";
}
.icon-roundedbroken_image:before {
  content: "\e9ab";
}
.icon-roundedbrowser_not_supported:before {
  content: "\e9ac";
}
.icon-roundedbrush:before {
  content: "\e9ad";
}
.icon-roundedbubble_chart:before {
  content: "\e9ae";
}
.icon-roundedbug_report:before {
  content: "\e9af";
}
.icon-roundedbuild:before {
  content: "\e9b0";
}
.icon-roundedbuild_circle:before {
  content: "\e9b1";
}
.icon-roundedburst_mode:before {
  content: "\e9b2";
}
.icon-roundedbusiness:before {
  content: "\e9b3";
}
.icon-roundedbusiness_center:before {
  content: "\e9b4";
}
.icon-roundedcached:before {
  content: "\e9b5";
}
.icon-roundedcake:before {
  content: "\e9b6";
}
.icon-roundedcalculate:before {
  content: "\e9b7";
}
.icon-roundedcalendar_today:before {
  content: "\e9b8";
}
.icon-roundedcalendar_view_day:before {
  content: "\e9b9";
}
.icon-roundedcall:before {
  content: "\e9ba";
}
.icon-roundedcall_end:before {
  content: "\e9bb";
}
.icon-roundedcall_made:before {
  content: "\e9bc";
}
.icon-roundedcall_merge:before {
  content: "\e9bd";
}
.icon-roundedcall_missed:before {
  content: "\e9be";
}
.icon-roundedcall_missed_outgoing:before {
  content: "\e9bf";
}
.icon-roundedcall_received:before {
  content: "\e9c0";
}
.icon-roundedcall_split:before {
  content: "\e9c1";
}
.icon-roundedcall_to_action:before {
  content: "\e9c2";
}
.icon-roundedcamera:before {
  content: "\e9c3";
}
.icon-roundedcamera_alt:before {
  content: "\e9c4";
}
.icon-roundedcamera_enhance:before {
  content: "\e9c5";
}
.icon-roundedcamera_front:before {
  content: "\e9c6";
}
.icon-roundedcamera_rear:before {
  content: "\e9c7";
}
.icon-roundedcamera_roll:before {
  content: "\e9c8";
}
.icon-roundedcampaign:before {
  content: "\e9c9";
}
.icon-roundedcancel:before {
  content: "\e9ca";
}
.icon-roundedcancel_presentation:before {
  content: "\e9cb";
}
.icon-roundedcancel_schedule_send:before {
  content: "\e9cc";
}
.icon-roundedcard_giftcard:before {
  content: "\e9cd";
}
.icon-roundedcard_membership:before {
  content: "\e9ce";
}
.icon-roundedcard_travel:before {
  content: "\e9cf";
}
.icon-roundedcarpenter:before {
  content: "\e9d0";
}
.icon-roundedcasino:before {
  content: "\e9d1";
}
.icon-roundedcast:before {
  content: "\e9d2";
}
.icon-roundedcast_connected:before {
  content: "\e9d3";
}
.icon-roundedcast_for_education:before {
  content: "\e9d4";
}
.icon-roundedcategory:before {
  content: "\e9d5";
}
.icon-roundedcenter_focus_strong:before {
  content: "\e9d6";
}
.icon-roundedcenter_focus_weak:before {
  content: "\e9d7";
}
.icon-roundedchange_history:before {
  content: "\e9d8";
}
.icon-roundedcharging_station:before {
  content: "\e9d9";
}
.icon-roundedchat:before {
  content: "\e9da";
}
.icon-roundedchat_bubble:before {
  content: "\e9db";
}
.icon-roundedchat_bubble_outline:before {
  content: "\e9dc";
}
.icon-roundedcheck:before {
  content: "\e9dd";
}
.icon-roundedcheck_box:before {
  content: "\e9de";
}
.icon-roundedcheck_box_outline_blank:before {
  content: "\e9df";
}
.icon-roundedcheck_circle:before {
  content: "\e9e0";
}
.icon-roundedcheck_circle_outline:before {
  content: "\e9e1";
}
.icon-roundedcheckroom:before {
  content: "\e9e2";
}
.icon-roundedchevron_left:before {
  content: "\e9e3";
}
.icon-roundedchevron_right:before {
  content: "\e9e4";
}
.icon-roundedchild_care:before {
  content: "\e9e5";
}
.icon-roundedchild_friendly:before {
  content: "\e9e6";
}
.icon-roundedchrome_reader_mode:before {
  content: "\e9e7";
}
.icon-roundedCircle:before {
  content: "\e9e8";
}
.icon-roundedclass:before {
  content: "\e9e9";
}
.icon-roundedclean_hands:before {
  content: "\e9ea";
}
.icon-roundedcleaning_services:before {
  content: "\e9eb";
}
.icon-roundedclear:before {
  content: "\e9ec";
}
.icon-roundedclear_all:before {
  content: "\e9ed";
}
.icon-roundedclose:before {
  content: "\e9ee";
}
.icon-roundedclose_fullscreen:before {
  content: "\e9ef";
}
.icon-roundedclosed_caption:before {
  content: "\e9f0";
}
.icon-roundedclosed_caption_disabled:before {
  content: "\e9f1";
}
.icon-roundedcloud:before {
  content: "\e9f2";
}
.icon-roundedcloud_circle:before {
  content: "\e9f3";
}
.icon-roundedcloud_done:before {
  content: "\e9f4";
}
.icon-roundedcloud_download:before {
  content: "\e9f5";
}
.icon-roundedcloud_off:before {
  content: "\e9f6";
}
.icon-roundedcloud_queue:before {
  content: "\e9f7";
}
.icon-roundedcloud_upload:before {
  content: "\e9f8";
}
.icon-roundedcode:before {
  content: "\e9f9";
}
.icon-roundedcollections:before {
  content: "\e9fa";
}
.icon-roundedcollections_bookmark:before {
  content: "\e9fb";
}
.icon-roundedcolor_lens:before {
  content: "\e9fc";
}
.icon-roundedcolorize:before {
  content: "\e9fd";
}
.icon-roundedcomment:before {
  content: "\e9fe";
}
.icon-roundedcomment_bank:before {
  content: "\e9ff";
}
.icon-roundedcommute:before {
  content: "\ea00";
}
.icon-roundedcompare:before {
  content: "\ea01";
}
.icon-roundedcompare_arrows:before {
  content: "\ea02";
}
.icon-roundedcompass_calibration:before {
  content: "\ea03";
}
.icon-roundedcomputer:before {
  content: "\ea04";
}
.icon-roundedconfirmation_number:before {
  content: "\ea05";
}
.icon-roundedconnect_without_contact:before {
  content: "\ea06";
}
.icon-roundedconstruction:before {
  content: "\ea07";
}
.icon-roundedcontact_mail:before {
  content: "\ea08";
}
.icon-roundedcontact_page:before {
  content: "\ea09";
}
.icon-roundedcontact_phone:before {
  content: "\ea0a";
}
.icon-roundedcontact_support:before {
  content: "\ea0b";
}
.icon-roundedcontactless:before {
  content: "\ea0c";
}
.icon-roundedcontacts:before {
  content: "\ea0d";
}
.icon-roundedcontent_copy:before {
  content: "\ea0e";
}
.icon-roundedcontent_cut:before {
  content: "\ea0f";
}
.icon-roundedcontent_paste:before {
  content: "\ea10";
}
.icon-roundedcontrol_camera:before {
  content: "\ea11";
}
.icon-roundedcontrol_point:before {
  content: "\ea12";
}
.icon-roundedcontrol_point_duplicate:before {
  content: "\ea13";
}
.icon-roundedcopyright:before {
  content: "\ea14";
}
.icon-roundedcoronavirus:before {
  content: "\ea15";
}
.icon-roundedcorporate_fare:before {
  content: "\ea16";
}
.icon-roundedcountertops:before {
  content: "\ea17";
}
.icon-roundedcreate:before {
  content: "\ea18";
}
.icon-roundedcreate_new_folder:before {
  content: "\ea19";
}
.icon-roundedcredit_card:before {
  content: "\ea1a";
}
.icon-roundedcrop:before {
  content: "\ea1b";
}
.icon-roundedcrop_3_2:before {
  content: "\ea1c";
}
.icon-roundedcrop_5_4:before {
  content: "\ea1d";
}
.icon-roundedcrop_7_5:before {
  content: "\ea1e";
}
.icon-roundedcrop_16_9:before {
  content: "\ea1f";
}
.icon-roundedcrop_din:before {
  content: "\ea20";
}
.icon-roundedcrop_free:before {
  content: "\ea21";
}
.icon-roundedcrop_landscape:before {
  content: "\ea22";
}
.icon-roundedcrop_original:before {
  content: "\ea23";
}
.icon-roundedcrop_portrait:before {
  content: "\ea24";
}
.icon-roundedcrop_rotate:before {
  content: "\ea25";
}
.icon-roundedcrop_square:before {
  content: "\ea26";
}
.icon-roundeddashboard:before {
  content: "\ea27";
}
.icon-roundeddata_usage:before {
  content: "\ea28";
}
.icon-roundeddate_range:before {
  content: "\ea29";
}
.icon-roundeddeck:before {
  content: "\ea2a";
}
.icon-roundeddehaze:before {
  content: "\ea2b";
}
.icon-roundeddelete:before {
  content: "\ea2c";
}
.icon-roundeddelete_forever:before {
  content: "\ea2d";
}
.icon-roundeddelete_outline:before {
  content: "\ea2e";
}
.icon-roundeddelete_sweep:before {
  content: "\ea2f";
}
.icon-roundeddeparture_board:before {
  content: "\ea30";
}
.icon-roundeddescription:before {
  content: "\ea31";
}
.icon-roundeddesign_services:before {
  content: "\ea32";
}
.icon-roundeddesktop_access_disabled:before {
  content: "\ea33";
}
.icon-roundeddesktop_mac:before {
  content: "\ea34";
}
.icon-roundeddesktop_windows:before {
  content: "\ea35";
}
.icon-roundeddetails:before {
  content: "\ea36";
}
.icon-roundeddeveloper_board:before {
  content: "\ea37";
}
.icon-roundeddeveloper_mode:before {
  content: "\ea38";
}
.icon-roundeddevice_hub:before {
  content: "\ea39";
}
.icon-roundeddevice_unknown:before {
  content: "\ea3a";
}
.icon-roundeddevices:before {
  content: "\ea3b";
}
.icon-roundeddevices_other:before {
  content: "\ea3c";
}
.icon-roundeddialer_sip:before {
  content: "\ea3d";
}
.icon-roundeddialpad:before {
  content: "\ea3e";
}
.icon-roundeddirections:before {
  content: "\ea3f";
}
.icon-roundeddirections_bike:before {
  content: "\ea40";
}
.icon-roundeddirections_boat:before {
  content: "\ea41";
}
.icon-roundeddirections_bus:before {
  content: "\ea42";
}
.icon-roundeddirections_car:before {
  content: "\ea43";
}
.icon-roundeddirections_off:before {
  content: "\ea44";
}
.icon-roundeddirections_railway:before {
  content: "\ea45";
}
.icon-roundeddirections_run:before {
  content: "\ea46";
}
.icon-roundeddirections_subway:before {
  content: "\ea47";
}
.icon-roundeddirections_transit:before {
  content: "\ea48";
}
.icon-roundeddirections_walk:before {
  content: "\ea49";
}
.icon-roundeddisabled_by_default:before {
  content: "\ea4a";
}
.icon-roundeddisc_full:before {
  content: "\ea4b";
}
.icon-roundeddns:before {
  content: "\ea4c";
}
.icon-roundeddo_disturb:before {
  content: "\ea4d";
}
.icon-roundeddo_disturb_alt:before {
  content: "\ea4e";
}
.icon-roundeddo_disturb_off:before {
  content: "\ea4f";
}
.icon-roundeddo_disturb_on:before {
  content: "\ea50";
}
.icon-roundeddo_not_step:before {
  content: "\ea51";
}
.icon-roundeddo_not_touch:before {
  content: "\ea52";
}
.icon-roundeddock:before {
  content: "\ea53";
}
.icon-roundeddomain:before {
  content: "\ea54";
}
.icon-roundeddomain_disabled:before {
  content: "\ea55";
}
.icon-roundeddomain_verification:before {
  content: "\ea56";
}
.icon-roundeddone:before {
  content: "\ea57";
}
.icon-roundeddone_all:before {
  content: "\ea58";
}
.icon-roundeddone_outline:before {
  content: "\ea59";
}
.icon-roundeddonut_large:before {
  content: "\ea5a";
}
.icon-roundeddonut_small:before {
  content: "\ea5b";
}
.icon-roundeddouble_arrow:before {
  content: "\ea5c";
}
.icon-roundeddownload:before {
  content: "\ea5d";
}
.icon-roundeddownload_done:before {
  content: "\ea5e";
}
.icon-roundeddrafts:before {
  content: "\ea5f";
}
.icon-roundeddrag_handle:before {
  content: "\ea60";
}
.icon-roundeddrag_indicator:before {
  content: "\ea61";
}
.icon-roundeddrive_eta:before {
  content: "\ea62";
}
.icon-roundeddry:before {
  content: "\ea63";
}
.icon-roundedduo:before {
  content: "\ea64";
}
.icon-roundeddvr:before {
  content: "\ea65";
}
.icon-roundeddynamic_feed:before {
  content: "\ea66";
}
.icon-roundeddynamic_form:before {
  content: "\ea67";
}
.icon-roundedeast:before {
  content: "\ea68";
}
.icon-roundedeco:before {
  content: "\ea69";
}
.icon-roundededit:before {
  content: "\ea6a";
}
.icon-roundededit_attributes:before {
  content: "\ea6b";
}
.icon-roundededit_location:before {
  content: "\ea6c";
}
.icon-roundededit_road:before {
  content: "\ea6d";
}
.icon-roundedeject:before {
  content: "\ea6e";
}
.icon-roundedelderly:before {
  content: "\ea6f";
}
.icon-roundedelectric_bike:before {
  content: "\ea70";
}
.icon-roundedelectric_car:before {
  content: "\ea71";
}
.icon-roundedelectric_moped:before {
  content: "\ea72";
}
.icon-roundedelectric_rickshaw:before {
  content: "\ea73";
}
.icon-roundedelectric_scooter:before {
  content: "\ea74";
}
.icon-roundedelectrical_services:before {
  content: "\ea75";
}
.icon-roundedelevator:before {
  content: "\ea76";
}
.icon-roundedemail:before {
  content: "\ea77";
}
.icon-roundedemoji_emotions:before {
  content: "\ea78";
}
.icon-roundedemoji_events:before {
  content: "\ea79";
}
.icon-roundedemoji_flags:before {
  content: "\ea7a";
}
.icon-roundedemoji_food_beverage:before {
  content: "\ea7b";
}
.icon-roundedemoji_nature:before {
  content: "\ea7c";
}
.icon-roundedemoji_objects:before {
  content: "\ea7d";
}
.icon-roundedemoji_people:before {
  content: "\ea7e";
}
.icon-roundedemoji_symbols:before {
  content: "\ea7f";
}
.icon-roundedemoji_transportation:before {
  content: "\ea80";
}
.icon-roundedengineering:before {
  content: "\ea81";
}
.icon-roundedenhanced_encryption:before {
  content: "\ea82";
}
.icon-roundedequalizer:before {
  content: "\ea83";
}
.icon-roundederror:before {
  content: "\ea84";
}
.icon-roundederror_outline:before {
  content: "\ea85";
}
.icon-roundedescalator:before {
  content: "\ea86";
}
.icon-roundedescalator_warning:before {
  content: "\ea87";
}
.icon-roundedeuro:before {
  content: "\ea88";
}
.icon-roundedeuro_symbol:before {
  content: "\ea89";
}
.icon-roundedev_station:before {
  content: "\ea8a";
}
.icon-roundedevent:before {
  content: "\ea8b";
}
.icon-roundedevent_available:before {
  content: "\ea8c";
}
.icon-roundedevent_busy:before {
  content: "\ea8d";
}
.icon-roundedevent_note:before {
  content: "\ea8e";
}
.icon-roundedevent_seat:before {
  content: "\ea8f";
}
.icon-roundedexit_to_app:before {
  content: "\ea90";
}
.icon-roundedexpand_less:before {
  content: "\ea91";
}
.icon-roundedexpand_more:before {
  content: "\ea92";
}
.icon-roundedexplicit:before {
  content: "\ea93";
}
.icon-roundedexplore:before {
  content: "\ea94";
}
.icon-roundedexplore_off:before {
  content: "\ea95";
}
.icon-roundedexposure:before {
  content: "\ea96";
}
.icon-roundedexposure_neg_1:before {
  content: "\ea97";
}
.icon-roundedexposure_neg_2:before {
  content: "\ea98";
}
.icon-roundedexposure_plus_1:before {
  content: "\ea99";
}
.icon-roundedexposure_plus_2:before {
  content: "\ea9a";
}
.icon-roundedexposure_zero:before {
  content: "\ea9b";
}
.icon-roundedextension:before {
  content: "\ea9c";
}
.icon-roundedface:before {
  content: "\ea9d";
}
.icon-roundedface_unlock:before {
  content: "\ea9e";
}
.icon-roundedfacebook:before {
  content: "\ea9f";
}
.icon-roundedfact_check:before {
  content: "\eaa0";
}
.icon-roundedfamily_restroom:before {
  content: "\eaa1";
}
.icon-roundedfast_forward:before {
  content: "\eaa2";
}
.icon-roundedfast_rewind:before {
  content: "\eaa3";
}
.icon-roundedfastfood:before {
  content: "\eaa4";
}
.icon-roundedfavorite:before {
  content: "\eaa5";
}
.icon-roundedfavorite_border:before {
  content: "\eaa6";
}
.icon-roundedfeatured_play_list:before {
  content: "\eaa7";
}
.icon-roundedfeatured_video:before {
  content: "\eaa8";
}
.icon-roundedfeedback:before {
  content: "\eaa9";
}
.icon-roundedfence:before {
  content: "\eaaa";
}
.icon-roundedfiber_dvr:before {
  content: "\eaab";
}
.icon-roundedfiber_manual_record:before {
  content: "\eaac";
}
.icon-roundedfiber_new:before {
  content: "\eaad";
}
.icon-roundedfiber_pin:before {
  content: "\eaae";
}
.icon-roundedfiber_smart_record:before {
  content: "\eaaf";
}
.icon-roundedfile_copy:before {
  content: "\eab0";
}
.icon-roundedfilter:before {
  content: "\eab1";
}
.icon-roundedfilter_1:before {
  content: "\eab2";
}
.icon-roundedfilter_2:before {
  content: "\eab3";
}
.icon-roundedfilter_3:before {
  content: "\eab4";
}
.icon-roundedfilter_4:before {
  content: "\eab5";
}
.icon-roundedfilter_5:before {
  content: "\eab6";
}
.icon-roundedfilter_6:before {
  content: "\eab7";
}
.icon-roundedfilter_7:before {
  content: "\eab8";
}
.icon-roundedfilter_8:before {
  content: "\eab9";
}
.icon-roundedfilter_9:before {
  content: "\eaba";
}
.icon-roundedfilter_9_plus:before {
  content: "\eabb";
}
.icon-roundedfilter_alt:before {
  content: "\eabc";
}
.icon-roundedfilter_b_and_w:before {
  content: "\eabd";
}
.icon-roundedfilter_center_focus:before {
  content: "\eabe";
}
.icon-roundedfilter_drama:before {
  content: "\eabf";
}
.icon-roundedfilter_frames:before {
  content: "\eac0";
}
.icon-roundedfilter_hdr:before {
  content: "\eac1";
}
.icon-roundedfilter_list:before {
  content: "\eac2";
}
.icon-roundedfilter_none:before {
  content: "\eac3";
}
.icon-roundedfilter_tilt_shift:before {
  content: "\eac4";
}
.icon-roundedfilter_vintage:before {
  content: "\eac5";
}
.icon-roundedfind_in_page:before {
  content: "\eac6";
}
.icon-roundedfind_replace:before {
  content: "\eac7";
}
.icon-roundedfingerprint:before {
  content: "\eac8";
}
.icon-roundedfire_extinguisher:before {
  content: "\eac9";
}
.icon-roundedfireplace:before {
  content: "\eaca";
}
.icon-roundedfirst_page:before {
  content: "\eacb";
}
.icon-roundedfitness_center:before {
  content: "\eacc";
}
.icon-roundedflag:before {
  content: "\eacd";
}
.icon-roundedflaky:before {
  content: "\eace";
}
.icon-roundedflare:before {
  content: "\eacf";
}
.icon-roundedflash_auto:before {
  content: "\ead0";
}
.icon-roundedflash_off:before {
  content: "\ead1";
}
.icon-roundedflash_on:before {
  content: "\ead2";
}
.icon-roundedflight:before {
  content: "\ead3";
}
.icon-roundedflight_land:before {
  content: "\ead4";
}
.icon-roundedflight_takeoff:before {
  content: "\ead5";
}
.icon-roundedflip:before {
  content: "\ead6";
}
.icon-roundedflip_camera_android:before {
  content: "\ead7";
}
.icon-roundedflip_camera_ios:before {
  content: "\ead8";
}
.icon-roundedflip_to_back:before {
  content: "\ead9";
}
.icon-roundedflip_to_front:before {
  content: "\eada";
}
.icon-roundedfolder:before {
  content: "\eadb";
}
.icon-roundedfolder_open:before {
  content: "\eadc";
}
.icon-roundedfolder_shared:before {
  content: "\eadd";
}
.icon-roundedfolder_special:before {
  content: "\eade";
}
.icon-roundedfollow_the_signs:before {
  content: "\eadf";
}
.icon-roundedfont_download:before {
  content: "\eae0";
}
.icon-roundedfood_bank:before {
  content: "\eae1";
}
.icon-roundedformat_align_center:before {
  content: "\eae2";
}
.icon-roundedformat_align_justify:before {
  content: "\eae3";
}
.icon-roundedformat_align_left:before {
  content: "\eae4";
}
.icon-roundedformat_align_right:before {
  content: "\eae5";
}
.icon-roundedformat_bold:before {
  content: "\eae6";
}
.icon-roundedformat_clear:before {
  content: "\eae7";
}
.icon-roundedformat_color_reset:before {
  content: "\eae8";
}
.icon-roundedformat_indent_decrease:before {
  content: "\eae9";
}
.icon-roundedformat_indent_increase:before {
  content: "\eaea";
}
.icon-roundedformat_italic:before {
  content: "\eaeb";
}
.icon-roundedformat_line_spacing:before {
  content: "\eaec";
}
.icon-roundedformat_list_bulleted:before {
  content: "\eaed";
}
.icon-roundedformat_list_numbered:before {
  content: "\eaee";
}
.icon-roundedformat_list_numbered_rtl:before {
  content: "\eaef";
}
.icon-roundedformat_paint:before {
  content: "\eaf0";
}
.icon-roundedformat_quote:before {
  content: "\eaf1";
}
.icon-roundedformat_shapes:before {
  content: "\eaf2";
}
.icon-roundedformat_size:before {
  content: "\eaf3";
}
.icon-roundedformat_strikethrough:before {
  content: "\eaf4";
}
.icon-roundedformat_textdirection_l_to_r:before {
  content: "\eaf5";
}
.icon-roundedformat_textdirection_r_to_l:before {
  content: "\eaf6";
}
.icon-roundedformat_underlined:before {
  content: "\eaf7";
}
.icon-roundedforum:before {
  content: "\eaf8";
}
.icon-roundedforward:before {
  content: "\eaf9";
}
.icon-roundedforward_5:before {
  content: "\eafa";
}
.icon-roundedforward_10:before {
  content: "\eafb";
}
.icon-roundedforward_30:before {
  content: "\eafc";
}
.icon-roundedforward_to_inbox:before {
  content: "\eafd";
}
.icon-roundedfoundation:before {
  content: "\eafe";
}
.icon-roundedfree_breakfast:before {
  content: "\eaff";
}
.icon-roundedfullscreen:before {
  content: "\eb00";
}
.icon-roundedfullscreen_exit:before {
  content: "\eb01";
}
.icon-roundedfunctions:before {
  content: "\eb02";
}
.icon-roundedg_translate:before {
  content: "\eb03";
}
.icon-roundedgamepad:before {
  content: "\eb04";
}
.icon-roundedgames:before {
  content: "\eb05";
}
.icon-roundedgavel:before {
  content: "\eb06";
}
.icon-roundedgesture:before {
  content: "\eb07";
}
.icon-roundedget_app:before {
  content: "\eb08";
}
.icon-roundedgif:before {
  content: "\eb09";
}
.icon-roundedgolf_course:before {
  content: "\eb0a";
}
.icon-roundedgps_fixed:before {
  content: "\eb0b";
}
.icon-roundedgps_not_fixed:before {
  content: "\eb0c";
}
.icon-roundedgps_off:before {
  content: "\eb0d";
}
.icon-roundedgrade:before {
  content: "\eb0e";
}
.icon-roundedgradient:before {
  content: "\eb0f";
}
.icon-roundedgrading:before {
  content: "\eb10";
}
.icon-roundedgrain:before {
  content: "\eb11";
}
.icon-roundedgraphic_eq:before {
  content: "\eb12";
}
.icon-roundedgrass:before {
  content: "\eb13";
}
.icon-roundedgrid_off:before {
  content: "\eb14";
}
.icon-roundedgrid_on:before {
  content: "\eb15";
}
.icon-roundedgroup:before {
  content: "\eb16";
}
.icon-roundedgroup_add:before {
  content: "\eb17";
}
.icon-roundedgroup_work:before {
  content: "\eb18";
}
.icon-roundedgroups:before {
  content: "\eb19";
}
.icon-roundedhandyman:before {
  content: "\eb1a";
}
.icon-roundedhd:before {
  content: "\eb1b";
}
.icon-roundedhdr_off:before {
  content: "\eb1c";
}
.icon-roundedhdr_on:before {
  content: "\eb1d";
}
.icon-roundedhdr_strong:before {
  content: "\eb1e";
}
.icon-roundedhdr_weak:before {
  content: "\eb1f";
}
.icon-roundedheadset:before {
  content: "\eb20";
}
.icon-roundedheadset_mic:before {
  content: "\eb21";
}
.icon-roundedhealing:before {
  content: "\eb22";
}
.icon-roundedhearing:before {
  content: "\eb23";
}
.icon-roundedhearing_disabled:before {
  content: "\eb24";
}
.icon-roundedheight:before {
  content: "\eb25";
}
.icon-roundedhelp:before {
  content: "\eb26";
}
.icon-roundedhelp_center:before {
  content: "\eb27";
}
.icon-roundedhelp_outline:before {
  content: "\eb28";
}
.icon-roundedhigh_quality:before {
  content: "\eb29";
}
.icon-roundedhighlight:before {
  content: "\eb2a";
}
.icon-roundedhighlight_alt:before {
  content: "\eb2b";
}
.icon-roundedhighlight_off:before {
  content: "\eb2c";
}
.icon-roundedhistory:before {
  content: "\eb2d";
}
.icon-roundedhistory_edu:before {
  content: "\eb2e";
}
.icon-roundedhistory_toggle_off:before {
  content: "\eb2f";
}
.icon-roundedhome:before {
  content: "\eb30";
}
.icon-roundedhome_repair_service:before {
  content: "\eb31";
}
.icon-roundedhome_work:before {
  content: "\eb32";
}
.icon-roundedhorizontal_rule:before {
  content: "\eb33";
}
.icon-roundedhorizontal_split:before {
  content: "\eb34";
}
.icon-roundedhot_tub:before {
  content: "\eb35";
}
.icon-roundedhotel:before {
  content: "\eb36";
}
.icon-roundedhourglass_bottom:before {
  content: "\eb37";
}
.icon-roundedhourglass_disabled:before {
  content: "\eb38";
}
.icon-roundedhourglass_empty:before {
  content: "\eb39";
}
.icon-roundedhourglass_full:before {
  content: "\eb3a";
}
.icon-roundedhourglass_top:before {
  content: "\eb3b";
}
.icon-roundedhouse:before {
  content: "\eb3c";
}
.icon-roundedhouse_siding:before {
  content: "\eb3d";
}
.icon-roundedhow_to_reg:before {
  content: "\eb3e";
}
.icon-roundedhow_to_vote:before {
  content: "\eb3f";
}
.icon-roundedhttp:before {
  content: "\eb40";
}
.icon-roundedhttps:before {
  content: "\eb41";
}
.icon-roundedhvac:before {
  content: "\eb42";
}
.icon-roundedimage:before {
  content: "\eb43";
}
.icon-roundedimage_aspect_ratio:before {
  content: "\eb44";
}
.icon-roundedimage_not_supported:before {
  content: "\eb45";
}
.icon-roundedimage_search:before {
  content: "\eb46";
}
.icon-roundedimport_contacts:before {
  content: "\eb47";
}
.icon-roundedimport_export:before {
  content: "\eb48";
}
.icon-roundedimportant_devices:before {
  content: "\eb49";
}
.icon-roundedinbox:before {
  content: "\eb4a";
}
.icon-roundedindeterminate_check_box:before {
  content: "\eb4b";
}
.icon-roundedinfo:before {
  content: "\eb4c";
}
.icon-roundedinfo_outline:before {
  content: "\eb4d";
}
.icon-roundedinput:before {
  content: "\eb4e";
}
.icon-roundedinsert_chart:before {
  content: "\eb4f";
}
.icon-roundedinsert_chart_outlined:before {
  content: "\eb50";
}
.icon-roundedinsert_comment:before {
  content: "\eb51";
}
.icon-roundedinsert_drive_file:before {
  content: "\eb52";
}
.icon-roundedinsert_emoticon:before {
  content: "\eb53";
}
.icon-roundedinsert_invitation:before {
  content: "\eb54";
}
.icon-roundedinsert_link:before {
  content: "\eb55";
}
.icon-roundedinsert_photo:before {
  content: "\eb56";
}
.icon-roundedinsights:before {
  content: "\eb57";
}
.icon-roundedintegration_instructions:before {
  content: "\eb58";
}
.icon-roundedinvert_colors:before {
  content: "\eb59";
}
.icon-roundedinvert_colors_off:before {
  content: "\eb5a";
}
.icon-roundediso:before {
  content: "\eb5b";
}
.icon-roundedkeyboard:before {
  content: "\eb5c";
}
.icon-roundedkeyboard_arrow_down:before {
  content: "\eb5d";
}
.icon-roundedkeyboard_arrow_left:before {
  content: "\eb5e";
}
.icon-roundedkeyboard_arrow_right:before {
  content: "\eb5f";
}
.icon-roundedkeyboard_arrow_up:before {
  content: "\eb60";
}
.icon-roundedkeyboard_backspace:before {
  content: "\eb61";
}
.icon-roundedkeyboard_capslock:before {
  content: "\eb62";
}
.icon-roundedkeyboard_hide:before {
  content: "\eb63";
}
.icon-roundedkeyboard_return:before {
  content: "\eb64";
}
.icon-roundedkeyboard_tab:before {
  content: "\eb65";
}
.icon-roundedkeyboard_voice:before {
  content: "\eb66";
}
.icon-roundedking_bed:before {
  content: "\eb67";
}
.icon-roundedkitchen:before {
  content: "\eb68";
}
.icon-roundedlabel:before {
  content: "\eb69";
}
.icon-roundedlabel_important:before {
  content: "\eb6a";
}
.icon-roundedlabel_important_outline:before {
  content: "\eb6b";
}
.icon-roundedlabel_off:before {
  content: "\eb6c";
}
.icon-roundedlabel_outline:before {
  content: "\eb6d";
}
.icon-roundedlandscape:before {
  content: "\eb6e";
}
.icon-roundedlanguage:before {
  content: "\eb6f";
}
.icon-roundedlaptop:before {
  content: "\eb70";
}
.icon-roundedlaptop_chromebook:before {
  content: "\eb71";
}
.icon-roundedlaptop_mac:before {
  content: "\eb72";
}
.icon-roundedlaptop_windows:before {
  content: "\eb73";
}
.icon-roundedlast_page:before {
  content: "\eb74";
}
.icon-roundedlaunch:before {
  content: "\eb75";
}
.icon-roundedlayers:before {
  content: "\eb76";
}
.icon-roundedlayers_clear:before {
  content: "\eb77";
}
.icon-roundedleaderboard:before {
  content: "\eb78";
}
.icon-roundedleak_add:before {
  content: "\eb79";
}
.icon-roundedleak_remove:before {
  content: "\eb7a";
}
.icon-roundedlegend_toggle:before {
  content: "\eb7b";
}
.icon-roundedlens:before {
  content: "\eb7c";
}
.icon-roundedlibrary_add:before {
  content: "\eb7d";
}
.icon-roundedlibrary_add_check:before {
  content: "\eb7e";
}
.icon-roundedlibrary_books:before {
  content: "\eb7f";
}
.icon-roundedlibrary_music:before {
  content: "\eb80";
}
.icon-roundedlightbulb_outline:before {
  content: "\eb81";
}
.icon-roundedline_style:before {
  content: "\eb82";
}
.icon-roundedline_weight:before {
  content: "\eb83";
}
.icon-roundedlinear_scale:before {
  content: "\eb84";
}
.icon-roundedlink:before {
  content: "\eb85";
}
.icon-roundedlink_off:before {
  content: "\eb86";
}
.icon-roundedlinked_camera:before {
  content: "\eb87";
}
.icon-roundedlist:before {
  content: "\eb88";
}
.icon-roundedlist_alt:before {
  content: "\eb89";
}
.icon-roundedlive_help:before {
  content: "\eb8a";
}
.icon-roundedlive_tv:before {
  content: "\eb8b";
}
.icon-roundedlocal_activity:before {
  content: "\eb8c";
}
.icon-roundedlocal_airport:before {
  content: "\eb8d";
}
.icon-roundedlocal_atm:before {
  content: "\eb8e";
}
.icon-roundedlocal_bar:before {
  content: "\eb8f";
}
.icon-roundedlocal_cafe:before {
  content: "\eb90";
}
.icon-roundedlocal_car_wash:before {
  content: "\eb91";
}
.icon-roundedlocal_convenience_store:before {
  content: "\eb92";
}
.icon-roundedlocal_dining:before {
  content: "\eb93";
}
.icon-roundedlocal_drink:before {
  content: "\eb94";
}
.icon-roundedlocal_fire_department:before {
  content: "\eb95";
}
.icon-roundedlocal_florist:before {
  content: "\eb96";
}
.icon-roundedlocal_gas_station:before {
  content: "\eb97";
}
.icon-roundedlocal_grocery_store:before {
  content: "\eb98";
}
.icon-roundedlocal_hospital:before {
  content: "\eb99";
}
.icon-roundedlocal_hotel:before {
  content: "\eb9a";
}
.icon-roundedlocal_laundry_service:before {
  content: "\eb9b";
}
.icon-roundedlocal_library:before {
  content: "\eb9c";
}
.icon-roundedlocal_mall:before {
  content: "\eb9d";
}
.icon-roundedlocal_movies:before {
  content: "\eb9e";
}
.icon-roundedlocal_offer:before {
  content: "\eb9f";
}
.icon-roundedlocal_parking:before {
  content: "\eba0";
}
.icon-roundedlocal_pharmacy:before {
  content: "\eba1";
}
.icon-roundedlocal_phone:before {
  content: "\eba2";
}
.icon-roundedlocal_pizza:before {
  content: "\eba3";
}
.icon-roundedlocal_play:before {
  content: "\eba4";
}
.icon-roundedlocal_police:before {
  content: "\eba5";
}
.icon-roundedlocal_post_office:before {
  content: "\eba6";
}
.icon-roundedlocal_printshop:before {
  content: "\eba7";
}
.icon-roundedlocal_see:before {
  content: "\eba8";
}
.icon-roundedlocal_shipping:before {
  content: "\eba9";
}
.icon-roundedlocal_taxi:before {
  content: "\ebaa";
}
.icon-roundedlocation_city:before {
  content: "\ebab";
}
.icon-roundedlocation_disabled:before {
  content: "\ebac";
}
.icon-roundedlocation_off:before {
  content: "\ebad";
}
.icon-roundedlocation_on:before {
  content: "\ebae";
}
.icon-roundedlocation_searching:before {
  content: "\ebaf";
}
.icon-roundedlock:before {
  content: "\ebb0";
}
.icon-roundedlock_open:before {
  content: "\ebb1";
}
.icon-roundedlock_outline:before {
  content: "\ebb2";
}
.icon-roundedlogin:before {
  content: "\ebb3";
}
.icon-roundedlooks:before {
  content: "\ebb4";
}
.icon-roundedlooks_3:before {
  content: "\ebb5";
}
.icon-roundedlooks_4:before {
  content: "\ebb6";
}
.icon-roundedlooks_5:before {
  content: "\ebb7";
}
.icon-roundedlooks_6:before {
  content: "\ebb8";
}
.icon-roundedlooks_one:before {
  content: "\ebb9";
}
.icon-roundedlooks_two:before {
  content: "\ebba";
}
.icon-roundedloop:before {
  content: "\ebbb";
}
.icon-roundedloupe:before {
  content: "\ebbc";
}
.icon-roundedlow_priority:before {
  content: "\ebbd";
}
.icon-roundedloyalty:before {
  content: "\ebbe";
}
.icon-roundedluggage:before {
  content: "\ebbf";
}
.icon-roundedmail:before {
  content: "\ebc0";
}
.icon-roundedmail_outline:before {
  content: "\ebc1";
}
.icon-roundedmap:before {
  content: "\ebc2";
}
.icon-roundedmaps_ugc:before {
  content: "\ebc3";
}
.icon-roundedmark_chat_read:before {
  content: "\ebc4";
}
.icon-roundedmark_chat_unread:before {
  content: "\ebc5";
}
.icon-roundedmark_email_read:before {
  content: "\ebc6";
}
.icon-roundedmark_email_unread:before {
  content: "\ebc7";
}
.icon-roundedmarkunread:before {
  content: "\ebc8";
}
.icon-roundedmarkunread_mailbox:before {
  content: "\ebc9";
}
.icon-roundedmasks:before {
  content: "\ebca";
}
.icon-roundedmaximize:before {
  content: "\ebcb";
}
.icon-roundedmediation:before {
  content: "\ebcc";
}
.icon-roundedmedical_services:before {
  content: "\ebcd";
}
.icon-roundedmeeting_room:before {
  content: "\ebce";
}
.icon-roundedmemory:before {
  content: "\ebcf";
}
.icon-roundedmenu:before {
  content: "\ebd0";
}
.icon-roundedmenu_book:before {
  content: "\ebd1";
}
.icon-roundedmenu_open:before {
  content: "\ebd2";
}
.icon-roundedmerge_type:before {
  content: "\ebd3";
}
.icon-roundedmessage:before {
  content: "\ebd4";
}
.icon-roundedmic:before {
  content: "\ebd5";
}
.icon-roundedmic_none:before {
  content: "\ebd6";
}
.icon-roundedmic_off:before {
  content: "\ebd7";
}
.icon-roundedmicrowave:before {
  content: "\ebd8";
}
.icon-roundedmilitary_tech:before {
  content: "\ebd9";
}
.icon-roundedminimize:before {
  content: "\ebda";
}
.icon-roundedmiscellaneous_services:before {
  content: "\ebdb";
}
.icon-roundedmissed_video_call:before {
  content: "\ebdc";
}
.icon-roundedmms:before {
  content: "\ebdd";
}
.icon-roundedmobile_friendly:before {
  content: "\ebde";
}
.icon-roundedmobile_off:before {
  content: "\ebdf";
}
.icon-roundedmobile_screen_share:before {
  content: "\ebe0";
}
.icon-roundedmode:before {
  content: "\ebe1";
}
.icon-roundedmode_comment:before {
  content: "\ebe2";
}
.icon-roundedmodel_training:before {
  content: "\ebe3";
}
.icon-roundedmonetization_on:before {
  content: "\ebe4";
}
.icon-roundedmoney:before {
  content: "\ebe5";
}
.icon-roundedmoney_off:before {
  content: "\ebe6";
}
.icon-roundedmoney_off_csred:before {
  content: "\ebe7";
}
.icon-roundedmonochrome_photos:before {
  content: "\ebe8";
}
.icon-roundedmood:before {
  content: "\ebe9";
}
.icon-roundedmood_bad:before {
  content: "\ebea";
}
.icon-roundedmoped:before {
  content: "\ebeb";
}
.icon-roundedmore:before {
  content: "\ebec";
}
.icon-roundedmore_horiz:before {
  content: "\ebed";
}
.icon-roundedmore_time:before {
  content: "\ebee";
}
.icon-roundedmore_vert:before {
  content: "\ebef";
}
.icon-roundedmotion_photos_on:before {
  content: "\ebf0";
}
.icon-roundedmotion_photos_pause:before {
  content: "\ebf1";
}
.icon-roundedmotion_photos_paused:before {
  content: "\ebf2";
}
.icon-roundedmouse:before {
  content: "\ebf3";
}
.icon-roundedmove_to_inbox:before {
  content: "\ebf4";
}
.icon-roundedmovie:before {
  content: "\ebf5";
}
.icon-roundedmovie_creation:before {
  content: "\ebf6";
}
.icon-roundedmovie_filter:before {
  content: "\ebf7";
}
.icon-roundedmultiline_chart:before {
  content: "\ebf8";
}
.icon-roundedmultiple_stop:before {
  content: "\ebf9";
}
.icon-roundedmuseum:before {
  content: "\ebfa";
}
.icon-roundedmusic_note:before {
  content: "\ebfb";
}
.icon-roundedmusic_off:before {
  content: "\ebfc";
}
.icon-roundedmusic_video:before {
  content: "\ebfd";
}
.icon-roundedmy_location:before {
  content: "\ebfe";
}
.icon-roundednat:before {
  content: "\ebff";
}
.icon-roundednature:before {
  content: "\ec00";
}
.icon-roundednature_people:before {
  content: "\ec01";
}
.icon-roundednavigate_before:before {
  content: "\ec02";
}
.icon-roundednavigate_next:before {
  content: "\ec03";
}
.icon-roundednavigation:before {
  content: "\ec04";
}
.icon-roundednear_me:before {
  content: "\ec05";
}
.icon-roundednear_me_disabled:before {
  content: "\ec06";
}
.icon-roundednetwork_check:before {
  content: "\ec07";
}
.icon-roundednetwork_locked:before {
  content: "\ec08";
}
.icon-roundednew_releases:before {
  content: "\ec09";
}
.icon-roundednext_plan:before {
  content: "\ec0a";
}
.icon-roundednext_week:before {
  content: "\ec0b";
}
.icon-roundednfc:before {
  content: "\ec0c";
}
.icon-roundednight_shelter:before {
  content: "\ec0d";
}
.icon-roundednights_stay:before {
  content: "\ec0e";
}
.icon-roundedno_backpack:before {
  content: "\ec0f";
}
.icon-roundedno_cell:before {
  content: "\ec10";
}
.icon-roundedno_drinks:before {
  content: "\ec11";
}
.icon-roundedno_encryption:before {
  content: "\ec12";
}
.icon-roundedno_encryption_gmailerrorred:before {
  content: "\ec13";
}
.icon-roundedno_flash:before {
  content: "\ec14";
}
.icon-roundedno_food:before {
  content: "\ec15";
}
.icon-roundedno_luggage:before {
  content: "\ec16";
}
.icon-roundedno_meals:before {
  content: "\ec17";
}
.icon-roundedno_meeting_room:before {
  content: "\ec18";
}
.icon-roundedno_photography:before {
  content: "\ec19";
}
.icon-roundedno_sim:before {
  content: "\ec1a";
}
.icon-roundedno_stroller:before {
  content: "\ec1b";
}
.icon-roundedno_transfer:before {
  content: "\ec1c";
}
.icon-roundednorth:before {
  content: "\ec1d";
}
.icon-roundednorth_east:before {
  content: "\ec1e";
}
.icon-roundednorth_west:before {
  content: "\ec1f";
}
.icon-roundednot_accessible:before {
  content: "\ec20";
}
.icon-roundednot_interested:before {
  content: "\ec21";
}
.icon-roundednot_listed_location:before {
  content: "\ec22";
}
.icon-roundednot_started:before {
  content: "\ec23";
}
.icon-roundednote:before {
  content: "\ec24";
}
.icon-roundednote_add:before {
  content: "\ec25";
}
.icon-roundednotes:before {
  content: "\ec26";
}
.icon-roundednotification_important:before {
  content: "\ec27";
}
.icon-roundednotifications:before {
  content: "\ec28";
}
.icon-roundednotifications_active:before {
  content: "\ec29";
}
.icon-roundednotifications_none:before {
  content: "\ec2a";
}
.icon-roundednotifications_off:before {
  content: "\ec2b";
}
.icon-roundednotifications_paused:before {
  content: "\ec2c";
}
.icon-roundedoffline_bolt:before {
  content: "\ec2d";
}
.icon-roundedoffline_pin:before {
  content: "\ec2e";
}
.icon-roundedondemand_video:before {
  content: "\ec2f";
}
.icon-roundedonline_prediction:before {
  content: "\ec30";
}
.icon-roundedopacity:before {
  content: "\ec31";
}
.icon-roundedopen_in_browser:before {
  content: "\ec32";
}
.icon-roundedopen_in_full:before {
  content: "\ec33";
}
.icon-roundedopen_in_new:before {
  content: "\ec34";
}
.icon-roundedopen_with:before {
  content: "\ec35";
}
.icon-roundedoutbond:before {
  content: "\ec36";
}
.icon-roundedoutdoor_grill:before {
  content: "\ec37";
}
.icon-roundedoutlet:before {
  content: "\ec38";
}
.icon-roundedoutlined_flag:before {
  content: "\ec39";
}
.icon-roundedpages:before {
  content: "\ec3a";
}
.icon-roundedpageview:before {
  content: "\ec3b";
}
.icon-roundedpalette:before {
  content: "\ec3c";
}
.icon-roundedpan_tool:before {
  content: "\ec3d";
}
.icon-roundedpanorama:before {
  content: "\ec3e";
}
.icon-roundedpanorama_fish_eye:before {
  content: "\ec3f";
}
.icon-roundedpanorama_horizontal:before {
  content: "\ec40";
}
.icon-roundedpanorama_vertical:before {
  content: "\ec41";
}
.icon-roundedpanorama_wide_angle:before {
  content: "\ec42";
}
.icon-roundedparty_mode:before {
  content: "\ec43";
}
.icon-roundedpause:before {
  content: "\ec44";
}
.icon-roundedpause_circle_filled:before {
  content: "\ec45";
}
.icon-roundedpause_circle_outline:before {
  content: "\ec46";
}
.icon-roundedpause_presentation:before {
  content: "\ec47";
}
.icon-roundedpayment:before {
  content: "\ec48";
}
.icon-roundedpayments:before {
  content: "\ec49";
}
.icon-roundedpedal_bike:before {
  content: "\ec4a";
}
.icon-roundedpending:before {
  content: "\ec4b";
}
.icon-roundedpending_actions:before {
  content: "\ec4c";
}
.icon-roundedpeople:before {
  content: "\ec4d";
}
.icon-roundedpeople_alt:before {
  content: "\ec4e";
}
.icon-roundedpeople_outline:before {
  content: "\ec4f";
}
.icon-roundedperm_camera_mic:before {
  content: "\ec50";
}
.icon-roundedperm_contact_calendar:before {
  content: "\ec51";
}
.icon-roundedperm_data_setting:before {
  content: "\ec52";
}
.icon-roundedperm_device_information:before {
  content: "\ec53";
}
.icon-roundedperm_identity:before {
  content: "\ec54";
}
.icon-roundedperm_media:before {
  content: "\ec55";
}
.icon-roundedperm_phone_msg:before {
  content: "\ec56";
}
.icon-roundedperm_scan_wifi:before {
  content: "\ec57";
}
.icon-roundedperson:before {
  content: "\ec58";
}
.icon-roundedperson_add:before {
  content: "\ec59";
}
.icon-roundedperson_add_alt_1:before {
  content: "\ec5a";
}
.icon-roundedperson_add_disabled:before {
  content: "\ec5b";
}
.icon-roundedperson_outline:before {
  content: "\ec5c";
}
.icon-roundedperson_pin:before {
  content: "\ec5d";
}
.icon-roundedperson_pin_circle:before {
  content: "\ec5e";
}
.icon-roundedperson_remove:before {
  content: "\ec5f";
}
.icon-roundedperson_remove_alt_1:before {
  content: "\ec60";
}
.icon-roundedperson_search:before {
  content: "\ec61";
}
.icon-roundedpersonal_video:before {
  content: "\ec62";
}
.icon-roundedpest_control:before {
  content: "\ec63";
}
.icon-roundedpest_control_rodent:before {
  content: "\ec64";
}
.icon-roundedpets:before {
  content: "\ec65";
}
.icon-roundedphone:before {
  content: "\ec66";
}
.icon-roundedphone_android:before {
  content: "\ec67";
}
.icon-roundedphone_bluetooth_speaker:before {
  content: "\ec68";
}
.icon-roundedphone_callback:before {
  content: "\ec69";
}
.icon-roundedphone_disabled:before {
  content: "\ec6a";
}
.icon-roundedphone_enabled:before {
  content: "\ec6b";
}
.icon-roundedphone_forwarded:before {
  content: "\ec6c";
}
.icon-roundedphone_in_talk:before {
  content: "\ec6d";
}
.icon-roundedphone_iphone:before {
  content: "\ec6e";
}
.icon-roundedphone_locked:before {
  content: "\ec6f";
}
.icon-roundedphone_missed:before {
  content: "\ec70";
}
.icon-roundedphone_paused:before {
  content: "\ec71";
}
.icon-roundedphonelink:before {
  content: "\ec72";
}
.icon-roundedphonelink_erase:before {
  content: "\ec73";
}
.icon-roundedphonelink_lock:before {
  content: "\ec74";
}
.icon-roundedphonelink_off:before {
  content: "\ec75";
}
.icon-roundedphonelink_ring:before {
  content: "\ec76";
}
.icon-roundedphonelink_setup:before {
  content: "\ec77";
}
.icon-roundedphoto:before {
  content: "\ec78";
}
.icon-roundedphoto_album:before {
  content: "\ec79";
}
.icon-roundedphoto_camera:before {
  content: "\ec7a";
}
.icon-roundedphoto_filter:before {
  content: "\ec7b";
}
.icon-roundedphoto_library:before {
  content: "\ec7c";
}
.icon-roundedphoto_size_select_actual:before {
  content: "\ec7d";
}
.icon-roundedphoto_size_select_large:before {
  content: "\ec7e";
}
.icon-roundedphoto_size_select_small:before {
  content: "\ec7f";
}
.icon-roundedpicture_as_pdf:before {
  content: "\ec80";
}
.icon-roundedpicture_in_picture:before {
  content: "\ec81";
}
.icon-roundedpicture_in_picture_alt:before {
  content: "\ec82";
}
.icon-roundedpie_chart:before {
  content: "\ec83";
}
.icon-roundedpie_chart_outline:before {
  content: "\ec84";
}
.icon-roundedpin_drop:before {
  content: "\ec85";
}
.icon-roundedplace:before {
  content: "\ec86";
}
.icon-roundedplagiarism:before {
  content: "\ec87";
}
.icon-roundedplay_arrow:before {
  content: "\ec88";
}
.icon-roundedplay_circle_filled:before {
  content: "\ec89";
}
.icon-roundedplay_circle_outline:before {
  content: "\ec8a";
}
.icon-roundedplay_for_work:before {
  content: "\ec8b";
}
.icon-roundedplaylist_add:before {
  content: "\ec8c";
}
.icon-roundedplaylist_add_check:before {
  content: "\ec8d";
}
.icon-roundedplaylist_play:before {
  content: "\ec8e";
}
.icon-roundedplumbing:before {
  content: "\ec8f";
}
.icon-roundedplus_one:before {
  content: "\ec90";
}
.icon-roundedpoint_of_sale:before {
  content: "\ec91";
}
.icon-roundedpolicy:before {
  content: "\ec92";
}
.icon-roundedpoll:before {
  content: "\ec93";
}
.icon-roundedpolymer:before {
  content: "\ec94";
}
.icon-roundedpool:before {
  content: "\ec95";
}
.icon-roundedportable_wifi_off:before {
  content: "\ec96";
}
.icon-roundedportrait:before {
  content: "\ec97";
}
.icon-roundedpost_add:before {
  content: "\ec98";
}
.icon-roundedpower:before {
  content: "\ec99";
}
.icon-roundedpower_input:before {
  content: "\ec9a";
}
.icon-roundedpower_off:before {
  content: "\ec9b";
}
.icon-roundedpower_settings_new:before {
  content: "\ec9c";
}
.icon-roundedprecision_manufacturing:before {
  content: "\ec9d";
}
.icon-roundedpregnant_woman:before {
  content: "\ec9e";
}
.icon-roundedpresent_to_all:before {
  content: "\ec9f";
}
.icon-roundedpreview:before {
  content: "\eca0";
}
.icon-roundedprint:before {
  content: "\eca1";
}
.icon-roundedprint_disabled:before {
  content: "\eca2";
}
.icon-roundedpriority_high:before {
  content: "\eca3";
}
.icon-roundedprivacy_tip:before {
  content: "\eca4";
}
.icon-roundedpsychology:before {
  content: "\eca5";
}
.icon-roundedpublic:before {
  content: "\eca6";
}
.icon-roundedpublic_off:before {
  content: "\eca7";
}
.icon-roundedpublish:before {
  content: "\eca8";
}
.icon-roundedpublished_with_changes:before {
  content: "\eca9";
}
.icon-roundedpush_pin:before {
  content: "\ecaa";
}
.icon-roundedqr_code:before {
  content: "\ecab";
}
.icon-roundedqr_code_scanner:before {
  content: "\ecac";
}
.icon-roundedquery_builder:before {
  content: "\ecad";
}
.icon-roundedquestion_answer:before {
  content: "\ecae";
}
.icon-roundedqueue:before {
  content: "\ecaf";
}
.icon-roundedqueue_music:before {
  content: "\ecb0";
}
.icon-roundedqueue_play_next:before {
  content: "\ecb1";
}
.icon-roundedquickreply:before {
  content: "\ecb2";
}
.icon-roundedradio:before {
  content: "\ecb3";
}
.icon-roundedradio_button_checked:before {
  content: "\ecb4";
}
.icon-roundedradio_button_unchecked:before {
  content: "\ecb5";
}
.icon-roundedrate_review:before {
  content: "\ecb6";
}
.icon-roundedread_more:before {
  content: "\ecb7";
}
.icon-roundedreceipt:before {
  content: "\ecb8";
}
.icon-roundedreceipt_long:before {
  content: "\ecb9";
}
.icon-roundedrecent_actors:before {
  content: "\ecba";
}
.icon-roundedrecord_voice_over:before {
  content: "\ecbb";
}
.icon-roundedredeem:before {
  content: "\ecbc";
}
.icon-roundedredo:before {
  content: "\ecbd";
}
.icon-roundedreduce_capacity:before {
  content: "\ecbe";
}
.icon-roundedrefresh:before {
  content: "\ecbf";
}
.icon-roundedremove:before {
  content: "\ecc0";
}
.icon-roundedremove_circle:before {
  content: "\ecc1";
}
.icon-roundedremove_circle_outline:before {
  content: "\ecc2";
}
.icon-roundedremove_from_queue:before {
  content: "\ecc3";
}
.icon-roundedremove_red_eye:before {
  content: "\ecc4";
}
.icon-roundedremove_shopping_cart:before {
  content: "\ecc5";
}
.icon-roundedreorder:before {
  content: "\ecc6";
}
.icon-roundedrepeat:before {
  content: "\ecc7";
}
.icon-roundedrepeat_one:before {
  content: "\ecc8";
}
.icon-roundedreplay:before {
  content: "\ecc9";
}
.icon-roundedreplay_5:before {
  content: "\ecca";
}
.icon-roundedreplay_10:before {
  content: "\eccb";
}
.icon-roundedreplay_30:before {
  content: "\eccc";
}
.icon-roundedreply:before {
  content: "\eccd";
}
.icon-roundedreply_all:before {
  content: "\ecce";
}
.icon-roundedreport:before {
  content: "\eccf";
}
.icon-roundedreport_gmailerrorred:before {
  content: "\ecd0";
}
.icon-roundedreport_off:before {
  content: "\ecd1";
}
.icon-roundedreport_problem:before {
  content: "\ecd2";
}
.icon-roundedrequest_page:before {
  content: "\ecd3";
}
.icon-roundedrequest_quote:before {
  content: "\ecd4";
}
.icon-roundedrestaurant:before {
  content: "\ecd5";
}
.icon-roundedrestaurant_menu:before {
  content: "\ecd6";
}
.icon-roundedrestore:before {
  content: "\ecd7";
}
.icon-roundedrestore_from_trash:before {
  content: "\ecd8";
}
.icon-roundedrestore_page:before {
  content: "\ecd9";
}
.icon-roundedrice_bowl:before {
  content: "\ecda";
}
.icon-roundedring_volume:before {
  content: "\ecdb";
}
.icon-roundedroofing:before {
  content: "\ecdc";
}
.icon-roundedroom:before {
  content: "\ecdd";
}
.icon-roundedroom_preferences:before {
  content: "\ecde";
}
.icon-roundedroom_service:before {
  content: "\ecdf";
}
.icon-roundedrotate_90_degrees_ccw:before {
  content: "\ece0";
}
.icon-roundedrotate_left:before {
  content: "\ece1";
}
.icon-roundedrotate_right:before {
  content: "\ece2";
}
.icon-roundedrounded_corner:before {
  content: "\ece3";
}
.icon-roundedrouter:before {
  content: "\ece4";
}
.icon-roundedrowing:before {
  content: "\ece5";
}
.icon-roundedrss_feed:before {
  content: "\ece6";
}
.icon-roundedrule:before {
  content: "\ece7";
}
.icon-roundedrule_folder:before {
  content: "\ece8";
}
.icon-roundedrun_circle:before {
  content: "\ece9";
}
.icon-roundedrv_hookup:before {
  content: "\ecea";
}
.icon-roundedsanitizer:before {
  content: "\eceb";
}
.icon-roundedsatellite:before {
  content: "\ecec";
}
.icon-roundedsave:before {
  content: "\eced";
}
.icon-roundedsave_alt:before {
  content: "\ecee";
}
.icon-roundedscanner:before {
  content: "\ecef";
}
.icon-roundedscatter_plot:before {
  content: "\ecf0";
}
.icon-roundedschedule:before {
  content: "\ecf1";
}
.icon-roundedschool:before {
  content: "\ecf2";
}
.icon-roundedscience:before {
  content: "\ecf3";
}
.icon-roundedscore:before {
  content: "\ecf4";
}
.icon-roundedscreen_lock_landscape:before {
  content: "\ecf5";
}
.icon-roundedscreen_lock_portrait:before {
  content: "\ecf6";
}
.icon-roundedscreen_lock_rotation:before {
  content: "\ecf7";
}
.icon-roundedscreen_rotation:before {
  content: "\ecf8";
}
.icon-roundedscreen_share:before {
  content: "\ecf9";
}
.icon-roundedsd_card:before {
  content: "\ecfa";
}
.icon-roundedsd_card_alert:before {
  content: "\ecfb";
}
.icon-roundedsd_storage:before {
  content: "\ecfc";
}
.icon-roundedsearch:before {
  content: "\ecfd";
}
.icon-roundedsearch_off:before {
  content: "\ecfe";
}
.icon-roundedsecurity:before {
  content: "\ecff";
}
.icon-roundedselect_all:before {
  content: "\ed00";
}
.icon-roundedself_improvement:before {
  content: "\ed01";
}
.icon-roundedsend:before {
  content: "\ed02";
}
.icon-roundedsensor_door:before {
  content: "\ed03";
}
.icon-roundedsensor_window:before {
  content: "\ed04";
}
.icon-roundedsentiment_dissatisfied:before {
  content: "\ed05";
}
.icon-roundedsentiment_neutral:before {
  content: "\ed06";
}
.icon-roundedsentiment_satisfied:before {
  content: "\ed07";
}
.icon-roundedsentiment_satisfied_alt:before {
  content: "\ed08";
}
.icon-roundedsentiment_very_dissatisfied:before {
  content: "\ed09";
}
.icon-roundedsentiment_very_satisfied:before {
  content: "\ed0a";
}
.icon-roundedset_meal:before {
  content: "\ed0b";
}
.icon-roundedsettings:before {
  content: "\ed0c";
}
.icon-roundedsettings_applications:before {
  content: "\ed0d";
}
.icon-roundedsettings_backup_restore:before {
  content: "\ed0e";
}
.icon-roundedsettings_bluetooth:before {
  content: "\ed0f";
}
.icon-roundedsettings_brightness:before {
  content: "\ed10";
}
.icon-roundedsettings_cell:before {
  content: "\ed11";
}
.icon-roundedsettings_ethernet:before {
  content: "\ed12";
}
.icon-roundedsettings_input_antenna:before {
  content: "\ed13";
}
.icon-roundedsettings_input_component:before {
  content: "\ed14";
}
.icon-roundedsettings_input_composite:before {
  content: "\ed15";
}
.icon-roundedsettings_input_hdmi:before {
  content: "\ed16";
}
.icon-roundedsettings_input_svideo:before {
  content: "\ed17";
}
.icon-roundedsettings_overscan:before {
  content: "\ed18";
}
.icon-roundedsettings_phone:before {
  content: "\ed19";
}
.icon-roundedsettings_power:before {
  content: "\ed1a";
}
.icon-roundedsettings_remote:before {
  content: "\ed1b";
}
.icon-roundedsettings_system_daydream:before {
  content: "\ed1c";
}
.icon-roundedsettings_voice:before {
  content: "\ed1d";
}
.icon-roundedshare:before {
  content: "\ed1e";
}
.icon-roundedshop:before {
  content: "\ed1f";
}
.icon-roundedshop_two:before {
  content: "\ed20";
}
.icon-roundedshopping_bag:before {
  content: "\ed21";
}
.icon-roundedshopping_basket:before {
  content: "\ed22";
}
.icon-roundedshopping_cart:before {
  content: "\ed23";
}
.icon-roundedshort_text:before {
  content: "\ed24";
}
.icon-roundedshow_chart:before {
  content: "\ed25";
}
.icon-roundedshuffle:before {
  content: "\ed26";
}
.icon-roundedshutter_speed:before {
  content: "\ed27";
}
.icon-roundedsick:before {
  content: "\ed28";
}
.icon-roundedsignal_cellular_4_bar:before {
  content: "\ed29";
}
.icon-roundedsignal_cellular_alt:before {
  content: "\ed2a";
}
.icon-roundedsignal_cellular_connected_no_internet_4_bar:before {
  content: "\ed2b";
}
.icon-roundedsignal_cellular_no_sim:before {
  content: "\ed2c";
}
.icon-roundedsignal_cellular_null:before {
  content: "\ed2d";
}
.icon-roundedsignal_cellular_off:before {
  content: "\ed2e";
}
.icon-roundedsignal_wifi_4_bar:before {
  content: "\ed2f";
}
.icon-roundedsignal_wifi_4_bar_lock:before {
  content: "\ed30";
}
.icon-roundedsignal_wifi_off:before {
  content: "\ed31";
}
.icon-roundedsim_card:before {
  content: "\ed32";
}
.icon-roundedsingle_bed:before {
  content: "\ed33";
}
.icon-roundedskip_next:before {
  content: "\ed34";
}
.icon-roundedskip_previous:before {
  content: "\ed35";
}
.icon-roundedslideshow:before {
  content: "\ed36";
}
.icon-roundedslow_motion_video:before {
  content: "\ed37";
}
.icon-roundedsmart_button:before {
  content: "\ed38";
}
.icon-roundedsmartphone:before {
  content: "\ed39";
}
.icon-roundedsmoke_free:before {
  content: "\ed3a";
}
.icon-roundedsmoking_rooms:before {
  content: "\ed3b";
}
.icon-roundedsms:before {
  content: "\ed3c";
}
.icon-roundedsms_failed:before {
  content: "\ed3d";
}
.icon-roundedsnippet_folder:before {
  content: "\ed3e";
}
.icon-roundedsnooze:before {
  content: "\ed3f";
}
.icon-roundedsoap:before {
  content: "\ed40";
}
.icon-roundedsort:before {
  content: "\ed41";
}
.icon-roundedsort_by_alpha:before {
  content: "\ed42";
}
.icon-roundedsource:before {
  content: "\ed43";
}
.icon-roundedsouth:before {
  content: "\ed44";
}
.icon-roundedsouth_east:before {
  content: "\ed45";
}
.icon-roundedsouth_west:before {
  content: "\ed46";
}
.icon-roundedspa:before {
  content: "\ed47";
}
.icon-roundedspace_bar:before {
  content: "\ed48";
}
.icon-roundedspeaker:before {
  content: "\ed49";
}
.icon-roundedspeaker_group:before {
  content: "\ed4a";
}
.icon-roundedspeaker_notes:before {
  content: "\ed4b";
}
.icon-roundedspeaker_notes_off:before {
  content: "\ed4c";
}
.icon-roundedspeaker_phone:before {
  content: "\ed4d";
}
.icon-roundedspeed:before {
  content: "\ed4e";
}
.icon-roundedspellcheck:before {
  content: "\ed4f";
}
.icon-roundedsports:before {
  content: "\ed50";
}
.icon-roundedsports_bar:before {
  content: "\ed51";
}
.icon-roundedsports_baseball:before {
  content: "\ed52";
}
.icon-roundedsports_basketball:before {
  content: "\ed53";
}
.icon-roundedsports_cricket:before {
  content: "\ed54";
}
.icon-roundedsports_esports:before {
  content: "\ed55";
}
.icon-roundedsports_football:before {
  content: "\ed56";
}
.icon-roundedsports_golf:before {
  content: "\ed57";
}
.icon-roundedsports_handball:before {
  content: "\ed58";
}
.icon-roundedsports_hockey:before {
  content: "\ed59";
}
.icon-roundedsports_kabaddi:before {
  content: "\ed5a";
}
.icon-roundedsports_mma:before {
  content: "\ed5b";
}
.icon-roundedsports_motorsports:before {
  content: "\ed5c";
}
.icon-roundedsports_rugby:before {
  content: "\ed5d";
}
.icon-roundedsports_soccer:before {
  content: "\ed5e";
}
.icon-roundedsports_tennis:before {
  content: "\ed5f";
}
.icon-roundedsports_volleyball:before {
  content: "\ed60";
}
.icon-roundedsquare_foot:before {
  content: "\ed61";
}
.icon-roundedstacked_line_chart:before {
  content: "\ed62";
}
.icon-roundedstairs:before {
  content: "\ed63";
}
.icon-roundedstar:before {
  content: "\ed64";
}
.icon-roundedstar_border:before {
  content: "\ed65";
}
.icon-roundedstar_half:before {
  content: "\ed66";
}
.icon-roundedstar_outline:before {
  content: "\ed67";
}
.icon-roundedstar_rate:before {
  content: "\ed68";
}
.icon-roundedstars:before {
  content: "\ed69";
}
.icon-roundedstay_current_landscape:before {
  content: "\ed6a";
}
.icon-roundedstay_current_portrait:before {
  content: "\ed6b";
}
.icon-roundedstay_primary_landscape:before {
  content: "\ed6c";
}
.icon-roundedstay_primary_portrait:before {
  content: "\ed6d";
}
.icon-roundedsticky_note_2:before {
  content: "\ed6e";
}
.icon-roundedstop:before {
  content: "\ed6f";
}
.icon-roundedstop_circle:before {
  content: "\ed70";
}
.icon-roundedstop_screen_share:before {
  content: "\ed71";
}
.icon-roundedstorage:before {
  content: "\ed72";
}
.icon-roundedstore:before {
  content: "\ed73";
}
.icon-roundedstore_mall_directory:before {
  content: "\ed74";
}
.icon-roundedstorefront:before {
  content: "\ed75";
}
.icon-roundedstraighten:before {
  content: "\ed76";
}
.icon-roundedstreetview:before {
  content: "\ed77";
}
.icon-roundedstrikethrough_s:before {
  content: "\ed78";
}
.icon-roundedstroller:before {
  content: "\ed79";
}
.icon-roundedstyle:before {
  content: "\ed7a";
}
.icon-roundedsubdirectory_arrow_left:before {
  content: "\ed7b";
}
.icon-roundedsubdirectory_arrow_right:before {
  content: "\ed7c";
}
.icon-roundedsubject:before {
  content: "\ed7d";
}
.icon-roundedsubscript:before {
  content: "\ed7e";
}
.icon-roundedsubscriptions:before {
  content: "\ed7f";
}
.icon-roundedsubtitles:before {
  content: "\ed80";
}
.icon-roundedsubtitles_off:before {
  content: "\ed81";
}
.icon-roundedsubway:before {
  content: "\ed82";
}
.icon-roundedsuperscript:before {
  content: "\ed83";
}
.icon-roundedsupervised_user_circle:before {
  content: "\ed84";
}
.icon-roundedsupervisor_account:before {
  content: "\ed85";
}
.icon-roundedsupport:before {
  content: "\ed86";
}
.icon-roundedsupport_agent:before {
  content: "\ed87";
}
.icon-roundedsurround_sound:before {
  content: "\ed88";
}
.icon-roundedswap_calls:before {
  content: "\ed89";
}
.icon-roundedswap_horiz:before {
  content: "\ed8a";
}
.icon-roundedswap_horizontal_circle:before {
  content: "\ed8b";
}
.icon-roundedswap_vert:before {
  content: "\ed8c";
}
.icon-roundedswap_vertical_circle:before {
  content: "\ed8d";
}
.icon-roundedswitch_camera:before {
  content: "\ed8e";
}
.icon-roundedswitch_left:before {
  content: "\ed8f";
}
.icon-roundedswitch_right:before {
  content: "\ed90";
}
.icon-roundedswitch_video:before {
  content: "\ed91";
}
.icon-roundedsync:before {
  content: "\ed92";
}
.icon-roundedsync_alt:before {
  content: "\ed93";
}
.icon-roundedsync_disabled:before {
  content: "\ed94";
}
.icon-roundedsync_problem:before {
  content: "\ed95";
}
.icon-roundedsystem_update:before {
  content: "\ed96";
}
.icon-roundedsystem_update_alt:before {
  content: "\ed97";
}
.icon-roundedtab:before {
  content: "\ed98";
}
.icon-roundedtab_unselected:before {
  content: "\ed99";
}
.icon-roundedtable_chart:before {
  content: "\ed9a";
}
.icon-roundedtable_rows:before {
  content: "\ed9b";
}
.icon-roundedtable_view:before {
  content: "\ed9c";
}
.icon-roundedtablet:before {
  content: "\ed9d";
}
.icon-roundedtablet_android:before {
  content: "\ed9e";
}
.icon-roundedtablet_mac:before {
  content: "\ed9f";
}
.icon-roundedtag_faces:before {
  content: "\eda0";
}
.icon-roundedtap_and_play:before {
  content: "\eda1";
}
.icon-roundedtapas:before {
  content: "\eda2";
}
.icon-roundedterrain:before {
  content: "\eda3";
}
.icon-roundedtext_fields:before {
  content: "\eda4";
}
.icon-roundedtext_format:before {
  content: "\eda5";
}
.icon-roundedtext_rotate_up:before {
  content: "\eda6";
}
.icon-roundedtext_rotate_vertical:before {
  content: "\eda7";
}
.icon-roundedtext_rotation_angledown:before {
  content: "\eda8";
}
.icon-roundedtext_rotation_angleup:before {
  content: "\eda9";
}
.icon-roundedtext_rotation_down:before {
  content: "\edaa";
}
.icon-roundedtext_rotation_none:before {
  content: "\edab";
}
.icon-roundedtext_snippet:before {
  content: "\edac";
}
.icon-roundedtextsms:before {
  content: "\edad";
}
.icon-roundedtexture:before {
  content: "\edae";
}
.icon-roundedtheaters:before {
  content: "\edaf";
}
.icon-roundedthermostat:before {
  content: "\edb0";
}
.icon-roundedthumb_down:before {
  content: "\edb1";
}
.icon-roundedthumb_down_alt:before {
  content: "\edb2";
}
.icon-roundedthumb_up:before {
  content: "\edb3";
}
.icon-roundedthumb_up_alt:before {
  content: "\edb4";
}
.icon-roundedthumbs_up_down:before {
  content: "\edb5";
}
.icon-roundedtime_to_leave:before {
  content: "\edb6";
}
.icon-roundedtimelapse:before {
  content: "\edb7";
}
.icon-roundedtimeline:before {
  content: "\edb8";
}
.icon-roundedtimer:before {
  content: "\edb9";
}
.icon-roundedtimer_3:before {
  content: "\edba";
}
.icon-roundedtimer_10:before {
  content: "\edbb";
}
.icon-roundedtimer_off:before {
  content: "\edbc";
}
.icon-roundedtitle:before {
  content: "\edbd";
}
.icon-roundedtoc:before {
  content: "\edbe";
}
.icon-roundedtoday:before {
  content: "\edbf";
}
.icon-roundedtoggle_off:before {
  content: "\edc0";
}
.icon-roundedtoggle_on:before {
  content: "\edc1";
}
.icon-roundedtoll:before {
  content: "\edc2";
}
.icon-roundedtonality:before {
  content: "\edc3";
}
.icon-roundedtopic:before {
  content: "\edc4";
}
.icon-roundedtouch_app:before {
  content: "\edc5";
}
.icon-roundedtour:before {
  content: "\edc6";
}
.icon-roundedtoys:before {
  content: "\edc7";
}
.icon-roundedtrack_changes:before {
  content: "\edc8";
}
.icon-roundedtraffic:before {
  content: "\edc9";
}
.icon-roundedtrain:before {
  content: "\edca";
}
.icon-roundedtram:before {
  content: "\edcb";
}
.icon-roundedtransfer_within_a_station:before {
  content: "\edcc";
}
.icon-roundedtransform:before {
  content: "\edcd";
}
.icon-roundedtransit_enterexit:before {
  content: "\edce";
}
.icon-roundedtranslate:before {
  content: "\edcf";
}
.icon-roundedtrending_down:before {
  content: "\edd0";
}
.icon-roundedtrending_flat:before {
  content: "\edd1";
}
.icon-roundedtrending_up:before {
  content: "\edd2";
}
.icon-roundedtrip_origin:before {
  content: "\edd3";
}
.icon-roundedtty:before {
  content: "\edd4";
}
.icon-roundedtune:before {
  content: "\edd5";
}
.icon-roundedturned_in:before {
  content: "\edd6";
}
.icon-roundedturned_in_not:before {
  content: "\edd7";
}
.icon-roundedtv:before {
  content: "\edd8";
}
.icon-roundedtv_off:before {
  content: "\edd9";
}
.icon-roundedtwo_wheeler:before {
  content: "\edda";
}
.icon-roundedumbrella:before {
  content: "\eddb";
}
.icon-roundedunarchive:before {
  content: "\eddc";
}
.icon-roundedundo:before {
  content: "\eddd";
}
.icon-roundedunfold_less:before {
  content: "\edde";
}
.icon-roundedunfold_more:before {
  content: "\eddf";
}
.icon-roundedunpublished:before {
  content: "\ede0";
}
.icon-roundedunsubscribe:before {
  content: "\ede1";
}
.icon-roundedupdate:before {
  content: "\ede2";
}
.icon-roundedupgrade:before {
  content: "\ede3";
}
.icon-roundedupload:before {
  content: "\ede4";
}
.icon-roundedusb:before {
  content: "\ede5";
}
.icon-roundedverified:before {
  content: "\ede6";
}
.icon-roundedverified_user:before {
  content: "\ede7";
}
.icon-roundedvertical_align_bottom:before {
  content: "\ede8";
}
.icon-roundedvertical_align_center:before {
  content: "\ede9";
}
.icon-roundedvertical_align_top:before {
  content: "\edea";
}
.icon-roundedvertical_split:before {
  content: "\edeb";
}
.icon-roundedvibration:before {
  content: "\edec";
}
.icon-roundedvideo_call:before {
  content: "\eded";
}
.icon-roundedvideo_label:before {
  content: "\edee";
}
.icon-roundedvideo_library:before {
  content: "\edef";
}
.icon-roundedvideo_settings:before {
  content: "\edf0";
}
.icon-roundedvideocam:before {
  content: "\edf1";
}
.icon-roundedvideocam_off:before {
  content: "\edf2";
}
.icon-roundedvideogame_asset:before {
  content: "\edf3";
}
.icon-roundedview_agenda:before {
  content: "\edf4";
}
.icon-roundedview_array:before {
  content: "\edf5";
}
.icon-roundedview_carousel:before {
  content: "\edf6";
}
.icon-roundedview_column:before {
  content: "\edf7";
}
.icon-roundedview_comfy:before {
  content: "\edf8";
}
.icon-roundedview_compact:before {
  content: "\edf9";
}
.icon-roundedview_day:before {
  content: "\edfa";
}
.icon-roundedview_headline:before {
  content: "\edfb";
}
.icon-roundedview_list:before {
  content: "\edfc";
}
.icon-roundedview_module:before {
  content: "\edfd";
}
.icon-roundedview_quilt:before {
  content: "\edfe";
}
.icon-roundedview_sidebar:before {
  content: "\edff";
}
.icon-roundedview_stream:before {
  content: "\ee00";
}
.icon-roundedview_week:before {
  content: "\ee01";
}
.icon-roundedvignette:before {
  content: "\ee02";
}
.icon-roundedvisibility:before {
  content: "\ee03";
}
.icon-roundedvisibility_off:before {
  content: "\ee04";
}
.icon-roundedvoice_chat:before {
  content: "\ee05";
}
.icon-roundedvoice_over_off:before {
  content: "\ee06";
}
.icon-roundedvoicemail:before {
  content: "\ee07";
}
.icon-roundedvolume_down:before {
  content: "\ee08";
}
.icon-roundedvolume_mute:before {
  content: "\ee09";
}
.icon-roundedvolume_off:before {
  content: "\ee0a";
}
.icon-roundedvolume_up:before {
  content: "\ee0b";
}
.icon-roundedvpn_key:before {
  content: "\ee0c";
}
.icon-roundedvpn_lock:before {
  content: "\ee0d";
}
.icon-roundedwallpaper:before {
  content: "\ee0e";
}
.icon-roundedwarning:before {
  content: "\ee0f";
}
.icon-roundedwarning_amber:before {
  content: "\ee10";
}
.icon-roundedwash:before {
  content: "\ee11";
}
.icon-roundedwatch:before {
  content: "\ee12";
}
.icon-roundedwatch_later:before {
  content: "\ee13";
}
.icon-roundedwater_damage:before {
  content: "\ee14";
}
.icon-roundedwaves:before {
  content: "\ee15";
}
.icon-roundedwb_auto:before {
  content: "\ee16";
}
.icon-roundedwb_cloudy:before {
  content: "\ee17";
}
.icon-roundedwb_incandescent:before {
  content: "\ee18";
}
.icon-roundedwb_iridescent:before {
  content: "\ee19";
}
.icon-roundedwb_sunny:before {
  content: "\ee1a";
}
.icon-roundedwc:before {
  content: "\ee1b";
}
.icon-roundedweb:before {
  content: "\ee1c";
}
.icon-roundedweb_asset:before {
  content: "\ee1d";
}
.icon-roundedweekend:before {
  content: "\ee1e";
}
.icon-roundedwest:before {
  content: "\ee1f";
}
.icon-roundedwhatshot:before {
  content: "\ee20";
}
.icon-roundedwheelchair_pickup:before {
  content: "\ee21";
}
.icon-roundedwhere_to_vote:before {
  content: "\ee22";
}
.icon-roundedwidgets:before {
  content: "\ee23";
}
.icon-roundedwifi:before {
  content: "\ee24";
}
.icon-roundedwifi_calling:before {
  content: "\ee25";
}
.icon-roundedwifi_lock:before {
  content: "\ee26";
}
.icon-roundedwifi_off:before {
  content: "\ee27";
}
.icon-roundedwifi_protected_setup:before {
  content: "\ee28";
}
.icon-roundedwifi_tethering:before {
  content: "\ee29";
}
.icon-roundedwine_bar:before {
  content: "\ee2a";
}
.icon-roundedwork:before {
  content: "\ee2b";
}
.icon-roundedwork_off:before {
  content: "\ee2c";
}
.icon-roundedwork_outline:before {
  content: "\ee2d";
}
.icon-roundedwrap_text:before {
  content: "\ee2e";
}
.icon-roundedwrong_location:before {
  content: "\ee2f";
}
.icon-roundedwysiwyg:before {
  content: "\ee30";
}
.icon-roundedyoutube_searched_for:before {
  content: "\ee31";
}
.icon-roundedzoom_in:before {
  content: "\ee32";
}
.icon-roundedzoom_out:before {
  content: "\ee33";
}
.icon-roundedzoom_out_map:before {
  content: "\ee34";
}
