@font-face {
  font-family: 'twotone';
  src:  url('fonts/twotone.eot?rspy01');
  src:  url('fonts/twotone.eot?rspy01#iefix') format('embedded-opentype'),
    url('fonts/twotone.ttf?rspy01') format('truetype'),
    url('fonts/twotone.woff?rspy01') format('woff'),
    url('fonts/twotone.svg?rspy01#twotone') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-twotone"], [class*=" icon-twotone"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'twotone' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twotoned_rotation:before {
  content: "\e900";
}
.icon-twotonek .path1:before {
  content: "\e901";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonek .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneg:before {
  content: "\e903";
}
.icon-twotoneft_apart:before {
  content: "\e904";
}
.icon-twotone360:before {
  content: "\e905";
}
.icon-twotoneac_unit:before {
  content: "\e906";
}
.icon-twotoneaccess_alarm .path1:before {
  content: "\e907";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccess_alarm .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccess_alarms .path1:before {
  content: "\e909";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccess_alarms .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccess_time .path1:before {
  content: "\e90b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccess_time .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccessibility:before {
  content: "\e90d";
}
.icon-twotoneaccessibility_new:before {
  content: "\e90e";
}
.icon-twotoneaccessible:before {
  content: "\e90f";
}
.icon-twotoneaccessible_forward:before {
  content: "\e910";
}
.icon-twotoneaccount_balance .path1:before {
  content: "\e911";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_balance .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_balance_wallet .path1:before {
  content: "\e913";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_balance_wallet .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_balance_wallet .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_box .path1:before {
  content: "\e916";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_box .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_circle .path1:before {
  content: "\e918";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_circle .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_tree .path1:before {
  content: "\e91a";
  color: rgb(37, 44, 50);
}
.icon-twotoneaccount_tree .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_tree .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaccount_tree .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonead_units .path1:before {
  content: "\e91e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonead_units .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonead_units .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonead_units .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadb:before {
  content: "\e922";
}
.icon-twotoneadd:before {
  content: "\e923";
}
.icon-twotoneadd_a_photo .path1:before {
  content: "\e924";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_a_photo .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_alarm .path1:before {
  content: "\e926";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_alarm .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_alert .path1:before {
  content: "\e928";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_alert .path2:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_box .path1:before {
  content: "\e92a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_box .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_business .path1:before {
  content: "\e92c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_business .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_business .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_business .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_circle .path1:before {
  content: "\e930";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_circle .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_circle_outline:before {
  content: "\e932";
}
.icon-twotoneadd_comment .path1:before {
  content: "\e933";
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_comment .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_ic_call .path1:before {
  content: "\e935";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_ic_call .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_location .path1:before {
  content: "\e937";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_location .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_location_alt .path1:before {
  content: "\e939";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotoneadd_location_alt .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_photo_alternate .path1:before {
  content: "\e93b";
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_photo_alternate .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_photo_alternate .path3:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_road:before {
  content: "\e93e";
}
.icon-twotoneadd_shopping_cart:before {
  content: "\e93f";
}
.icon-twotoneadd_task:before {
  content: "\e940";
}
.icon-twotoneadd_to_home_screen:before {
  content: "\e941";
}
.icon-twotoneadd_to_photos .path1:before {
  content: "\e942";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_to_photos .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadd_to_queue .path1:before {
  content: "\e944";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadd_to_queue .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaddchart:before {
  content: "\e946";
}
.icon-twotoneadjust:before {
  content: "\e947";
}
.icon-twotoneadmin_panel_settings .path1:before {
  content: "\e948";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadmin_panel_settings .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneadmin_panel_settings .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadmin_panel_settings .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneadmin_panel_settings .path5:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneagriculture .path1:before {
  content: "\e94d";
  color: rgb(37, 44, 50);
}
.icon-twotoneagriculture .path2:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneagriculture .path3:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairline_seat_flat .path1:before {
  content: "\e950";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairline_seat_flat .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneairline_seat_flat_angled .path1:before {
  content: "\e952";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairline_seat_flat_angled .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneairline_seat_individual_suite .path1:before {
  content: "\e954";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairline_seat_individual_suite .path2:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairline_seat_individual_suite .path3:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneairline_seat_legroom_extra:before {
  content: "\e957";
}
.icon-twotoneairline_seat_legroom_normal:before {
  content: "\e958";
}
.icon-twotoneairline_seat_legroom_reduced:before {
  content: "\e959";
}
.icon-twotoneairline_seat_recline_extra:before {
  content: "\e95a";
}
.icon-twotoneairline_seat_recline_normal:before {
  content: "\e95b";
}
.icon-twotoneairplanemode_active:before {
  content: "\e95c";
}
.icon-twotoneairplanemode_inactive:before {
  content: "\e95d";
}
.icon-twotoneairplay:before {
  content: "\e95e";
}
.icon-twotoneairport_shuttle .path1:before {
  content: "\e95f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneairport_shuttle .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonealarm .path1:before {
  content: "\e961";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonealarm .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonealarm_add .path1:before {
  content: "\e963";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonealarm_add .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonealarm_off:before {
  content: "\e965";
}
.icon-twotonealarm_on .path1:before {
  content: "\e966";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonealarm_on .path2:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonealbum .path1:before {
  content: "\e968";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonealbum .path2:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneall_inbox .path1:before {
  content: "\e96a";
  color: rgb(37, 44, 50);
}
.icon-twotoneall_inbox .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneall_inclusive:before {
  content: "\e96c";
}
.icon-twotoneall_out .path1:before {
  content: "\e96d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneall_out .path2:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonealt_route:before {
  content: "\e96f";
}
.icon-twotonealternate_email:before {
  content: "\e970";
}
.icon-twotoneamp_stories .path1:before {
  content: "\e971";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotoneamp_stories .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneamp_stories .path3:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneamp_stories .path4:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanalytics .path1:before {
  content: "\e975";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneanalytics .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanalytics .path3:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanalytics .path4:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanalytics .path5:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanalytics .path6:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneanchor:before {
  content: "\e97b";
}
.icon-twotoneandroid:before {
  content: "\e97c";
}
.icon-twotoneannouncement .path1:before {
  content: "\e97d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneannouncement .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneapartment:before {
  content: "\e97f";
}
.icon-twotoneapi:before {
  content: "\e980";
}
.icon-twotoneapp_blocking .path1:before {
  content: "\e981";
  color: rgb(37, 44, 50);
}
.icon-twotoneapp_blocking .path2:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneapp_blocking .path3:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneapp_blocking .path4:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneapp_settings_alt .path1:before {
  content: "\e985";
  color: rgb(37, 44, 50);
}
.icon-twotoneapp_settings_alt .path2:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneapp_settings_alt .path3:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneapps:before {
  content: "\e988";
}
.icon-twotonearchitecture:before {
  content: "\e989";
}
.icon-twotonearchive .path1:before {
  content: "\e98a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonearchive .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonearrow_back:before {
  content: "\e98c";
}
.icon-twotonearrow_back_ios:before {
  content: "\e98d";
}
.icon-twotonearrow_circle_down .path1:before {
  content: "\e98e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonearrow_circle_down .path2:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonearrow_circle_up .path1:before {
  content: "\e990";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonearrow_circle_up .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonearrow_downward:before {
  content: "\e992";
}
.icon-twotonearrow_drop_down:before {
  content: "\e993";
}
.icon-twotonearrow_drop_down_circle .path1:before {
  content: "\e994";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonearrow_drop_down_circle .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonearrow_drop_up:before {
  content: "\e996";
}
.icon-twotonearrow_forward:before {
  content: "\e997";
}
.icon-twotonearrow_forward_ios:before {
  content: "\e998";
}
.icon-twotonearrow_left:before {
  content: "\e999";
}
.icon-twotonearrow_right:before {
  content: "\e99a";
}
.icon-twotonearrow_right_alt:before {
  content: "\e99b";
}
.icon-twotonearrow_upward:before {
  content: "\e99c";
}
.icon-twotoneart_track:before {
  content: "\e99d";
}
.icon-twotonearticle .path1:before {
  content: "\e99e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonearticle .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaspect_ratio .path1:before {
  content: "\e9a0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaspect_ratio .path2:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassessment .path1:before {
  content: "\e9a2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassessment .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment .path1:before {
  content: "\e9a4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment .path2:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment_ind .path1:before {
  content: "\e9a6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment_ind .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment_late .path1:before {
  content: "\e9a8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment_late .path2:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment_return .path1:before {
  content: "\e9aa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment_return .path2:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment_returned .path1:before {
  content: "\e9ac";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment_returned .path2:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassignment_turned_in .path1:before {
  content: "\e9ae";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassignment_turned_in .path2:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassistant .path1:before {
  content: "\e9b0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassistant .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneassistant_photo .path1:before {
  content: "\e9b2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneassistant_photo .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneatm:before {
  content: "\e9b4";
}
.icon-twotoneattach_email:before {
  content: "\e9b5";
}
.icon-twotoneattach_file:before {
  content: "\e9b6";
}
.icon-twotoneattach_money:before {
  content: "\e9b7";
}
.icon-twotoneattachment:before {
  content: "\e9b8";
}
.icon-twotoneattribution .path1:before {
  content: "\e9b9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneattribution .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneattribution .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneaudiotrack .path1:before {
  content: "\e9bc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneaudiotrack .path2:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneauto_delete .path1:before {
  content: "\e9be";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneauto_delete .path2:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneauto_delete .path3:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneauto_delete .path4:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneautorenew:before {
  content: "\e9c2";
}
.icon-twotoneav_timer:before {
  content: "\e9c3";
}
.icon-twotonebaby_changing_station:before {
  content: "\e9c4";
}
.icon-twotonebackpack .path1:before {
  content: "\e9c5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackpack .path2:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebackspace .path1:before {
  content: "\e9c7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackspace .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebackup .path1:before {
  content: "\e9c9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackup .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebackup_table .path1:before {
  content: "\e9cb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackup_table .path2:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackup_table .path3:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebackup_table .path4:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebackup_table .path5:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneballot .path1:before {
  content: "\e9d0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneballot .path2:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebar_chart:before {
  content: "\e9d2";
}
.icon-twotonebatch_prediction .path1:before {
  content: "\e9d3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebatch_prediction .path2:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebathtub .path1:before {
  content: "\e9d5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebathtub .path2:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebathtub .path3:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_20 .path1:before {
  content: "\e9d8";
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_20 .path2:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_30 .path1:before {
  content: "\e9da";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_30 .path2:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_50 .path1:before {
  content: "\e9dc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_50 .path2:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_60 .path1:before {
  content: "\e9de";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_60 .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_80 .path1:before {
  content: "\e9e0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_80 .path2:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_90 .path1:before {
  content: "\e9e2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_90 .path2:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_alert:before {
  content: "\e9e4";
}
.icon-twotonebattery_charging_20 .path1:before {
  content: "\e9e5";
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_20 .path2:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_30 .path1:before {
  content: "\e9e7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_30 .path2:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_50 .path1:before {
  content: "\e9e9";
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_50 .path2:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_60 .path1:before {
  content: "\e9eb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_60 .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_80 .path1:before {
  content: "\e9ed";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_80 .path2:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_90 .path1:before {
  content: "\e9ef";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebattery_charging_90 .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebattery_charging_full:before {
  content: "\e9f1";
}
.icon-twotonebattery_full:before {
  content: "\e9f2";
}
.icon-twotonebattery_std:before {
  content: "\e9f3";
}
.icon-twotonebattery_unknown:before {
  content: "\e9f4";
}
.icon-twotonebeach_access .path1:before {
  content: "\e9f5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebeach_access .path2:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebedtime .path1:before {
  content: "\e9f7";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonebedtime .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebeenhere .path1:before {
  content: "\e9f9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebeenhere .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebento .path1:before {
  content: "\e9fb";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonebento .path2:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebike_scooter:before {
  content: "\e9fd";
}
.icon-twotonebiotech .path1:before {
  content: "\e9fe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebiotech .path2:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebiotech .path3:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneblock:before {
  content: "\ea01";
}
.icon-twotonebluetooth:before {
  content: "\ea02";
}
.icon-twotonebluetooth_audio:before {
  content: "\ea03";
}
.icon-twotonebluetooth_connected:before {
  content: "\ea04";
}
.icon-twotonebluetooth_disabled:before {
  content: "\ea05";
}
.icon-twotonebluetooth_searching:before {
  content: "\ea06";
}
.icon-twotoneblur_circular:before {
  content: "\ea07";
}
.icon-twotoneblur_linear:before {
  content: "\ea08";
}
.icon-twotoneblur_off:before {
  content: "\ea09";
}
.icon-twotoneblur_on:before {
  content: "\ea0a";
}
.icon-twotonebook .path1:before {
  content: "\ea0b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebook .path2:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebook_online .path1:before {
  content: "\ea0d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebook_online .path2:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebookmark .path1:before {
  content: "\ea0f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebookmark .path2:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebookmark_border:before {
  content: "\ea11";
}
.icon-twotonebookmarks .path1:before {
  content: "\ea12";
  color: rgb(37, 44, 50);
}
.icon-twotonebookmarks .path2:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneborder_all:before {
  content: "\ea14";
}
.icon-twotoneborder_bottom:before {
  content: "\ea15";
}
.icon-twotoneborder_clear:before {
  content: "\ea16";
}
.icon-twotoneborder_color .path1:before {
  content: "\ea17";
  color: rgb(50, 50, 50);
  opacity: 0.36;
}
.icon-twotoneborder_color .path2:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneborder_color .path3:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(50, 50, 50);
  opacity: 0.36;
}
.icon-twotoneborder_horizontal:before {
  content: "\ea1a";
}
.icon-twotoneborder_inner:before {
  content: "\ea1b";
}
.icon-twotoneborder_left:before {
  content: "\ea1c";
}
.icon-twotoneborder_outer:before {
  content: "\ea1d";
}
.icon-twotoneborder_right:before {
  content: "\ea1e";
}
.icon-twotoneborder_style:before {
  content: "\ea1f";
}
.icon-twotoneborder_top:before {
  content: "\ea20";
}
.icon-twotoneborder_vertical:before {
  content: "\ea21";
}
.icon-twotonebranding_watermark .path1:before {
  content: "\ea22";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebranding_watermark .path2:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_1 .path1:before {
  content: "\ea24";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_1 .path2:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_2 .path1:before {
  content: "\ea26";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_2 .path2:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_3 .path1:before {
  content: "\ea28";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_3 .path2:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_4 .path1:before {
  content: "\ea2a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_4 .path2:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_5 .path1:before {
  content: "\ea2c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_5 .path2:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_6 .path1:before {
  content: "\ea2e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_6 .path2:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_7 .path1:before {
  content: "\ea30";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_7 .path2:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_7 .path3:before {
  content: "\ea32";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_auto .path1:before {
  content: "\ea33";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_auto .path2:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_high .path1:before {
  content: "\ea35";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_high .path2:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_high .path3:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_low .path1:before {
  content: "\ea38";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_low .path2:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrightness_medium .path1:before {
  content: "\ea3a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrightness_medium .path2:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebroken_image .path1:before {
  content: "\ea3c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebroken_image .path2:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebrowser_not_supported:before {
  content: "\ea3e";
}
.icon-twotonebrush .path1:before {
  content: "\ea3f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebrush .path2:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebubble_chart .path1:before {
  content: "\ea41";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebubble_chart .path2:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebubble_chart .path3:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebubble_chart .path4:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebug_report .path1:before {
  content: "\ea45";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebug_report .path2:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebuild .path1:before {
  content: "\ea47";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebuild .path2:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebuild_circle .path1:before {
  content: "\ea49";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebuild_circle .path2:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebuild_circle .path3:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneburst_mode .path1:before {
  content: "\ea4c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneburst_mode .path2:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebusiness .path1:before {
  content: "\ea4e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebusiness .path2:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonebusiness_center .path1:before {
  content: "\ea50";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonebusiness_center .path2:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecached:before {
  content: "\ea52";
}
.icon-twotonecake .path1:before {
  content: "\ea53";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecake .path2:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path1:before {
  content: "\ea55";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecalculate .path2:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path3:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path4:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path5:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path6:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalculate .path7:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecalendar_today .path1:before {
  content: "\ea5c";
  color: rgb(37, 44, 50);
}
.icon-twotonecalendar_today .path2:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecalendar_view_day .path1:before {
  content: "\ea5e";
  color: rgb(37, 44, 50);
}
.icon-twotonecalendar_view_day .path2:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecall .path1:before {
  content: "\ea60";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecall .path2:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecall_end .path1:before {
  content: "\ea62";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecall_end .path2:before {
  content: "\ea63";
  margin-left: -1.3330078125em;
  color: rgb(37, 44, 50);
}
.icon-twotonecall_made:before {
  content: "\ea64";
}
.icon-twotonecall_merge:before {
  content: "\ea65";
}
.icon-twotonecall_missed:before {
  content: "\ea66";
}
.icon-twotonecall_missed_outgoing:before {
  content: "\ea67";
}
.icon-twotonecall_received:before {
  content: "\ea68";
}
.icon-twotonecall_split:before {
  content: "\ea69";
}
.icon-twotonecall_to_action .path1:before {
  content: "\ea6a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecall_to_action .path2:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera .path1:before {
  content: "\ea6c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera .path2:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera_alt .path1:before {
  content: "\ea6e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera_alt .path2:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera_enhance .path1:before {
  content: "\ea70";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera_enhance .path2:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera_front .path1:before {
  content: "\ea72";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera_front .path2:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera_rear .path1:before {
  content: "\ea74";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera_rear .path2:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecamera_roll .path1:before {
  content: "\ea76";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecamera_roll .path2:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path1:before {
  content: "\ea78";
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path2:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path3:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path4:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path5:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecampaign .path6:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel .path1:before {
  content: "\ea7e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel .path2:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecancel_presentation .path1:before {
  content: "\ea80";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel_presentation .path2:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecancel_schedule_send .path1:before {
  content: "\ea82";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel_schedule_send .path2:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel_schedule_send .path3:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecancel_schedule_send .path4:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecancel_schedule_send .path5:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecard_giftcard .path1:before {
  content: "\ea87";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecard_giftcard .path2:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecard_membership .path1:before {
  content: "\ea89";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecard_membership .path2:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecard_travel .path1:before {
  content: "\ea8b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecard_travel .path2:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecarpenter .path1:before {
  content: "\ea8d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecarpenter .path2:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path1:before {
  content: "\ea8f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecasino .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path3:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path4:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path5:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path6:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecasino .path7:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecast:before {
  content: "\ea96";
}
.icon-twotonecast_connected .path1:before {
  content: "\ea97";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecast_connected .path2:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecast_for_education:before {
  content: "\ea99";
}
.icon-twotonecategory .path1:before {
  content: "\ea9a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecategory .path2:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecategory .path3:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecell_wifi .path1:before {
  content: "\ea9d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecell_wifi .path2:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecenter_focus_strong .path1:before {
  content: "\ea9f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecenter_focus_strong .path2:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecenter_focus_weak .path1:before {
  content: "\eaa1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecenter_focus_weak .path2:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechange_history .path1:before {
  content: "\eaa3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechange_history .path2:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecharging_station .path1:before {
  content: "\eaa5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecharging_station .path2:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechat .path1:before {
  content: "\eaa7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechat .path2:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechat_bubble .path1:before {
  content: "\eaa9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechat_bubble .path2:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechat_bubble_outline:before {
  content: "\eaab";
}
.icon-twotonecheck:before {
  content: "\eaac";
}
.icon-twotonecheck_box .path1:before {
  content: "\eaad";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecheck_box .path2:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecheck_box_outline_blank:before {
  content: "\eaaf";
}
.icon-twotonecheck_circle .path1:before {
  content: "\eab0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecheck_circle .path2:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecheck_circle_outline:before {
  content: "\eab2";
}
.icon-twotonecheckroom:before {
  content: "\eab3";
}
.icon-twotonechevron_left:before {
  content: "\eab4";
}
.icon-twotonechevron_right:before {
  content: "\eab5";
}
.icon-twotonechild_care .path1:before {
  content: "\eab6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechild_care .path2:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechild_care .path3:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechild_care .path4:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechild_friendly .path1:before {
  content: "\eaba";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechild_friendly .path2:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonechrome_reader_mode .path1:before {
  content: "\eabc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonechrome_reader_mode .path2:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneclass .path1:before {
  content: "\eabe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneclass .path2:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneclean_hands .path1:before {
  content: "\eac0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneclean_hands .path2:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecleaning_services .path1:before {
  content: "\eac2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecleaning_services .path2:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecleaning_services .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneclear:before {
  content: "\eac5";
}
.icon-twotoneclear_all:before {
  content: "\eac6";
}
.icon-twotoneclose:before {
  content: "\eac7";
}
.icon-twotoneclose_fullscreen:before {
  content: "\eac8";
}
.icon-twotoneclosed_caption .path1:before {
  content: "\eac9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneclosed_caption .path2:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneclosed_caption_disabled .path1:before {
  content: "\eacb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneclosed_caption_disabled .path2:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud .path1:before {
  content: "\eacd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud .path2:before {
  content: "\eace";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_circle .path1:before {
  content: "\eacf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_circle .path2:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_done .path1:before {
  content: "\ead1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_done .path2:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_download .path1:before {
  content: "\ead3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_download .path2:before {
  content: "\ead4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_off .path1:before {
  content: "\ead5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_off .path2:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_queue .path1:before {
  content: "\ead7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_queue .path2:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecloud_upload .path1:before {
  content: "\ead9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecloud_upload .path2:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecode:before {
  content: "\eadb";
}
.icon-twotonecollections .path1:before {
  content: "\eadc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecollections .path2:before {
  content: "\eadd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecollections_bookmark .path1:before {
  content: "\eade";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecollections_bookmark .path2:before {
  content: "\eadf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolor_lens .path1:before {
  content: "\eae0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecolor_lens .path2:before {
  content: "\eae1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolor_lens .path3:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolor_lens .path4:before {
  content: "\eae3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolor_lens .path5:before {
  content: "\eae4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolor_lens .path6:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecolorize .path1:before {
  content: "\eae6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecolorize .path2:before {
  content: "\eae7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecomment .path1:before {
  content: "\eae8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecomment .path2:before {
  content: "\eae9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecomment_bank .path1:before {
  content: "\eaea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecomment_bank .path2:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecomment_bank .path3:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecommute:before {
  content: "\eaed";
}
.icon-twotonecompare .path1:before {
  content: "\eaee";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecompare .path2:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecompare_arrows:before {
  content: "\eaf0";
}
.icon-twotonecompass_calibration .path1:before {
  content: "\eaf1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecompass_calibration .path2:before {
  content: "\eaf2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecompass_calibration .path3:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecomputer .path1:before {
  content: "\eaf4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecomputer .path2:before {
  content: "\eaf5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneconfirmation_number .path1:before {
  content: "\eaf6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneconfirmation_number .path2:before {
  content: "\eaf7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneconnect_without_contact:before {
  content: "\eaf8";
}
.icon-twotoneconstruction:before {
  content: "\eaf9";
}
.icon-twotonecontact_mail .path1:before {
  content: "\eafa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontact_mail .path2:before {
  content: "\eafb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontact_page .path1:before {
  content: "\eafc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontact_page .path2:before {
  content: "\eafd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontact_phone .path1:before {
  content: "\eafe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontact_phone .path2:before {
  content: "\eaff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontact_support .path1:before {
  content: "\eb00";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontact_support .path2:before {
  content: "\eb01";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontactless .path1:before {
  content: "\eb02";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontactless .path2:before {
  content: "\eb03";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontactless .path3:before {
  content: "\eb04";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontactless .path4:before {
  content: "\eb05";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontactless .path5:before {
  content: "\eb06";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontacts .path1:before {
  content: "\eb07";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontacts .path2:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontent_copy .path1:before {
  content: "\eb09";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontent_copy .path2:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontent_cut:before {
  content: "\eb0b";
}
.icon-twotonecontent_paste .path1:before {
  content: "\eb0c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontent_paste .path2:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontrol_camera:before {
  content: "\eb0e";
}
.icon-twotonecontrol_point .path1:before {
  content: "\eb0f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontrol_point .path2:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecontrol_point_duplicate .path1:before {
  content: "\eb11";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecontrol_point_duplicate .path2:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecopyright .path1:before {
  content: "\eb13";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecopyright .path2:before {
  content: "\eb14";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecoronavirus .path1:before {
  content: "\eb15";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecoronavirus .path2:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecorporate_fare .path1:before {
  content: "\eb17";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecorporate_fare .path2:before {
  content: "\eb18";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecountertops .path1:before {
  content: "\eb19";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecountertops .path2:before {
  content: "\eb1a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecreate .path1:before {
  content: "\eb1b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecreate .path2:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecreate_new_folder .path1:before {
  content: "\eb1d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecreate_new_folder .path2:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecredit_card .path1:before {
  content: "\eb1f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonecredit_card .path2:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonecrop:before {
  content: "\eb21";
}
.icon-twotonecrop_3_2:before {
  content: "\eb22";
}
.icon-twotonecrop_5_4:before {
  content: "\eb23";
}
.icon-twotonecrop_7_5:before {
  content: "\eb24";
}
.icon-twotonecrop_16_9:before {
  content: "\eb25";
}
.icon-twotonecrop_din:before {
  content: "\eb26";
}
.icon-twotonecrop_free:before {
  content: "\eb27";
}
.icon-twotonecrop_landscape:before {
  content: "\eb28";
}
.icon-twotonecrop_original:before {
  content: "\eb29";
}
.icon-twotonecrop_portrait:before {
  content: "\eb2a";
}
.icon-twotonecrop_rotate:before {
  content: "\eb2b";
}
.icon-twotonecrop_square:before {
  content: "\eb2c";
}
.icon-twotonedashboard .path1:before {
  content: "\eb2d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedashboard .path2:before {
  content: "\eb2e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedata_usage:before {
  content: "\eb2f";
}
.icon-twotonedate_range .path1:before {
  content: "\eb30";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedate_range .path2:before {
  content: "\eb31";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeck .path1:before {
  content: "\eb32";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedeck .path2:before {
  content: "\eb33";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeck .path3:before {
  content: "\eb34";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeck .path4:before {
  content: "\eb35";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedehaze:before {
  content: "\eb36";
}
.icon-twotonedelete .path1:before {
  content: "\eb37";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedelete .path2:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedelete_forever .path1:before {
  content: "\eb39";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedelete_forever .path2:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedelete_outline:before {
  content: "\eb3b";
}
.icon-twotonedelete_sweep .path1:before {
  content: "\eb3c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedelete_sweep .path2:before {
  content: "\eb3d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeparture_board .path1:before {
  content: "\eb3e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedeparture_board .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeparture_board .path3:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeparture_board .path4:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedescription .path1:before {
  content: "\eb42";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedescription .path2:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedesign_services .path1:before {
  content: "\eb44";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesign_services .path2:before {
  content: "\eb45";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesign_services .path3:before {
  content: "\eb46";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesign_services .path4:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesign_services .path5:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedesktop_access_disabled .path1:before {
  content: "\eb49";
  color: rgb(37, 44, 50);
}
.icon-twotonedesktop_access_disabled .path2:before {
  content: "\eb4a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesktop_mac .path1:before {
  content: "\eb4b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesktop_mac .path2:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedesktop_windows .path1:before {
  content: "\eb4d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedesktop_windows .path2:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedetails .path1:before {
  content: "\eb4f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedetails .path2:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeveloper_board .path1:before {
  content: "\eb51";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedeveloper_board .path2:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedeveloper_mode:before {
  content: "\eb53";
}
.icon-twotonedevice_hub:before {
  content: "\eb54";
}
.icon-twotonedevice_unknown .path1:before {
  content: "\eb55";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedevice_unknown .path2:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedevices .path1:before {
  content: "\eb57";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedevices .path2:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedevices_other .path1:before {
  content: "\eb59";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedevices_other .path2:before {
  content: "\eb5a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedevices_other .path3:before {
  content: "\eb5b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedialer_sip .path1:before {
  content: "\eb5c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedialer_sip .path2:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedialpad:before {
  content: "\eb5e";
}
.icon-twotonedirections .path1:before {
  content: "\eb5f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections .path2:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_bike:before {
  content: "\eb61";
}
.icon-twotonedirections_boat .path1:before {
  content: "\eb62";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_boat .path2:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_bus .path1:before {
  content: "\eb64";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_bus .path2:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_bus .path3:before {
  content: "\eb66";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_bus .path4:before {
  content: "\eb67";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_car .path1:before {
  content: "\eb68";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_car .path2:before {
  content: "\eb69";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_car .path3:before {
  content: "\eb6a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_car .path4:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_off:before {
  content: "\eb6c";
}
.icon-twotonedirections_railway .path1:before {
  content: "\eb6d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_railway .path2:before {
  content: "\eb6e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_run:before {
  content: "\eb6f";
}
.icon-twotonedirections_subway .path1:before {
  content: "\eb70";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_subway .path2:before {
  content: "\eb71";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_subway .path3:before {
  content: "\eb72";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_subway .path4:before {
  content: "\eb73";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_transit .path1:before {
  content: "\eb74";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedirections_transit .path2:before {
  content: "\eb75";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_transit .path3:before {
  content: "\eb76";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_transit .path4:before {
  content: "\eb77";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedirections_walk:before {
  content: "\eb78";
}
.icon-twotonedisabled_by_default .path1:before {
  content: "\eb79";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedisabled_by_default .path2:before {
  content: "\eb7a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedisc_full .path1:before {
  content: "\eb7b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedisc_full .path2:before {
  content: "\eb7c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedns .path1:before {
  content: "\eb7d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedns .path2:before {
  content: "\eb7e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedo_disturb:before {
  content: "\eb7f";
}
.icon-twotonedo_disturb_alt:before {
  content: "\eb80";
}
.icon-twotonedo_disturb_off .path1:before {
  content: "\eb81";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedo_disturb_off .path2:before {
  content: "\eb82";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedo_disturb_on .path1:before {
  content: "\eb83";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedo_disturb_on .path2:before {
  content: "\eb84";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedo_not_step .path1:before {
  content: "\eb85";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedo_not_step .path2:before {
  content: "\eb86";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedo_not_touch .path1:before {
  content: "\eb87";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedo_not_touch .path2:before {
  content: "\eb88";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedock .path1:before {
  content: "\eb89";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedock .path2:before {
  content: "\eb8a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedomain .path1:before {
  content: "\eb8b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedomain .path2:before {
  content: "\eb8c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedomain_disabled .path1:before {
  content: "\eb8d";
  color: rgb(37, 44, 50);
}
.icon-twotonedomain_disabled .path2:before {
  content: "\eb8e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedomain_verification .path1:before {
  content: "\eb8f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedomain_verification .path2:before {
  content: "\eb90";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedomain_verification .path3:before {
  content: "\eb91";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedone:before {
  content: "\eb92";
}
.icon-twotonedone_all:before {
  content: "\eb93";
}
.icon-twotonedone_outline:before {
  content: "\eb94";
}
.icon-twotonedonut_large:before {
  content: "\eb95";
}
.icon-twotonedonut_small .path1:before {
  content: "\eb96";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedonut_small .path2:before {
  content: "\eb97";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedouble_arrow:before {
  content: "\eb98";
}
.icon-twotonedownload .path1:before {
  content: "\eb99";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedownload .path2:before {
  content: "\eb9a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedownload_done:before {
  content: "\eb9b";
}
.icon-twotonedrafts .path1:before {
  content: "\eb9c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedrafts .path2:before {
  content: "\eb9d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedrag_handle:before {
  content: "\eb9e";
}
.icon-twotonedrag_indicator:before {
  content: "\eb9f";
}
.icon-twotonedrive_eta .path1:before {
  content: "\eba0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedrive_eta .path2:before {
  content: "\eba1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedrive_eta .path3:before {
  content: "\eba2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedrive_eta .path4:before {
  content: "\eba3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedry .path1:before {
  content: "\eba4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedry .path2:before {
  content: "\eba5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneduo:before {
  content: "\eba6";
}
.icon-twotonedvr .path1:before {
  content: "\eba7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedvr .path2:before {
  content: "\eba8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedynamic_feed .path1:before {
  content: "\eba9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedynamic_feed .path2:before {
  content: "\ebaa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedynamic_feed .path3:before {
  content: "\ebab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedynamic_feed .path4:before {
  content: "\ebac";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonedynamic_form .path1:before {
  content: "\ebad";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonedynamic_form .path2:before {
  content: "\ebae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneeast:before {
  content: "\ebaf";
}
.icon-twotoneeco .path1:before {
  content: "\ebb0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneeco .path2:before {
  content: "\ebb1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit .path1:before {
  content: "\ebb2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneedit .path2:before {
  content: "\ebb3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_attributes .path1:before {
  content: "\ebb4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneedit_attributes .path2:before {
  content: "\ebb5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_location .path1:before {
  content: "\ebb6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneedit_location .path2:before {
  content: "\ebb7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_location .path3:before {
  content: "\ebb8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneedit_road .path1:before {
  content: "\ebb9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneedit_road .path2:before {
  content: "\ebba";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_road .path3:before {
  content: "\ebbb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_road .path4:before {
  content: "\ebbc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_road .path5:before {
  content: "\ebbd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_road .path6:before {
  content: "\ebbe";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneedit_road .path7:before {
  content: "\ebbf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneeject .path1:before {
  content: "\ebc0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneeject .path2:before {
  content: "\ebc1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelderly:before {
  content: "\ebc2";
}
.icon-twotoneelectric_bike:before {
  content: "\ebc3";
}
.icon-twotoneelectric_car .path1:before {
  content: "\ebc4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneelectric_car .path2:before {
  content: "\ebc5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_car .path3:before {
  content: "\ebc6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_car .path4:before {
  content: "\ebc7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_car .path5:before {
  content: "\ebc8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_moped .path1:before {
  content: "\ebc9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneelectric_moped .path2:before {
  content: "\ebca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_moped .path3:before {
  content: "\ebcb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_moped .path4:before {
  content: "\ebcc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_moped .path5:before {
  content: "\ebcd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneelectric_rickshaw:before {
  content: "\ebce";
}
.icon-twotoneelectric_scooter:before {
  content: "\ebcf";
}
.icon-twotoneelectrical_services:before {
  content: "\ebd0";
}
.icon-twotoneelevator .path1:before {
  content: "\ebd1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneelevator .path2:before {
  content: "\ebd2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemail .path1:before {
  content: "\ebd3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemail .path2:before {
  content: "\ebd4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_emotions .path1:before {
  content: "\ebd5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_emotions .path2:before {
  content: "\ebd6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_emotions .path3:before {
  content: "\ebd7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_emotions .path4:before {
  content: "\ebd8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_emotions .path5:before {
  content: "\ebd9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_events .path1:before {
  content: "\ebda";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_events .path2:before {
  content: "\ebdb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_flags .path1:before {
  content: "\ebdc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_flags .path2:before {
  content: "\ebdd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_food_beverage .path1:before {
  content: "\ebde";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_food_beverage .path2:before {
  content: "\ebdf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_food_beverage .path3:before {
  content: "\ebe0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_nature .path1:before {
  content: "\ebe1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_nature .path2:before {
  content: "\ebe2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_nature .path3:before {
  content: "\ebe3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_nature .path4:before {
  content: "\ebe4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_nature .path5:before {
  content: "\ebe5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_objects .path1:before {
  content: "\ebe6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_objects .path2:before {
  content: "\ebe7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneemoji_objects .path3:before {
  content: "\ebe8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_objects .path4:before {
  content: "\ebe9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_objects .path5:before {
  content: "\ebea";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_objects .path6:before {
  content: "\ebeb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneemoji_people:before {
  content: "\ebec";
}
.icon-twotoneemoji_symbols:before {
  content: "\ebed";
}
.icon-twotoneemoji_transportation:before {
  content: "\ebee";
}
.icon-twotoneengineering .path1:before {
  content: "\ebef";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneengineering .path2:before {
  content: "\ebf0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneengineering .path3:before {
  content: "\ebf1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneengineering .path4:before {
  content: "\ebf2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneengineering .path5:before {
  content: "\ebf3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneengineering .path6:before {
  content: "\ebf4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneengineering .path7:before {
  content: "\ebf5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneengineering .path8:before {
  content: "\ebf6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneenhanced_encryption .path1:before {
  content: "\ebf7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneenhanced_encryption .path2:before {
  content: "\ebf8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneequalizer:before {
  content: "\ebf9";
}
.icon-twotoneerror .path1:before {
  content: "\ebfa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneerror .path2:before {
  content: "\ebfb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneerror_outline:before {
  content: "\ebfc";
}
.icon-twotoneescalator .path1:before {
  content: "\ebfd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneescalator .path2:before {
  content: "\ebfe";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneescalator_warning:before {
  content: "\ebff";
}
.icon-twotoneeuro:before {
  content: "\ec00";
}
.icon-twotoneeuro_symbol:before {
  content: "\ec01";
}
.icon-twotoneev_station .path1:before {
  content: "\ec02";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneev_station .path2:before {
  content: "\ec03";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneevent .path1:before {
  content: "\ec04";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneevent .path2:before {
  content: "\ec05";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneevent_available .path1:before {
  content: "\ec06";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneevent_available .path2:before {
  content: "\ec07";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneevent_busy .path1:before {
  content: "\ec08";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneevent_busy .path2:before {
  content: "\ec09";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneevent_note .path1:before {
  content: "\ec0a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneevent_note .path2:before {
  content: "\ec0b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneevent_seat .path1:before {
  content: "\ec0c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneevent_seat .path2:before {
  content: "\ec0d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneexit_to_app:before {
  content: "\ec0e";
}
.icon-twotoneexpand_less:before {
  content: "\ec0f";
}
.icon-twotoneexpand_more:before {
  content: "\ec10";
}
.icon-twotoneexplicit .path1:before {
  content: "\ec11";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneexplicit .path2:before {
  content: "\ec12";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneexplore .path1:before {
  content: "\ec13";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneexplore .path2:before {
  content: "\ec14";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneexplore_off .path1:before {
  content: "\ec15";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneexplore_off .path2:before {
  content: "\ec16";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneexposure .path1:before {
  content: "\ec17";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneexposure .path2:before {
  content: "\ec18";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneexposure_neg_1:before {
  content: "\ec19";
}
.icon-twotoneexposure_neg_2:before {
  content: "\ec1a";
}
.icon-twotoneexposure_plus_1:before {
  content: "\ec1b";
}
.icon-twotoneexposure_plus_2:before {
  content: "\ec1c";
}
.icon-twotoneexposure_zero:before {
  content: "\ec1d";
}
.icon-twotoneextension .path1:before {
  content: "\ec1e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneextension .path2:before {
  content: "\ec1f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneface .path1:before {
  content: "\ec20";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneface .path2:before {
  content: "\ec21";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneface .path3:before {
  content: "\ec22";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneface .path4:before {
  content: "\ec23";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneface_unlock:before {
  content: "\ec24";
}
.icon-twotonefacebook:before {
  content: "\ec25";
}
.icon-twotonefact_check .path1:before {
  content: "\ec26";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefact_check .path2:before {
  content: "\ec27";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefact_check .path3:before {
  content: "\ec28";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefact_check .path4:before {
  content: "\ec29";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefact_check .path5:before {
  content: "\ec2a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefact_check .path6:before {
  content: "\ec2b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefamily_restroom:before {
  content: "\ec2c";
}
.icon-twotonefast_forward .path1:before {
  content: "\ec2d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefast_forward .path2:before {
  content: "\ec2e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefast_forward .path3:before {
  content: "\ec2f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefast_forward .path4:before {
  content: "\ec30";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefast_rewind .path1:before {
  content: "\ec31";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefast_rewind .path2:before {
  content: "\ec32";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefastfood .path1:before {
  content: "\ec33";
  color: rgb(37, 44, 50);
}
.icon-twotonefastfood .path2:before {
  content: "\ec34";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefastfood .path3:before {
  content: "\ec35";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefavorite .path1:before {
  content: "\ec36";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefavorite .path2:before {
  content: "\ec37";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefavorite_border:before {
  content: "\ec38";
}
.icon-twotonefeatured_play_list .path1:before {
  content: "\ec39";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefeatured_play_list .path2:before {
  content: "\ec3a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefeatured_video .path1:before {
  content: "\ec3b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefeatured_video .path2:before {
  content: "\ec3c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefeedback .path1:before {
  content: "\ec3d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefeedback .path2:before {
  content: "\ec3e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefence .path1:before {
  content: "\ec3f";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonefence .path2:before {
  content: "\ec40";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_dvr .path1:before {
  content: "\ec41";
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_dvr .path2:before {
  content: "\ec42";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefiber_dvr .path3:before {
  content: "\ec43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_manual_record .path1:before {
  content: "\ec44";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefiber_manual_record .path2:before {
  content: "\ec45";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_new .path1:before {
  content: "\ec46";
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_new .path2:before {
  content: "\ec47";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefiber_new .path3:before {
  content: "\ec48";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_pin .path1:before {
  content: "\ec49";
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_pin .path2:before {
  content: "\ec4a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefiber_pin .path3:before {
  content: "\ec4b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefiber_smart_record .path1:before {
  content: "\ec4c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefiber_smart_record .path2:before {
  content: "\ec4d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefile_copy .path1:before {
  content: "\ec4e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefile_copy .path2:before {
  content: "\ec4f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter .path1:before {
  content: "\ec50";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter .path2:before {
  content: "\ec51";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_1 .path1:before {
  content: "\ec52";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_1 .path2:before {
  content: "\ec53";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_2 .path1:before {
  content: "\ec54";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_2 .path2:before {
  content: "\ec55";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_3 .path1:before {
  content: "\ec56";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_3 .path2:before {
  content: "\ec57";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_4 .path1:before {
  content: "\ec58";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_4 .path2:before {
  content: "\ec59";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_5 .path1:before {
  content: "\ec5a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_5 .path2:before {
  content: "\ec5b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_6 .path1:before {
  content: "\ec5c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_6 .path2:before {
  content: "\ec5d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_7 .path1:before {
  content: "\ec5e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_7 .path2:before {
  content: "\ec5f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_8 .path1:before {
  content: "\ec60";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_8 .path2:before {
  content: "\ec61";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_9 .path1:before {
  content: "\ec62";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_9 .path2:before {
  content: "\ec63";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_9_plus .path1:before {
  content: "\ec64";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_9_plus .path2:before {
  content: "\ec65";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_9_plus .path3:before {
  content: "\ec66";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_9_plus .path4:before {
  content: "\ec67";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_alt .path1:before {
  content: "\ec68";
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_alt .path2:before {
  content: "\ec69";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_b_and_w .path1:before {
  content: "\ec6a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_b_and_w .path2:before {
  content: "\ec6b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_center_focus:before {
  content: "\ec6c";
}
.icon-twotonefilter_drama .path1:before {
  content: "\ec6d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_drama .path2:before {
  content: "\ec6e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_frames .path1:before {
  content: "\ec6f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_frames .path2:before {
  content: "\ec70";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_hdr .path1:before {
  content: "\ec71";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_hdr .path2:before {
  content: "\ec72";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_list:before {
  content: "\ec73";
}
.icon-twotonefilter_none .path1:before {
  content: "\ec74";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_none .path2:before {
  content: "\ec75";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefilter_tilt_shift:before {
  content: "\ec76";
}
.icon-twotonefilter_vintage .path1:before {
  content: "\ec77";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefilter_vintage .path2:before {
  content: "\ec78";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefind_in_page .path1:before {
  content: "\ec79";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefind_in_page .path2:before {
  content: "\ec7a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefind_replace:before {
  content: "\ec7b";
}
.icon-twotonefingerprint:before {
  content: "\ec7c";
}
.icon-twotonefire_extinguisher:before {
  content: "\ec7d";
}
.icon-twotonefireplace .path1:before {
  content: "\ec7e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefireplace .path2:before {
  content: "\ec7f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefireplace .path3:before {
  content: "\ec80";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefirst_page:before {
  content: "\ec81";
}
.icon-twotonefitness_center:before {
  content: "\ec82";
}
.icon-twotoneflag .path1:before {
  content: "\ec83";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneflag .path2:before {
  content: "\ec84";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflaky:before {
  content: "\ec85";
}
.icon-twotoneflare:before {
  content: "\ec86";
}
.icon-twotoneflash_auto:before {
  content: "\ec87";
}
.icon-twotoneflash_off:before {
  content: "\ec88";
}
.icon-twotoneflash_on:before {
  content: "\ec89";
}
.icon-twotoneflight:before {
  content: "\ec8a";
}
.icon-twotoneflight_land:before {
  content: "\ec8b";
}
.icon-twotoneflight_takeoff:before {
  content: "\ec8c";
}
.icon-twotoneflip:before {
  content: "\ec8d";
}
.icon-twotoneflip_camera_android .path1:before {
  content: "\ec8e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneflip_camera_android .path2:before {
  content: "\ec8f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_camera_android .path3:before {
  content: "\ec90";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_camera_android .path4:before {
  content: "\ec91";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_camera_ios .path1:before {
  content: "\ec92";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneflip_camera_ios .path2:before {
  content: "\ec93";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_camera_ios .path3:before {
  content: "\ec94";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_camera_ios .path4:before {
  content: "\ec95";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneflip_to_back:before {
  content: "\ec96";
}
.icon-twotoneflip_to_front:before {
  content: "\ec97";
}
.icon-twotonefolder .path1:before {
  content: "\ec98";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefolder .path2:before {
  content: "\ec99";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefolder_open .path1:before {
  content: "\ec9a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefolder_open .path2:before {
  content: "\ec9b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefolder_shared .path1:before {
  content: "\ec9c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefolder_shared .path2:before {
  content: "\ec9d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefolder_special .path1:before {
  content: "\ec9e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefolder_special .path2:before {
  content: "\ec9f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefollow_the_signs .path1:before {
  content: "\eca0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefollow_the_signs .path2:before {
  content: "\eca1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefont_download .path1:before {
  content: "\eca2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefont_download .path2:before {
  content: "\eca3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefood_bank .path1:before {
  content: "\eca4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefood_bank .path2:before {
  content: "\eca5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_align_center:before {
  content: "\eca6";
}
.icon-twotoneformat_align_justify:before {
  content: "\eca7";
}
.icon-twotoneformat_align_left:before {
  content: "\eca8";
}
.icon-twotoneformat_align_right:before {
  content: "\eca9";
}
.icon-twotoneformat_bold:before {
  content: "\ecaa";
}
.icon-twotoneformat_clear:before {
  content: "\ecab";
}
.icon-twotoneformat_color_fill .path1:before {
  content: "\ecac";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_color_fill .path2:before {
  content: "\ecad";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_color_fill .path3:before {
  content: "\ecae";
  margin-left: -1em;
  color: rgb(50, 50, 50);
  opacity: 0.36;
}
.icon-twotoneformat_color_reset .path1:before {
  content: "\ecaf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_color_reset .path2:before {
  content: "\ecb0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_color_text .path1:before {
  content: "\ecb1";
  color: rgb(50, 50, 50);
  opacity: 0.36;
}
.icon-twotoneformat_color_text .path2:before {
  content: "\ecb2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_indent_decrease:before {
  content: "\ecb3";
}
.icon-twotoneformat_indent_increase:before {
  content: "\ecb4";
}
.icon-twotoneformat_italic:before {
  content: "\ecb5";
}
.icon-twotoneformat_line_spacing:before {
  content: "\ecb6";
}
.icon-twotoneformat_list_bulleted:before {
  content: "\ecb7";
}
.icon-twotoneformat_list_numbered:before {
  content: "\ecb8";
}
.icon-twotoneformat_list_numbered_rtl:before {
  content: "\ecb9";
}
.icon-twotoneformat_paint .path1:before {
  content: "\ecba";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_paint .path2:before {
  content: "\ecbb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_quote .path1:before {
  content: "\ecbc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_quote .path2:before {
  content: "\ecbd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_shapes .path1:before {
  content: "\ecbe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_shapes .path2:before {
  content: "\ecbf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_size:before {
  content: "\ecc0";
}
.icon-twotoneformat_strikethrough:before {
  content: "\ecc1";
}
.icon-twotoneformat_textdirection_l_to_r .path1:before {
  content: "\ecc2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_textdirection_l_to_r .path2:before {
  content: "\ecc3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_textdirection_r_to_l .path1:before {
  content: "\ecc4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneformat_textdirection_r_to_l .path2:before {
  content: "\ecc5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneformat_underlined:before {
  content: "\ecc6";
}
.icon-twotoneforum .path1:before {
  content: "\ecc7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneforum .path2:before {
  content: "\ecc8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneforward .path1:before {
  content: "\ecc9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneforward .path2:before {
  content: "\ecca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneforward_5:before {
  content: "\eccb";
}
.icon-twotoneforward_10:before {
  content: "\eccc";
}
.icon-twotoneforward_30:before {
  content: "\eccd";
}
.icon-twotoneforward_to_inbox .path1:before {
  content: "\ecce";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneforward_to_inbox .path2:before {
  content: "\eccf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefoundation .path1:before {
  content: "\ecd0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefoundation .path2:before {
  content: "\ecd1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefree_breakfast .path1:before {
  content: "\ecd2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonefree_breakfast .path2:before {
  content: "\ecd3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonefullscreen:before {
  content: "\ecd4";
}
.icon-twotonefullscreen_exit:before {
  content: "\ecd5";
}
.icon-twotonefunctions:before {
  content: "\ecd6";
}
.icon-twotoneg_translate:before {
  content: "\ecd7";
}
.icon-twotonegamepad .path1:before {
  content: "\ecd8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegamepad .path2:before {
  content: "\ecd9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegames .path1:before {
  content: "\ecda";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegames .path2:before {
  content: "\ecdb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegavel:before {
  content: "\ecdc";
}
.icon-twotonegesture:before {
  content: "\ecdd";
}
.icon-twotoneget_app .path1:before {
  content: "\ecde";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneget_app .path2:before {
  content: "\ecdf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegif:before {
  content: "\ece0";
}
.icon-twotonegolf_course .path1:before {
  content: "\ece1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegolf_course .path2:before {
  content: "\ece2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegolf_course .path3:before {
  content: "\ece3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegolf_course .path4:before {
  content: "\ece4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegps_fixed .path1:before {
  content: "\ece5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegps_fixed .path2:before {
  content: "\ece6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegps_not_fixed:before {
  content: "\ece7";
}
.icon-twotonegps_off:before {
  content: "\ece8";
}
.icon-twotonegrade .path1:before {
  content: "\ece9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegrade .path2:before {
  content: "\ecea";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegradient:before {
  content: "\eceb";
}
.icon-twotonegrading:before {
  content: "\ecec";
}
.icon-twotonegrain:before {
  content: "\eced";
}
.icon-twotonegraphic_eq:before {
  content: "\ecee";
}
.icon-twotonegrass:before {
  content: "\ecef";
}
.icon-twotonegrid_off .path1:before {
  content: "\ecf0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegrid_off .path2:before {
  content: "\ecf1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegrid_on .path1:before {
  content: "\ecf2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegrid_on .path2:before {
  content: "\ecf3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup .path1:before {
  content: "\ecf4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegroup .path2:before {
  content: "\ecf5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegroup .path3:before {
  content: "\ecf6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_add .path1:before {
  content: "\ecf7";
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_add .path2:before {
  content: "\ecf8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegroup_add .path3:before {
  content: "\ecf9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegroup_add .path4:before {
  content: "\ecfa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_work .path1:before {
  content: "\ecfb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonegroup_work .path2:before {
  content: "\ecfc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_work .path3:before {
  content: "\ecfd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_work .path4:before {
  content: "\ecfe";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroup_work .path5:before {
  content: "\ecff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonegroups .path1:before {
  content: "\ed00";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonegroups .path2:before {
  content: "\ed01";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehandyman .path1:before {
  content: "\ed02";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehandyman .path2:before {
  content: "\ed03";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehandyman .path3:before {
  content: "\ed04";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehandyman .path4:before {
  content: "\ed05";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehd .path1:before {
  content: "\ed06";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehd .path2:before {
  content: "\ed07";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehdr_off:before {
  content: "\ed08";
}
.icon-twotonehdr_on:before {
  content: "\ed09";
}
.icon-twotonehdr_strong .path1:before {
  content: "\ed0a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehdr_strong .path2:before {
  content: "\ed0b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehdr_weak .path1:before {
  content: "\ed0c";
  color: rgb(37, 44, 50);
}
.icon-twotonehdr_weak .path2:before {
  content: "\ed0d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehdr_weak .path3:before {
  content: "\ed0e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneheadset .path1:before {
  content: "\ed0f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneheadset .path2:before {
  content: "\ed10";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneheadset_mic .path1:before {
  content: "\ed11";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneheadset_mic .path2:before {
  content: "\ed12";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehealing .path1:before {
  content: "\ed13";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehealing .path2:before {
  content: "\ed14";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehearing:before {
  content: "\ed15";
}
.icon-twotonehearing_disabled:before {
  content: "\ed16";
}
.icon-twotoneheight:before {
  content: "\ed17";
}
.icon-twotonehelp .path1:before {
  content: "\ed18";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehelp .path2:before {
  content: "\ed19";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehelp_center .path1:before {
  content: "\ed1a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehelp_center .path2:before {
  content: "\ed1b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehelp_outline:before {
  content: "\ed1c";
}
.icon-twotonehigh_quality .path1:before {
  content: "\ed1d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehigh_quality .path2:before {
  content: "\ed1e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehighlight .path1:before {
  content: "\ed1f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehighlight .path2:before {
  content: "\ed20";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehighlight_alt:before {
  content: "\ed21";
}
.icon-twotonehighlight_off .path1:before {
  content: "\ed22";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehighlight_off .path2:before {
  content: "\ed23";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehistory:before {
  content: "\ed24";
}
.icon-twotonehistory_edu .path1:before {
  content: "\ed25";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehistory_edu .path2:before {
  content: "\ed26";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehistory_edu .path3:before {
  content: "\ed27";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehistory_toggle_off:before {
  content: "\ed28";
}
.icon-twotonehome .path1:before {
  content: "\ed29";
  color: rgb(37, 44, 50);
}
.icon-twotonehome .path2:before {
  content: "\ed2a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehome_repair_service .path1:before {
  content: "\ed2b";
  color: rgb(37, 44, 50);
}
.icon-twotonehome_repair_service .path2:before {
  content: "\ed2c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehome_repair_service .path3:before {
  content: "\ed2d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonehome_work .path1:before {
  content: "\ed2e";
  color: rgb(37, 44, 50);
}
.icon-twotonehome_work .path2:before {
  content: "\ed2f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehome_work .path3:before {
  content: "\ed30";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehome_work .path4:before {
  content: "\ed31";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehorizontal_rule:before {
  content: "\ed32";
}
.icon-twotonehorizontal_split .path1:before {
  content: "\ed33";
  color: rgb(37, 44, 50);
}
.icon-twotonehorizontal_split .path2:before {
  content: "\ed34";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehot_tub:before {
  content: "\ed35";
}
.icon-twotonehotel .path1:before {
  content: "\ed36";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehotel .path2:before {
  content: "\ed37";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehotel .path3:before {
  content: "\ed38";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehourglass_bottom .path1:before {
  content: "\ed39";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehourglass_bottom .path2:before {
  content: "\ed3a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehourglass_disabled:before {
  content: "\ed3b";
}
.icon-twotonehourglass_empty:before {
  content: "\ed3c";
}
.icon-twotonehourglass_full .path1:before {
  content: "\ed3d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehourglass_full .path2:before {
  content: "\ed3e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehourglass_top .path1:before {
  content: "\ed3f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehourglass_top .path2:before {
  content: "\ed40";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehouse .path1:before {
  content: "\ed41";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehouse .path2:before {
  content: "\ed42";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehouse .path3:before {
  content: "\ed43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehouse_siding .path1:before {
  content: "\ed44";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehouse_siding .path2:before {
  content: "\ed45";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehow_to_reg .path1:before {
  content: "\ed46";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehow_to_reg .path2:before {
  content: "\ed47";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehow_to_reg .path3:before {
  content: "\ed48";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehow_to_vote .path1:before {
  content: "\ed49";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehow_to_vote .path2:before {
  content: "\ed4a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehow_to_vote .path3:before {
  content: "\ed4b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehow_to_vote .path4:before {
  content: "\ed4c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehttp:before {
  content: "\ed4d";
}
.icon-twotonehttps .path1:before {
  content: "\ed4e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehttps .path2:before {
  content: "\ed4f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehvac .path1:before {
  content: "\ed50";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonehvac .path2:before {
  content: "\ed51";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonehvac .path3:before {
  content: "\ed52";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneimage .path1:before {
  content: "\ed53";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimage .path2:before {
  content: "\ed54";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneimage_aspect_ratio .path1:before {
  content: "\ed55";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimage_aspect_ratio .path2:before {
  content: "\ed56";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneimage_not_supported .path1:before {
  content: "\ed57";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimage_not_supported .path2:before {
  content: "\ed58";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneimage_search .path1:before {
  content: "\ed59";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimage_search .path2:before {
  content: "\ed5a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneimport_contacts .path1:before {
  content: "\ed5b";
  color: rgb(37, 44, 50);
}
.icon-twotoneimport_contacts .path2:before {
  content: "\ed5c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimport_export:before {
  content: "\ed5d";
}
.icon-twotoneimportant_devices .path1:before {
  content: "\ed5e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneimportant_devices .path2:before {
  content: "\ed5f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinbox .path1:before {
  content: "\ed60";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinbox .path2:before {
  content: "\ed61";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneindeterminate_check_box .path1:before {
  content: "\ed62";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneindeterminate_check_box .path2:before {
  content: "\ed63";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinfo .path1:before {
  content: "\ed64";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinfo .path2:before {
  content: "\ed65";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinfo_outline:before {
  content: "\ed66";
}
.icon-twotoneinput:before {
  content: "\ed67";
}
.icon-twotoneinsert_chart .path1:before {
  content: "\ed68";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_chart .path2:before {
  content: "\ed69";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_chart_outlined:before {
  content: "\ed6a";
}
.icon-twotoneinsert_comment .path1:before {
  content: "\ed6b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_comment .path2:before {
  content: "\ed6c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_drive_file .path1:before {
  content: "\ed6d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_drive_file .path2:before {
  content: "\ed6e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_emoticon .path1:before {
  content: "\ed6f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_emoticon .path2:before {
  content: "\ed70";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_emoticon .path3:before {
  content: "\ed71";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_emoticon .path4:before {
  content: "\ed72";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_emoticon .path5:before {
  content: "\ed73";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_invitation .path1:before {
  content: "\ed74";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_invitation .path2:before {
  content: "\ed75";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsert_link:before {
  content: "\ed76";
}
.icon-twotoneinsert_photo .path1:before {
  content: "\ed77";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinsert_photo .path2:before {
  content: "\ed78";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinsights:before {
  content: "\ed79";
}
.icon-twotoneintegration_instructions .path1:before {
  content: "\ed7a";
  color: rgb(37, 44, 50);
}
.icon-twotoneintegration_instructions .path2:before {
  content: "\ed7b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneintegration_instructions .path3:before {
  content: "\ed7c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneintegration_instructions .path4:before {
  content: "\ed7d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinvert_colors .path1:before {
  content: "\ed7e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinvert_colors .path2:before {
  content: "\ed7f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinvert_colors_off .path1:before {
  content: "\ed80";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneinvert_colors_off .path2:before {
  content: "\ed81";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneinvert_colors_off .path3:before {
  content: "\ed82";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneiso .path1:before {
  content: "\ed83";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneiso .path2:before {
  content: "\ed84";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonekeyboard .path1:before {
  content: "\ed85";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonekeyboard .path2:before {
  content: "\ed86";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonekeyboard_arrow_down:before {
  content: "\ed87";
}
.icon-twotonekeyboard_arrow_left:before {
  content: "\ed88";
}
.icon-twotonekeyboard_arrow_right:before {
  content: "\ed89";
}
.icon-twotonekeyboard_arrow_up:before {
  content: "\ed8a";
}
.icon-twotonekeyboard_backspace:before {
  content: "\ed8b";
}
.icon-twotonekeyboard_capslock:before {
  content: "\ed8c";
}
.icon-twotonekeyboard_hide .path1:before {
  content: "\ed8d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonekeyboard_hide .path2:before {
  content: "\ed8e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonekeyboard_return:before {
  content: "\ed8f";
}
.icon-twotonekeyboard_tab:before {
  content: "\ed90";
}
.icon-twotonekeyboard_voice .path1:before {
  content: "\ed91";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonekeyboard_voice .path2:before {
  content: "\ed92";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneking_bed .path1:before {
  content: "\ed93";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneking_bed .path2:before {
  content: "\ed94";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonekitchen .path1:before {
  content: "\ed95";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonekitchen .path2:before {
  content: "\ed96";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelabel .path1:before {
  content: "\ed97";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelabel .path2:before {
  content: "\ed98";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelabel_important .path1:before {
  content: "\ed99";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelabel_important .path2:before {
  content: "\ed9a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelabel_important_outline:before {
  content: "\ed9b";
}
.icon-twotonelabel_off .path1:before {
  content: "\ed9c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelabel_off .path2:before {
  content: "\ed9d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelabel_outline:before {
  content: "\ed9e";
}
.icon-twotonelandscape .path1:before {
  content: "\ed9f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelandscape .path2:before {
  content: "\eda0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelanguage .path1:before {
  content: "\eda1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelanguage .path2:before {
  content: "\eda2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelaptop .path1:before {
  content: "\eda3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelaptop .path2:before {
  content: "\eda4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelaptop_chromebook .path1:before {
  content: "\eda5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelaptop_chromebook .path2:before {
  content: "\eda6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelaptop_mac .path1:before {
  content: "\eda7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelaptop_mac .path2:before {
  content: "\eda8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelaptop_windows .path1:before {
  content: "\eda9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelaptop_windows .path2:before {
  content: "\edaa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelast_page:before {
  content: "\edab";
}
.icon-twotonelaunch:before {
  content: "\edac";
}
.icon-twotonelayers .path1:before {
  content: "\edad";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelayers .path2:before {
  content: "\edae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelayers_clear .path1:before {
  content: "\edaf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelayers_clear .path2:before {
  content: "\edb0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneleaderboard .path1:before {
  content: "\edb1";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotoneleaderboard .path2:before {
  content: "\edb2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneleak_add:before {
  content: "\edb3";
}
.icon-twotoneleak_remove:before {
  content: "\edb4";
}
.icon-twotonelegend_toggle:before {
  content: "\edb5";
}
.icon-twotonelens .path1:before {
  content: "\edb6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelens .path2:before {
  content: "\edb7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelibrary_add .path1:before {
  content: "\edb8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelibrary_add .path2:before {
  content: "\edb9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelibrary_add_check .path1:before {
  content: "\edba";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelibrary_add_check .path2:before {
  content: "\edbb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelibrary_books .path1:before {
  content: "\edbc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelibrary_books .path2:before {
  content: "\edbd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelibrary_music .path1:before {
  content: "\edbe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelibrary_music .path2:before {
  content: "\edbf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelightbulb_outline:before {
  content: "\edc0";
}
.icon-twotoneline_style:before {
  content: "\edc1";
}
.icon-twotoneline_weight:before {
  content: "\edc2";
}
.icon-twotonelinear_scale:before {
  content: "\edc3";
}
.icon-twotonelink:before {
  content: "\edc4";
}
.icon-twotonelink_off:before {
  content: "\edc5";
}
.icon-twotonelinked_camera .path1:before {
  content: "\edc6";
  color: rgb(37, 44, 50);
}
.icon-twotonelinked_camera .path2:before {
  content: "\edc7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelinked_camera .path3:before {
  content: "\edc8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelist:before {
  content: "\edc9";
}
.icon-twotonelist_alt .path1:before {
  content: "\edca";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelist_alt .path2:before {
  content: "\edcb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelive_help .path1:before {
  content: "\edcc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelive_help .path2:before {
  content: "\edcd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelive_tv .path1:before {
  content: "\edce";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelive_tv .path2:before {
  content: "\edcf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_activity .path1:before {
  content: "\edd0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_activity .path2:before {
  content: "\edd1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_airport:before {
  content: "\edd2";
}
.icon-twotonelocal_atm .path1:before {
  content: "\edd3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_atm .path2:before {
  content: "\edd4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_bar .path1:before {
  content: "\edd5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_bar .path2:before {
  content: "\edd6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_cafe .path1:before {
  content: "\edd7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_cafe .path2:before {
  content: "\edd8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_car_wash .path1:before {
  content: "\edd9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_car_wash .path2:before {
  content: "\edda";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_car_wash .path3:before {
  content: "\eddb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_car_wash .path4:before {
  content: "\eddc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_convenience_store .path1:before {
  content: "\eddd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_convenience_store .path2:before {
  content: "\edde";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_dining:before {
  content: "\eddf";
}
.icon-twotonelocal_drink .path1:before {
  content: "\ede0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_drink .path2:before {
  content: "\ede1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_fire_department .path1:before {
  content: "\ede2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_fire_department .path2:before {
  content: "\ede3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_florist .path1:before {
  content: "\ede4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_florist .path2:before {
  content: "\ede5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_gas_station .path1:before {
  content: "\ede6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_gas_station .path2:before {
  content: "\ede7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_grocery_store .path1:before {
  content: "\ede8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_grocery_store .path2:before {
  content: "\ede9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_hospital .path1:before {
  content: "\edea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_hospital .path2:before {
  content: "\edeb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_hotel .path1:before {
  content: "\edec";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_hotel .path2:before {
  content: "\eded";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_hotel .path3:before {
  content: "\edee";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_laundry_service .path1:before {
  content: "\edef";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_laundry_service .path2:before {
  content: "\edf0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_laundry_service .path3:before {
  content: "\edf1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_laundry_service .path4:before {
  content: "\edf2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_laundry_service .path5:before {
  content: "\edf3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_library .path1:before {
  content: "\edf4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_library .path2:before {
  content: "\edf5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_library .path3:before {
  content: "\edf6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_mall .path1:before {
  content: "\edf7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_mall .path2:before {
  content: "\edf8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_movies .path1:before {
  content: "\edf9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_movies .path2:before {
  content: "\edfa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_offer .path1:before {
  content: "\edfb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_offer .path2:before {
  content: "\edfc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_offer .path3:before {
  content: "\edfd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_parking:before {
  content: "\edfe";
}
.icon-twotonelocal_pharmacy .path1:before {
  content: "\edff";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_pharmacy .path2:before {
  content: "\ee00";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_phone .path1:before {
  content: "\ee01";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_phone .path2:before {
  content: "\ee02";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_pizza .path1:before {
  content: "\ee03";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_pizza .path2:before {
  content: "\ee04";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_play .path1:before {
  content: "\ee05";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_play .path2:before {
  content: "\ee06";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_police .path1:before {
  content: "\ee07";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_police .path2:before {
  content: "\ee08";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_post_office .path1:before {
  content: "\ee09";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_post_office .path2:before {
  content: "\ee0a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_printshop .path1:before {
  content: "\ee0b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_printshop .path2:before {
  content: "\ee0c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_printshop .path3:before {
  content: "\ee0d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_see .path1:before {
  content: "\ee0e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_see .path2:before {
  content: "\ee0f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_shipping .path1:before {
  content: "\ee10";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_shipping .path2:before {
  content: "\ee11";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_taxi .path1:before {
  content: "\ee12";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocal_taxi .path2:before {
  content: "\ee13";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_taxi .path3:before {
  content: "\ee14";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocal_taxi .path4:before {
  content: "\ee15";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocation_city:before {
  content: "\ee16";
}
.icon-twotonelocation_disabled:before {
  content: "\ee17";
}
.icon-twotonelocation_off:before {
  content: "\ee18";
}
.icon-twotonelocation_on .path1:before {
  content: "\ee19";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelocation_on .path2:before {
  content: "\ee1a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocation_on .path3:before {
  content: "\ee1b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelocation_searching:before {
  content: "\ee1c";
}
.icon-twotonelock .path1:before {
  content: "\ee1d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelock .path2:before {
  content: "\ee1e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelock_open .path1:before {
  content: "\ee1f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelock_open .path2:before {
  content: "\ee20";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelock_outline:before {
  content: "\ee21";
}
.icon-twotonelogin:before {
  content: "\ee22";
}
.icon-twotonelooks:before {
  content: "\ee23";
}
.icon-twotonelooks_3 .path1:before {
  content: "\ee24";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_3 .path2:before {
  content: "\ee25";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelooks_4 .path1:before {
  content: "\ee26";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_4 .path2:before {
  content: "\ee27";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelooks_5 .path1:before {
  content: "\ee28";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_5 .path2:before {
  content: "\ee29";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelooks_6 .path1:before {
  content: "\ee2a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_6 .path2:before {
  content: "\ee2b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelooks_one .path1:before {
  content: "\ee2c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_one .path2:before {
  content: "\ee2d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelooks_two .path1:before {
  content: "\ee2e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonelooks_two .path2:before {
  content: "\ee2f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneloop:before {
  content: "\ee30";
}
.icon-twotoneloupe .path1:before {
  content: "\ee31";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneloupe .path2:before {
  content: "\ee32";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonelow_priority:before {
  content: "\ee33";
}
.icon-twotoneloyalty .path1:before {
  content: "\ee34";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneloyalty .path2:before {
  content: "\ee35";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneloyalty .path3:before {
  content: "\ee36";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneloyalty .path4:before {
  content: "\ee37";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneluggage .path1:before {
  content: "\ee38";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotoneluggage .path2:before {
  content: "\ee39";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemail .path1:before {
  content: "\ee3a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemail .path2:before {
  content: "\ee3b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemail_outline:before {
  content: "\ee3c";
}
.icon-twotonemap .path1:before {
  content: "\ee3d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemap .path2:before {
  content: "\ee3e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemaps_ugc .path1:before {
  content: "\ee3f";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotonemaps_ugc .path2:before {
  content: "\ee40";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemaps_ugc .path3:before {
  content: "\ee41";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemark_chat_read .path1:before {
  content: "\ee42";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemark_chat_read .path2:before {
  content: "\ee43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemark_chat_unread .path1:before {
  content: "\ee44";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemark_chat_unread .path2:before {
  content: "\ee45";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemark_email_read .path1:before {
  content: "\ee46";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemark_email_read .path2:before {
  content: "\ee47";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemark_email_unread .path1:before {
  content: "\ee48";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemark_email_unread .path2:before {
  content: "\ee49";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemarkunread .path1:before {
  content: "\ee4a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemarkunread .path2:before {
  content: "\ee4b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemarkunread_mailbox .path1:before {
  content: "\ee4c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemarkunread_mailbox .path2:before {
  content: "\ee4d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemasks .path1:before {
  content: "\ee4e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemasks .path2:before {
  content: "\ee4f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemaximize:before {
  content: "\ee50";
}
.icon-twotonemediation:before {
  content: "\ee51";
}
.icon-twotonemedical_services .path1:before {
  content: "\ee52";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonemedical_services .path2:before {
  content: "\ee53";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemedical_services .path3:before {
  content: "\ee54";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemeeting_room .path1:before {
  content: "\ee55";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemeeting_room .path2:before {
  content: "\ee56";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonememory .path1:before {
  content: "\ee57";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonememory .path2:before {
  content: "\ee58";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemenu:before {
  content: "\ee59";
}
.icon-twotonemenu_book .path1:before {
  content: "\ee5a";
  color: rgb(37, 44, 50);
}
.icon-twotonemenu_book .path2:before {
  content: "\ee5b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemenu_book .path3:before {
  content: "\ee5c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemenu_book .path4:before {
  content: "\ee5d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemenu_book .path5:before {
  content: "\ee5e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemenu_open:before {
  content: "\ee5f";
}
.icon-twotonemerge_type:before {
  content: "\ee60";
}
.icon-twotonemessage .path1:before {
  content: "\ee61";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemessage .path2:before {
  content: "\ee62";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemic .path1:before {
  content: "\ee63";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemic .path2:before {
  content: "\ee64";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemic .path3:before {
  content: "\ee65";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemic_none .path1:before {
  content: "\ee66";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemic_none .path2:before {
  content: "\ee67";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemic_off .path1:before {
  content: "\ee68";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemic_off .path2:before {
  content: "\ee69";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemicrowave .path1:before {
  content: "\ee6a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemicrowave .path2:before {
  content: "\ee6b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemilitary_tech .path1:before {
  content: "\ee6c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemilitary_tech .path2:before {
  content: "\ee6d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemilitary_tech .path3:before {
  content: "\ee6e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneminimize:before {
  content: "\ee6f";
}
.icon-twotonemiscellaneous_services:before {
  content: "\ee70";
}
.icon-twotonemissed_video_call .path1:before {
  content: "\ee71";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemissed_video_call .path2:before {
  content: "\ee72";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemms .path1:before {
  content: "\ee73";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemms .path2:before {
  content: "\ee74";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemobile_friendly:before {
  content: "\ee75";
}
.icon-twotonemobile_off:before {
  content: "\ee76";
}
.icon-twotonemobile_screen_share .path1:before {
  content: "\ee77";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemobile_screen_share .path2:before {
  content: "\ee78";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemode .path1:before {
  content: "\ee79";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemode .path2:before {
  content: "\ee7a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemode_comment .path1:before {
  content: "\ee7b";
  color: rgb(37, 44, 50);
}
.icon-twotonemode_comment .path2:before {
  content: "\ee7c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemodel_training:before {
  content: "\ee7d";
}
.icon-twotonemonetization_on .path1:before {
  content: "\ee7e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemonetization_on .path2:before {
  content: "\ee7f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemoney .path1:before {
  content: "\ee80";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemoney .path2:before {
  content: "\ee81";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemoney_off:before {
  content: "\ee82";
}
.icon-twotonemoney_off_csred:before {
  content: "\ee83";
}
.icon-twotonemonochrome_photos .path1:before {
  content: "\ee84";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemonochrome_photos .path2:before {
  content: "\ee85";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood .path1:before {
  content: "\ee86";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemood .path2:before {
  content: "\ee87";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood .path3:before {
  content: "\ee88";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood .path4:before {
  content: "\ee89";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood .path5:before {
  content: "\ee8a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood_bad .path1:before {
  content: "\ee8b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemood_bad .path2:before {
  content: "\ee8c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood_bad .path3:before {
  content: "\ee8d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemood_bad .path4:before {
  content: "\ee8e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemoped .path1:before {
  content: "\ee8f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemoped .path2:before {
  content: "\ee90";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemoped .path3:before {
  content: "\ee91";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemoped .path4:before {
  content: "\ee92";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemore .path1:before {
  content: "\ee93";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemore .path2:before {
  content: "\ee94";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemore .path3:before {
  content: "\ee95";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemore .path4:before {
  content: "\ee96";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemore .path5:before {
  content: "\ee97";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemore_horiz:before {
  content: "\ee98";
}
.icon-twotonemore_time:before {
  content: "\ee99";
}
.icon-twotonemore_vert:before {
  content: "\ee9a";
}
.icon-twotonemotion_photos_on:before {
  content: "\ee9b";
}
.icon-twotonemotion_photos_pause:before {
  content: "\ee9c";
}
.icon-twotonemotion_photos_paused:before {
  content: "\ee9d";
}
.icon-twotonemouse .path1:before {
  content: "\ee9e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemouse .path2:before {
  content: "\ee9f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemove_to_inbox .path1:before {
  content: "\eea0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemove_to_inbox .path2:before {
  content: "\eea1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemovie .path1:before {
  content: "\eea2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemovie .path2:before {
  content: "\eea3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemovie_creation .path1:before {
  content: "\eea4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemovie_creation .path2:before {
  content: "\eea5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemovie_filter .path1:before {
  content: "\eea6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemovie_filter .path2:before {
  content: "\eea7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemultiline_chart:before {
  content: "\eea8";
}
.icon-twotonemultiple_stop:before {
  content: "\eea9";
}
.icon-twotonemuseum .path1:before {
  content: "\eeaa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemuseum .path2:before {
  content: "\eeab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemuseum .path3:before {
  content: "\eeac";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemusic_note .path1:before {
  content: "\eead";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemusic_note .path2:before {
  content: "\eeae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemusic_off .path1:before {
  content: "\eeaf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemusic_off .path2:before {
  content: "\eeb0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemusic_video .path1:before {
  content: "\eeb1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemusic_video .path2:before {
  content: "\eeb2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonemy_location .path1:before {
  content: "\eeb3";
  color: rgb(37, 44, 50);
}
.icon-twotonemy_location .path2:before {
  content: "\eeb4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonemy_location .path3:before {
  content: "\eeb5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenat .path1:before {
  content: "\eeb6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenat .path2:before {
  content: "\eeb7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenat .path3:before {
  content: "\eeb8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenature .path1:before {
  content: "\eeb9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenature .path2:before {
  content: "\eeba";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenature_people .path1:before {
  content: "\eebb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenature_people .path2:before {
  content: "\eebc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenature_people .path3:before {
  content: "\eebd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenavigate_before:before {
  content: "\eebe";
}
.icon-twotonenavigate_next:before {
  content: "\eebf";
}
.icon-twotonenavigation .path1:before {
  content: "\eec0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenavigation .path2:before {
  content: "\eec1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenear_me .path1:before {
  content: "\eec2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenear_me .path2:before {
  content: "\eec3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenear_me_disabled .path1:before {
  content: "\eec4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenear_me_disabled .path2:before {
  content: "\eec5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenetwork_cell .path1:before {
  content: "\eec6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenetwork_cell .path2:before {
  content: "\eec7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenetwork_check:before {
  content: "\eec8";
}
.icon-twotonenetwork_locked:before {
  content: "\eec9";
}
.icon-twotonenetwork_wifi .path1:before {
  content: "\eeca";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenetwork_wifi .path2:before {
  content: "\eecb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenew_releases .path1:before {
  content: "\eecc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenew_releases .path2:before {
  content: "\eecd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenext_plan .path1:before {
  content: "\eece";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenext_plan .path2:before {
  content: "\eecf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenext_plan .path3:before {
  content: "\eed0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenext_week .path1:before {
  content: "\eed1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenext_week .path2:before {
  content: "\eed2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenfc:before {
  content: "\eed3";
}
.icon-twotonenight_shelter .path1:before {
  content: "\eed4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenight_shelter .path2:before {
  content: "\eed5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenights_stay .path1:before {
  content: "\eed6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenights_stay .path2:before {
  content: "\eed7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenights_stay .path3:before {
  content: "\eed8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_backpack .path1:before {
  content: "\eed9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_backpack .path2:before {
  content: "\eeda";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_cell .path1:before {
  content: "\eedb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_cell .path2:before {
  content: "\eedc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_drinks .path1:before {
  content: "\eedd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_drinks .path2:before {
  content: "\eede";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_encryption .path1:before {
  content: "\eedf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_encryption .path2:before {
  content: "\eee0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_encryption_gmailerrorred .path1:before {
  content: "\eee1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_encryption_gmailerrorred .path2:before {
  content: "\eee2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_flash .path1:before {
  content: "\eee3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_flash .path2:before {
  content: "\eee4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_food .path1:before {
  content: "\eee5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_food .path2:before {
  content: "\eee6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_luggage .path1:before {
  content: "\eee7";
  color: rgb(37, 44, 50);
  opacity: 0.0270;
}
.icon-twotoneno_luggage .path2:before {
  content: "\eee8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_meals:before {
  content: "\eee9";
}
.icon-twotoneno_meeting_room .path1:before {
  content: "\eeea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_meeting_room .path2:before {
  content: "\eeeb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_photography .path1:before {
  content: "\eeec";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_photography .path2:before {
  content: "\eeed";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_sim .path1:before {
  content: "\eeee";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_sim .path2:before {
  content: "\eeef";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_sim .path3:before {
  content: "\eef0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_sim .path4:before {
  content: "\eef1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_stroller .path1:before {
  content: "\eef2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_stroller .path2:before {
  content: "\eef3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneno_transfer .path1:before {
  content: "\eef4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneno_transfer .path2:before {
  content: "\eef5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenorth:before {
  content: "\eef6";
}
.icon-twotonenorth_east:before {
  content: "\eef7";
}
.icon-twotonenorth_west:before {
  content: "\eef8";
}
.icon-twotonenot_accessible:before {
  content: "\eef9";
}
.icon-twotonenot_interested:before {
  content: "\eefa";
}
.icon-twotonenot_listed_location .path1:before {
  content: "\eefb";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotonenot_listed_location .path2:before {
  content: "\eefc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenot_started .path1:before {
  content: "\eefd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenot_started .path2:before {
  content: "\eefe";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenote .path1:before {
  content: "\eeff";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenote .path2:before {
  content: "\ef00";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenote_add .path1:before {
  content: "\ef01";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenote_add .path2:before {
  content: "\ef02";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotes:before {
  content: "\ef03";
}
.icon-twotonenotification_important .path1:before {
  content: "\ef04";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotification_important .path2:before {
  content: "\ef05";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotifications .path1:before {
  content: "\ef06";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotifications .path2:before {
  content: "\ef07";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotifications_active .path1:before {
  content: "\ef08";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotifications_active .path2:before {
  content: "\ef09";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotifications_none .path1:before {
  content: "\ef0a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotifications_none .path2:before {
  content: "\ef0b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotifications_off .path1:before {
  content: "\ef0c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotifications_off .path2:before {
  content: "\ef0d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonenotifications_paused .path1:before {
  content: "\ef0e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonenotifications_paused .path2:before {
  content: "\ef0f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoffline_bolt .path1:before {
  content: "\ef10";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoffline_bolt .path2:before {
  content: "\ef11";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoffline_pin .path1:before {
  content: "\ef12";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoffline_pin .path2:before {
  content: "\ef13";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneondemand_video .path1:before {
  content: "\ef14";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneondemand_video .path2:before {
  content: "\ef15";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneonline_prediction:before {
  content: "\ef16";
}
.icon-twotoneopacity .path1:before {
  content: "\ef17";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneopacity .path2:before {
  content: "\ef18";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneopen_in_browser:before {
  content: "\ef19";
}
.icon-twotoneopen_in_full:before {
  content: "\ef1a";
}
.icon-twotoneopen_in_new:before {
  content: "\ef1b";
}
.icon-twotoneopen_with:before {
  content: "\ef1c";
}
.icon-twotoneoutbond .path1:before {
  content: "\ef1d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoutbond .path2:before {
  content: "\ef1e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutdoor_grill .path1:before {
  content: "\ef1f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoutdoor_grill .path2:before {
  content: "\ef20";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutdoor_grill .path3:before {
  content: "\ef21";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoutdoor_grill .path4:before {
  content: "\ef22";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutdoor_grill .path5:before {
  content: "\ef23";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutdoor_grill .path6:before {
  content: "\ef24";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutlet .path1:before {
  content: "\ef25";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneoutlet .path2:before {
  content: "\ef26";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneoutlined_flag:before {
  content: "\ef27";
}
.icon-twotonepages .path1:before {
  content: "\ef28";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepages .path2:before {
  content: "\ef29";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepageview .path1:before {
  content: "\ef2a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepageview .path2:before {
  content: "\ef2b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepalette .path1:before {
  content: "\ef2c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepalette .path2:before {
  content: "\ef2d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepalette .path3:before {
  content: "\ef2e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepalette .path4:before {
  content: "\ef2f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepalette .path5:before {
  content: "\ef30";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepalette .path6:before {
  content: "\ef31";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepan_tool .path1:before {
  content: "\ef32";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepan_tool .path2:before {
  content: "\ef33";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepanorama .path1:before {
  content: "\ef34";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepanorama .path2:before {
  content: "\ef35";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepanorama_fish_eye .path1:before {
  content: "\ef36";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepanorama_fish_eye .path2:before {
  content: "\ef37";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepanorama_horizontal .path1:before {
  content: "\ef38";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepanorama_horizontal .path2:before {
  content: "\ef39";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepanorama_vertical .path1:before {
  content: "\ef3a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepanorama_vertical .path2:before {
  content: "\ef3b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepanorama_wide_angle .path1:before {
  content: "\ef3c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepanorama_wide_angle .path2:before {
  content: "\ef3d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneparty_mode .path1:before {
  content: "\ef3e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneparty_mode .path2:before {
  content: "\ef3f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepause:before {
  content: "\ef40";
}
.icon-twotonepause_circle_filled .path1:before {
  content: "\ef41";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepause_circle_filled .path2:before {
  content: "\ef42";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepause_circle_filled .path3:before {
  content: "\ef43";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepause_circle_filled .path4:before {
  content: "\ef44";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepause_circle_outline:before {
  content: "\ef45";
}
.icon-twotonepause_presentation .path1:before {
  content: "\ef46";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepause_presentation .path2:before {
  content: "\ef47";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepayment .path1:before {
  content: "\ef48";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepayment .path2:before {
  content: "\ef49";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepayments .path1:before {
  content: "\ef4a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepayments .path2:before {
  content: "\ef4b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepayments .path3:before {
  content: "\ef4c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepayments .path4:before {
  content: "\ef4d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepedal_bike:before {
  content: "\ef4e";
}
.icon-twotonepending .path1:before {
  content: "\ef4f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepending .path2:before {
  content: "\ef50";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepending .path3:before {
  content: "\ef51";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepending .path4:before {
  content: "\ef52";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepending .path5:before {
  content: "\ef53";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepending_actions .path1:before {
  content: "\ef54";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepending_actions .path2:before {
  content: "\ef55";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople .path1:before {
  content: "\ef56";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople .path2:before {
  content: "\ef57";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople .path3:before {
  content: "\ef58";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople_alt .path1:before {
  content: "\ef59";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople_alt .path2:before {
  content: "\ef5a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople_alt .path3:before {
  content: "\ef5b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople_alt .path4:before {
  content: "\ef5c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople_alt .path5:before {
  content: "\ef5d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople_alt .path6:before {
  content: "\ef5e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepeople_outline .path1:before {
  content: "\ef5f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople_outline .path2:before {
  content: "\ef60";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepeople_outline .path3:before {
  content: "\ef61";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_camera_mic .path1:before {
  content: "\ef62";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_camera_mic .path2:before {
  content: "\ef63";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_contact_calendar .path1:before {
  content: "\ef64";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_contact_calendar .path2:before {
  content: "\ef65";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_data_setting:before {
  content: "\ef66";
}
.icon-twotoneperm_device_information .path1:before {
  content: "\ef67";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_device_information .path2:before {
  content: "\ef68";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_identity .path1:before {
  content: "\ef69";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_identity .path2:before {
  content: "\ef6a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_identity .path3:before {
  content: "\ef6b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_media .path1:before {
  content: "\ef6c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_media .path2:before {
  content: "\ef6d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_phone_msg .path1:before {
  content: "\ef6e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_phone_msg .path2:before {
  content: "\ef6f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperm_scan_wifi .path1:before {
  content: "\ef70";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperm_scan_wifi .path2:before {
  content: "\ef71";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson .path1:before {
  content: "\ef72";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson .path2:before {
  content: "\ef73";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson .path3:before {
  content: "\ef74";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_add .path1:before {
  content: "\ef75";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_add .path2:before {
  content: "\ef76";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_add .path3:before {
  content: "\ef77";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_add_alt_1 .path1:before {
  content: "\ef78";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_add_alt_1 .path2:before {
  content: "\ef79";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_add_alt_1 .path3:before {
  content: "\ef7a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_add_alt_1 .path4:before {
  content: "\ef7b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_add_alt_1 .path5:before {
  content: "\ef7c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_add_disabled .path1:before {
  content: "\ef7d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_add_disabled .path2:before {
  content: "\ef7e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_outline .path1:before {
  content: "\ef7f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_outline .path2:before {
  content: "\ef80";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_outline .path3:before {
  content: "\ef81";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_pin .path1:before {
  content: "\ef82";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_pin .path2:before {
  content: "\ef83";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_pin_circle .path1:before {
  content: "\ef84";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotoneperson_pin_circle .path2:before {
  content: "\ef85";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove .path1:before {
  content: "\ef86";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_remove .path2:before {
  content: "\ef87";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_remove .path3:before {
  content: "\ef88";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove .path4:before {
  content: "\ef89";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove .path5:before {
  content: "\ef8a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove_alt_1 .path1:before {
  content: "\ef8b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_remove_alt_1 .path2:before {
  content: "\ef8c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneperson_remove_alt_1 .path3:before {
  content: "\ef8d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove_alt_1 .path4:before {
  content: "\ef8e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_remove_alt_1 .path5:before {
  content: "\ef8f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_search .path1:before {
  content: "\ef90";
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_search .path2:before {
  content: "\ef91";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_search .path3:before {
  content: "\ef92";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotoneperson_search .path4:before {
  content: "\ef93";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneperson_search .path5:before {
  content: "\ef94";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonepersonal_video .path1:before {
  content: "\ef95";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepersonal_video .path2:before {
  content: "\ef96";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepest_control .path1:before {
  content: "\ef97";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepest_control .path2:before {
  content: "\ef98";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepest_control .path3:before {
  content: "\ef99";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepest_control .path4:before {
  content: "\ef9a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepest_control_rodent .path1:before {
  content: "\ef9b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepest_control_rodent .path2:before {
  content: "\ef9c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepest_control_rodent .path3:before {
  content: "\ef9d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepets:before {
  content: "\ef9e";
}
.icon-twotonephone .path1:before {
  content: "\ef9f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone .path2:before {
  content: "\efa0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_android .path1:before {
  content: "\efa1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_android .path2:before {
  content: "\efa2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_bluetooth_speaker .path1:before {
  content: "\efa3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_bluetooth_speaker .path2:before {
  content: "\efa4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_callback .path1:before {
  content: "\efa5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_callback .path2:before {
  content: "\efa6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_disabled:before {
  content: "\efa7";
}
.icon-twotonephone_enabled:before {
  content: "\efa8";
}
.icon-twotonephone_forwarded .path1:before {
  content: "\efa9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_forwarded .path2:before {
  content: "\efaa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_in_talk .path1:before {
  content: "\efab";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_in_talk .path2:before {
  content: "\efac";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_iphone .path1:before {
  content: "\efad";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_iphone .path2:before {
  content: "\efae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_locked .path1:before {
  content: "\efaf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_locked .path2:before {
  content: "\efb0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_missed .path1:before {
  content: "\efb1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_missed .path2:before {
  content: "\efb2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephone_paused .path1:before {
  content: "\efb3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephone_paused .path2:before {
  content: "\efb4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephonelink .path1:before {
  content: "\efb5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephonelink .path2:before {
  content: "\efb6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephonelink_erase:before {
  content: "\efb7";
}
.icon-twotonephonelink_lock:before {
  content: "\efb8";
}
.icon-twotonephonelink_off .path1:before {
  content: "\efb9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephonelink_off .path2:before {
  content: "\efba";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephonelink_ring .path1:before {
  content: "\efbb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephonelink_ring .path2:before {
  content: "\efbc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephonelink_setup:before {
  content: "\efbd";
}
.icon-twotonephoto .path1:before {
  content: "\efbe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephoto .path2:before {
  content: "\efbf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephoto_album .path1:before {
  content: "\efc0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephoto_album .path2:before {
  content: "\efc1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephoto_camera .path1:before {
  content: "\efc2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephoto_camera .path2:before {
  content: "\efc3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephoto_filter:before {
  content: "\efc4";
}
.icon-twotonephoto_library .path1:before {
  content: "\efc5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephoto_library .path2:before {
  content: "\efc6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephoto_size_select_actual .path1:before {
  content: "\efc7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonephoto_size_select_actual .path2:before {
  content: "\efc8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonephoto_size_select_large:before {
  content: "\efc9";
}
.icon-twotonephoto_size_select_small:before {
  content: "\efca";
}
.icon-twotonepicture_as_pdf .path1:before {
  content: "\efcb";
  color: rgb(37, 44, 50);
}
.icon-twotonepicture_as_pdf .path2:before {
  content: "\efcc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepicture_as_pdf .path3:before {
  content: "\efcd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepicture_in_picture .path1:before {
  content: "\efce";
  color: rgb(37, 44, 50);
}
.icon-twotonepicture_in_picture .path2:before {
  content: "\efcf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepicture_in_picture .path3:before {
  content: "\efd0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepicture_in_picture_alt .path1:before {
  content: "\efd1";
  color: rgb(37, 44, 50);
}
.icon-twotonepicture_in_picture_alt .path2:before {
  content: "\efd2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepie_chart .path1:before {
  content: "\efd3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepie_chart .path2:before {
  content: "\efd4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepie_chart_outline:before {
  content: "\efd5";
}
.icon-twotonepin_drop .path1:before {
  content: "\efd6";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotonepin_drop .path2:before {
  content: "\efd7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepin_drop .path3:before {
  content: "\efd8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplace .path1:before {
  content: "\efd9";
  color: rgb(50, 50, 50);
  opacity: 0.3;
}
.icon-twotoneplace .path2:before {
  content: "\efda";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplagiarism .path1:before {
  content: "\efdb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneplagiarism .path2:before {
  content: "\efdc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneplagiarism .path3:before {
  content: "\efdd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplagiarism .path4:before {
  content: "\efde";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplay_arrow .path1:before {
  content: "\efdf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneplay_arrow .path2:before {
  content: "\efe0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplay_circle_filled .path1:before {
  content: "\efe1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneplay_circle_filled .path2:before {
  content: "\efe2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneplay_circle_outline:before {
  content: "\efe3";
}
.icon-twotoneplay_for_work:before {
  content: "\efe4";
}
.icon-twotoneplaylist_add:before {
  content: "\efe5";
}
.icon-twotoneplaylist_add_check:before {
  content: "\efe6";
}
.icon-twotoneplaylist_play:before {
  content: "\efe7";
}
.icon-twotoneplumbing:before {
  content: "\efe8";
}
.icon-twotoneplus_one:before {
  content: "\efe9";
}
.icon-twotonepoint_of_sale .path1:before {
  content: "\efea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepoint_of_sale .path2:before {
  content: "\efeb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepolicy .path1:before {
  content: "\efec";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepolicy .path2:before {
  content: "\efed";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepoll .path1:before {
  content: "\efee";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepoll .path2:before {
  content: "\efef";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepolymer:before {
  content: "\eff0";
}
.icon-twotonepool .path1:before {
  content: "\eff1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepool .path2:before {
  content: "\eff2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepool .path3:before {
  content: "\eff3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepool .path4:before {
  content: "\eff4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneportable_wifi_off:before {
  content: "\eff5";
}
.icon-twotoneportrait .path1:before {
  content: "\eff6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneportrait .path2:before {
  content: "\eff7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepost_add:before {
  content: "\eff8";
}
.icon-twotonepower .path1:before {
  content: "\eff9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepower .path2:before {
  content: "\effa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepower_input:before {
  content: "\effb";
}
.icon-twotonepower_off .path1:before {
  content: "\effc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepower_off .path2:before {
  content: "\effd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepower_settings_new:before {
  content: "\effe";
}
.icon-twotoneprecision_manufacturing .path1:before {
  content: "\efff";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprecision_manufacturing .path2:before {
  content: "\f000";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprecision_manufacturing .path3:before {
  content: "\f001";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepregnant_woman:before {
  content: "\f002";
}
.icon-twotonepresent_to_all .path1:before {
  content: "\f003";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepresent_to_all .path2:before {
  content: "\f004";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepreview .path1:before {
  content: "\f005";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepreview .path2:before {
  content: "\f006";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneprint .path1:before {
  content: "\f007";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprint .path2:before {
  content: "\f008";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneprint .path3:before {
  content: "\f009";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneprint .path4:before {
  content: "\f00a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprint_disabled .path1:before {
  content: "\f00b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprint_disabled .path2:before {
  content: "\f00c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneprint_disabled .path3:before {
  content: "\f00d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprint_disabled .path4:before {
  content: "\f00e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneprint_disabled .path5:before {
  content: "\f00f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprint_disabled .path6:before {
  content: "\f010";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepriority_high:before {
  content: "\f011";
}
.icon-twotoneprivacy_tip .path1:before {
  content: "\f012";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneprivacy_tip .path2:before {
  content: "\f013";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepsychology .path1:before {
  content: "\f014";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepsychology .path2:before {
  content: "\f015";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepsychology .path3:before {
  content: "\f016";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepublic .path1:before {
  content: "\f017";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepublic .path2:before {
  content: "\f018";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepublic_off .path1:before {
  content: "\f019";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepublic_off .path2:before {
  content: "\f01a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepublish .path1:before {
  content: "\f01b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepublish .path2:before {
  content: "\f01c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonepublished_with_changes:before {
  content: "\f01d";
}
.icon-twotonepush_pin .path1:before {
  content: "\f01e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonepush_pin .path2:before {
  content: "\f01f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path1:before {
  content: "\f020";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneqr_code .path2:before {
  content: "\f021";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneqr_code .path3:before {
  content: "\f022";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneqr_code .path4:before {
  content: "\f023";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path5:before {
  content: "\f024";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path6:before {
  content: "\f025";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path7:before {
  content: "\f026";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path8:before {
  content: "\f027";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path9:before {
  content: "\f028";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path10:before {
  content: "\f029";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path11:before {
  content: "\f02a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path12:before {
  content: "\f02b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path13:before {
  content: "\f02c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code .path14:before {
  content: "\f02d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneqr_code_scanner:before {
  content: "\f02e";
}
.icon-twotonequery_builder .path1:before {
  content: "\f02f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonequery_builder .path2:before {
  content: "\f030";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequestion_answer .path1:before {
  content: "\f031";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonequestion_answer .path2:before {
  content: "\f032";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue .path1:before {
  content: "\f033";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonequeue .path2:before {
  content: "\f034";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue_music .path1:before {
  content: "\f035";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonequeue_music .path2:before {
  content: "\f036";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue_music .path3:before {
  content: "\f037";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue_music .path4:before {
  content: "\f038";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue_music .path5:before {
  content: "\f039";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequeue_play_next:before {
  content: "\f03a";
}
.icon-twotonequickreply .path1:before {
  content: "\f03b";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonequickreply .path2:before {
  content: "\f03c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonequickreply .path3:before {
  content: "\f03d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneradio .path1:before {
  content: "\f03e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneradio .path2:before {
  content: "\f03f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneradio .path3:before {
  content: "\f040";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneradio_button_checked:before {
  content: "\f041";
}
.icon-twotoneradio_button_unchecked:before {
  content: "\f042";
}
.icon-twotonerate_review .path1:before {
  content: "\f043";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerate_review .path2:before {
  content: "\f044";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneread_more:before {
  content: "\f045";
}
.icon-twotonereceipt .path1:before {
  content: "\f046";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonereceipt .path2:before {
  content: "\f047";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereceipt_long .path1:before {
  content: "\f048";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonereceipt_long .path2:before {
  content: "\f049";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereceipt_long .path3:before {
  content: "\f04a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereceipt_long .path4:before {
  content: "\f04b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereceipt_long .path5:before {
  content: "\f04c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereceipt_long .path6:before {
  content: "\f04d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerecent_actors .path1:before {
  content: "\f04e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerecent_actors .path2:before {
  content: "\f04f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerecent_actors .path3:before {
  content: "\f050";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerecent_actors .path4:before {
  content: "\f051";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerecord_voice_over .path1:before {
  content: "\f052";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonerecord_voice_over .path2:before {
  content: "\f053";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonerecord_voice_over .path3:before {
  content: "\f054";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneredeem .path1:before {
  content: "\f055";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneredeem .path2:before {
  content: "\f056";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneredo:before {
  content: "\f057";
}
.icon-twotonereduce_capacity:before {
  content: "\f058";
}
.icon-twotonerefresh:before {
  content: "\f059";
}
.icon-twotoneremove:before {
  content: "\f05a";
}
.icon-twotoneremove_circle .path1:before {
  content: "\f05b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneremove_circle .path2:before {
  content: "\f05c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneremove_circle_outline:before {
  content: "\f05d";
}
.icon-twotoneremove_from_queue .path1:before {
  content: "\f05e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneremove_from_queue .path2:before {
  content: "\f05f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneremove_red_eye .path1:before {
  content: "\f060";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneremove_red_eye .path2:before {
  content: "\f061";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneremove_shopping_cart .path1:before {
  content: "\f062";
  color: rgb(37, 44, 50);
}
.icon-twotoneremove_shopping_cart .path2:before {
  content: "\f063";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneremove_shopping_cart .path3:before {
  content: "\f064";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereorder:before {
  content: "\f065";
}
.icon-twotonerepeat:before {
  content: "\f066";
}
.icon-twotonerepeat_one:before {
  content: "\f067";
}
.icon-twotonereplay:before {
  content: "\f068";
}
.icon-twotonereplay_5:before {
  content: "\f069";
}
.icon-twotonereplay_10:before {
  content: "\f06a";
}
.icon-twotonereplay_30:before {
  content: "\f06b";
}
.icon-twotonereply:before {
  content: "\f06c";
}
.icon-twotonereply_all:before {
  content: "\f06d";
}
.icon-twotonereport .path1:before {
  content: "\f06e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonereport .path2:before {
  content: "\f06f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport .path3:before {
  content: "\f070";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport .path4:before {
  content: "\f071";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport_gmailerrorred:before {
  content: "\f072";
}
.icon-twotonereport_off .path1:before {
  content: "\f073";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonereport_off .path2:before {
  content: "\f074";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport_off .path3:before {
  content: "\f075";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport_off .path4:before {
  content: "\f076";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonereport_problem .path1:before {
  content: "\f077";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonereport_problem .path2:before {
  content: "\f078";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerequest_page .path1:before {
  content: "\f079";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerequest_page .path2:before {
  content: "\f07a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerequest_quote .path1:before {
  content: "\f07b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerequest_quote .path2:before {
  content: "\f07c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerestaurant:before {
  content: "\f07d";
}
.icon-twotonerestaurant_menu:before {
  content: "\f07e";
}
.icon-twotonerestore:before {
  content: "\f07f";
}
.icon-twotonerestore_from_trash .path1:before {
  content: "\f080";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerestore_from_trash .path2:before {
  content: "\f081";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerestore_page .path1:before {
  content: "\f082";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerestore_page .path2:before {
  content: "\f083";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerice_bowl .path1:before {
  content: "\f084";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerice_bowl .path2:before {
  content: "\f085";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonering_volume .path1:before {
  content: "\f086";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonering_volume .path2:before {
  content: "\f087";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneroofing .path1:before {
  content: "\f088";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneroofing .path2:before {
  content: "\f089";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneroom .path1:before {
  content: "\f08a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneroom .path2:before {
  content: "\f08b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneroom .path3:before {
  content: "\f08c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneroom_preferences .path1:before {
  content: "\f08d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneroom_preferences .path2:before {
  content: "\f08e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneroom_service .path1:before {
  content: "\f08f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneroom_service .path2:before {
  content: "\f090";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerotate_90_degrees_ccw .path1:before {
  content: "\f091";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerotate_90_degrees_ccw .path2:before {
  content: "\f092";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerotate_left:before {
  content: "\f093";
}
.icon-twotonerotate_right:before {
  content: "\f094";
}
.icon-twotonerounded_corner:before {
  content: "\f095";
}
.icon-twotonerouter .path1:before {
  content: "\f096";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerouter .path2:before {
  content: "\f097";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerowing:before {
  content: "\f098";
}
.icon-twotonerss_feed:before {
  content: "\f099";
}
.icon-twotonerule:before {
  content: "\f09a";
}
.icon-twotonerule_folder .path1:before {
  content: "\f09b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerule_folder .path2:before {
  content: "\f09c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerun_circle .path1:before {
  content: "\f09d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerun_circle .path2:before {
  content: "\f09e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerun_circle .path3:before {
  content: "\f09f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerun_circle .path4:before {
  content: "\f0a0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonerv_hookup .path1:before {
  content: "\f0a1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonerv_hookup .path2:before {
  content: "\f0a2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesanitizer .path1:before {
  content: "\f0a3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesanitizer .path2:before {
  content: "\f0a4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesatellite .path1:before {
  content: "\f0a5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesatellite .path2:before {
  content: "\f0a6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesave .path1:before {
  content: "\f0a7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesave .path2:before {
  content: "\f0a8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesave_alt:before {
  content: "\f0a9";
}
.icon-twotonescanner .path1:before {
  content: "\f0aa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescanner .path2:before {
  content: "\f0ab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescatter_plot .path1:before {
  content: "\f0ac";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescatter_plot .path2:before {
  content: "\f0ad";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescatter_plot .path3:before {
  content: "\f0ae";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescatter_plot .path4:before {
  content: "\f0af";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneschedule .path1:before {
  content: "\f0b0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneschedule .path2:before {
  content: "\f0b1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneschool .path1:before {
  content: "\f0b2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneschool .path2:before {
  content: "\f0b3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescience .path1:before {
  content: "\f0b4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescience .path2:before {
  content: "\f0b5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescore .path1:before {
  content: "\f0b6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescore .path2:before {
  content: "\f0b7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescreen_lock_landscape .path1:before {
  content: "\f0b8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescreen_lock_landscape .path2:before {
  content: "\f0b9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescreen_lock_portrait .path1:before {
  content: "\f0ba";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescreen_lock_portrait .path2:before {
  content: "\f0bb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescreen_lock_rotation:before {
  content: "\f0bc";
}
.icon-twotonescreen_rotation .path1:before {
  content: "\f0bd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescreen_rotation .path2:before {
  content: "\f0be";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonescreen_share .path1:before {
  content: "\f0bf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonescreen_share .path2:before {
  content: "\f0c0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesd_card .path1:before {
  content: "\f0c1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesd_card .path2:before {
  content: "\f0c2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesd_card_alert .path1:before {
  content: "\f0c3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesd_card_alert .path2:before {
  content: "\f0c4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesd_storage .path1:before {
  content: "\f0c5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesd_storage .path2:before {
  content: "\f0c6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesearch:before {
  content: "\f0c7";
}
.icon-twotonesearch_off:before {
  content: "\f0c8";
}
.icon-twotonesecurity .path1:before {
  content: "\f0c9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesecurity .path2:before {
  content: "\f0ca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneselect_all:before {
  content: "\f0cb";
}
.icon-twotoneself_improvement:before {
  content: "\f0cc";
}
.icon-twotonesend .path1:before {
  content: "\f0cd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesend .path2:before {
  content: "\f0ce";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesensor_door .path1:before {
  content: "\f0cf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesensor_door .path2:before {
  content: "\f0d0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesensor_window .path1:before {
  content: "\f0d1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesensor_window .path2:before {
  content: "\f0d2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_dissatisfied .path1:before {
  content: "\f0d3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_dissatisfied .path2:before {
  content: "\f0d4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_dissatisfied .path3:before {
  content: "\f0d5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_dissatisfied .path4:before {
  content: "\f0d6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_neutral .path1:before {
  content: "\f0d7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_neutral .path2:before {
  content: "\f0d8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_neutral .path3:before {
  content: "\f0d9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_neutral .path4:before {
  content: "\f0da";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_neutral .path5:before {
  content: "\f0db";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied .path1:before {
  content: "\f0dc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_satisfied .path2:before {
  content: "\f0dd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied .path3:before {
  content: "\f0de";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied .path4:before {
  content: "\f0df";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied_alt .path1:before {
  content: "\f0e0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_satisfied_alt .path2:before {
  content: "\f0e1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied_alt .path3:before {
  content: "\f0e2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_satisfied_alt .path4:before {
  content: "\f0e3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_very_dissatisfied .path1:before {
  content: "\f0e4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_very_dissatisfied .path2:before {
  content: "\f0e5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesentiment_very_satisfied .path1:before {
  content: "\f0e6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesentiment_very_satisfied .path2:before {
  content: "\f0e7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneset_meal .path1:before {
  content: "\f0e8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneset_meal .path2:before {
  content: "\f0e9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings .path1:before {
  content: "\f0ea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings .path2:before {
  content: "\f0eb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_applications .path1:before {
  content: "\f0ec";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_applications .path2:before {
  content: "\f0ed";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_backup_restore:before {
  content: "\f0ee";
}
.icon-twotonesettings_bluetooth:before {
  content: "\f0ef";
}
.icon-twotonesettings_brightness .path1:before {
  content: "\f0f0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_brightness .path2:before {
  content: "\f0f1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_cell .path1:before {
  content: "\f0f2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_cell .path2:before {
  content: "\f0f3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_ethernet:before {
  content: "\f0f4";
}
.icon-twotonesettings_input_antenna:before {
  content: "\f0f5";
}
.icon-twotonesettings_input_component .path1:before {
  content: "\f0f6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_input_component .path2:before {
  content: "\f0f7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_composite .path1:before {
  content: "\f0f8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_input_composite .path2:before {
  content: "\f0f9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_hdmi .path1:before {
  content: "\f0fa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_input_hdmi .path2:before {
  content: "\f0fb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path1:before {
  content: "\f0fc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_input_svideo .path2:before {
  content: "\f0fd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path3:before {
  content: "\f0fe";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path4:before {
  content: "\f0ff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path5:before {
  content: "\f100";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path6:before {
  content: "\f101";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_input_svideo .path7:before {
  content: "\f102";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_overscan .path1:before {
  content: "\f103";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_overscan .path2:before {
  content: "\f104";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_phone .path1:before {
  content: "\f105";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_phone .path2:before {
  content: "\f106";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_power:before {
  content: "\f107";
}
.icon-twotonesettings_remote .path1:before {
  content: "\f108";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_remote .path2:before {
  content: "\f109";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_remote .path3:before {
  content: "\f10a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_remote .path4:before {
  content: "\f10b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_system_daydream .path1:before {
  content: "\f10c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_system_daydream .path2:before {
  content: "\f10d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesettings_voice .path1:before {
  content: "\f10e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesettings_voice .path2:before {
  content: "\f10f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshare .path1:before {
  content: "\f110";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshare .path2:before {
  content: "\f111";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshare .path3:before {
  content: "\f112";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshare .path4:before {
  content: "\f113";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshop .path1:before {
  content: "\f114";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshop .path2:before {
  content: "\f115";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshop_two .path1:before {
  content: "\f116";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshop_two .path2:before {
  content: "\f117";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshopping_bag .path1:before {
  content: "\f118";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshopping_bag .path2:before {
  content: "\f119";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshopping_basket .path1:before {
  content: "\f11a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshopping_basket .path2:before {
  content: "\f11b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshopping_cart .path1:before {
  content: "\f11c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshopping_cart .path2:before {
  content: "\f11d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneshort_text:before {
  content: "\f11e";
}
.icon-twotoneshow_chart:before {
  content: "\f11f";
}
.icon-twotoneshuffle:before {
  content: "\f120";
}
.icon-twotoneshutter_speed .path1:before {
  content: "\f121";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneshutter_speed .path2:before {
  content: "\f122";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesick:before {
  content: "\f123";
}
.icon-twotonesignal_cellular_0_bar:before {
  content: "\f124";
}
.icon-twotonesignal_cellular_1_bar .path1:before {
  content: "\f125";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_1_bar .path2:before {
  content: "\f126";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_2_bar .path1:before {
  content: "\f127";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_2_bar .path2:before {
  content: "\f128";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_3_bar .path1:before {
  content: "\f129";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_3_bar .path2:before {
  content: "\f12a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_4_bar:before {
  content: "\f12b";
}
.icon-twotonesignal_cellular_alt:before {
  content: "\f12c";
}
.icon-twotonesignal_cellular_connected_no_internet_0_bar .path1:before {
  content: "\f12d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_connected_no_internet_0_bar .path2:before {
  content: "\f12e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_connected_no_internet_1_bar .path1:before {
  content: "\f12f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_connected_no_internet_1_bar .path2:before {
  content: "\f130";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_connected_no_internet_2_bar .path1:before {
  content: "\f131";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_connected_no_internet_2_bar .path2:before {
  content: "\f132";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_connected_no_internet_3_bar .path1:before {
  content: "\f133";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_connected_no_internet_3_bar .path2:before {
  content: "\f134";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_connected_no_internet_4_bar:before {
  content: "\f135";
}
.icon-twotonesignal_cellular_no_sim .path1:before {
  content: "\f136";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_cellular_no_sim .path2:before {
  content: "\f137";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_cellular_null:before {
  content: "\f138";
}
.icon-twotonesignal_cellular_off:before {
  content: "\f139";
}
.icon-twotonesignal_wifi_0_bar:before {
  content: "\f13a";
}
.icon-twotonesignal_wifi_1_bar .path1:before {
  content: "\f13b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_1_bar .path2:before {
  content: "\f13c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_1_bar_lock .path1:before {
  content: "\f13d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_1_bar_lock .path2:before {
  content: "\f13e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_2_bar .path1:before {
  content: "\f13f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_2_bar .path2:before {
  content: "\f140";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_2_bar_lock .path1:before {
  content: "\f141";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_2_bar_lock .path2:before {
  content: "\f142";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_3_bar .path1:before {
  content: "\f143";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_3_bar .path2:before {
  content: "\f144";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_3_bar_lock .path1:before {
  content: "\f145";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesignal_wifi_3_bar_lock .path2:before {
  content: "\f146";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesignal_wifi_4_bar:before {
  content: "\f147";
}
.icon-twotonesignal_wifi_4_bar_lock:before {
  content: "\f148";
}
.icon-twotonesignal_wifi_off:before {
  content: "\f149";
}
.icon-twotonesim_card .path1:before {
  content: "\f14a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesim_card .path2:before {
  content: "\f14b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesingle_bed .path1:before {
  content: "\f14c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesingle_bed .path2:before {
  content: "\f14d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneskip_next .path1:before {
  content: "\f14e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneskip_next .path2:before {
  content: "\f14f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneskip_previous .path1:before {
  content: "\f150";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneskip_previous .path2:before {
  content: "\f151";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneslideshow .path1:before {
  content: "\f152";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneslideshow .path2:before {
  content: "\f153";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneslow_motion_video:before {
  content: "\f154";
}
.icon-twotonesmart_button:before {
  content: "\f155";
}
.icon-twotonesmartphone .path1:before {
  content: "\f156";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesmartphone .path2:before {
  content: "\f157";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesmoke_free:before {
  content: "\f158";
}
.icon-twotonesmoking_rooms .path1:before {
  content: "\f159";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesmoking_rooms .path2:before {
  content: "\f15a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesms .path1:before {
  content: "\f15b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesms .path2:before {
  content: "\f15c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesms_failed .path1:before {
  content: "\f15d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesms_failed .path2:before {
  content: "\f15e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesnippet_folder .path1:before {
  content: "\f15f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesnippet_folder .path2:before {
  content: "\f160";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesnooze:before {
  content: "\f161";
}
.icon-twotonesoap .path1:before {
  content: "\f162";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesoap .path2:before {
  content: "\f163";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesort:before {
  content: "\f164";
}
.icon-twotonesort_by_alpha:before {
  content: "\f165";
}
.icon-twotonesource .path1:before {
  content: "\f166";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesource .path2:before {
  content: "\f167";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesouth:before {
  content: "\f168";
}
.icon-twotonesouth_east:before {
  content: "\f169";
}
.icon-twotonesouth_west:before {
  content: "\f16a";
}
.icon-twotonespa .path1:before {
  content: "\f16b";
  color: rgb(37, 44, 50);
}
.icon-twotonespa .path2:before {
  content: "\f16c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespa .path3:before {
  content: "\f16d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespa .path4:before {
  content: "\f16e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespace_bar:before {
  content: "\f16f";
}
.icon-twotonespeaker .path1:before {
  content: "\f170";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespeaker .path2:before {
  content: "\f171";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespeaker_group .path1:before {
  content: "\f172";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespeaker_group .path2:before {
  content: "\f173";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespeaker_notes .path1:before {
  content: "\f174";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespeaker_notes .path2:before {
  content: "\f175";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespeaker_notes_off .path1:before {
  content: "\f176";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespeaker_notes_off .path2:before {
  content: "\f177";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespeaker_phone .path1:before {
  content: "\f178";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonespeaker_phone .path2:before {
  content: "\f179";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonespeed:before {
  content: "\f17a";
}
.icon-twotonespellcheck:before {
  content: "\f17b";
}
.icon-twotonesports:before {
  content: "\f17c";
}
.icon-twotonesports_bar .path1:before {
  content: "\f17d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_bar .path2:before {
  content: "\f17e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_baseball .path1:before {
  content: "\f17f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_baseball .path2:before {
  content: "\f180";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_baseball .path3:before {
  content: "\f181";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_baseball .path4:before {
  content: "\f182";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_basketball .path1:before {
  content: "\f183";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path2:before {
  content: "\f184";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path3:before {
  content: "\f185";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path4:before {
  content: "\f186";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path5:before {
  content: "\f187";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path6:before {
  content: "\f188";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path7:before {
  content: "\f189";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path8:before {
  content: "\f18a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_basketball .path9:before {
  content: "\f18b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_cricket .path1:before {
  content: "\f18c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_cricket .path2:before {
  content: "\f18d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_cricket .path3:before {
  content: "\f18e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_cricket .path4:before {
  content: "\f18f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_cricket .path5:before {
  content: "\f190";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_esports .path1:before {
  content: "\f191";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_esports .path2:before {
  content: "\f192";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_esports .path3:before {
  content: "\f193";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_esports .path4:before {
  content: "\f194";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_esports .path5:before {
  content: "\f195";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_football .path1:before {
  content: "\f196";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_football .path2:before {
  content: "\f197";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_football .path3:before {
  content: "\f198";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_football .path4:before {
  content: "\f199";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_football .path5:before {
  content: "\f19a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_golf .path1:before {
  content: "\f19b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_golf .path2:before {
  content: "\f19c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_golf .path3:before {
  content: "\f19d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_golf .path4:before {
  content: "\f19e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_golf .path5:before {
  content: "\f19f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_golf .path6:before {
  content: "\f1a0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_handball:before {
  content: "\f1a1";
}
.icon-twotonesports_hockey:before {
  content: "\f1a2";
}
.icon-twotonesports_kabaddi:before {
  content: "\f1a3";
}
.icon-twotonesports_mma .path1:before {
  content: "\f1a4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_mma .path2:before {
  content: "\f1a5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_mma .path3:before {
  content: "\f1a6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_mma .path4:before {
  content: "\f1a7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_motorsports .path1:before {
  content: "\f1a8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_motorsports .path2:before {
  content: "\f1a9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_rugby .path1:before {
  content: "\f1aa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_rugby .path2:before {
  content: "\f1ab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_rugby .path3:before {
  content: "\f1ac";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_rugby .path4:before {
  content: "\f1ad";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_soccer .path1:before {
  content: "\f1ae";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path2:before {
  content: "\f1af";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path3:before {
  content: "\f1b0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path4:before {
  content: "\f1b1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path5:before {
  content: "\f1b2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path6:before {
  content: "\f1b3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonesports_soccer .path7:before {
  content: "\f1b4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesports_tennis:before {
  content: "\f1b5";
}
.icon-twotonesports_volleyball .path1:before {
  content: "\f1b6";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path2:before {
  content: "\f1b7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path3:before {
  content: "\f1b8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path4:before {
  content: "\f1b9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path5:before {
  content: "\f1ba";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path6:before {
  content: "\f1bb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesports_volleyball .path7:before {
  content: "\f1bc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesquare_foot .path1:before {
  content: "\f1bd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesquare_foot .path2:before {
  content: "\f1be";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestacked_line_chart:before {
  content: "\f1bf";
}
.icon-twotonestairs .path1:before {
  content: "\f1c0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestairs .path2:before {
  content: "\f1c1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestar .path1:before {
  content: "\f1c2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestar .path2:before {
  content: "\f1c3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestar_border:before {
  content: "\f1c4";
}
.icon-twotonestar_half:before {
  content: "\f1c5";
}
.icon-twotonestar_outline:before {
  content: "\f1c6";
}
.icon-twotonestar_rate .path1:before {
  content: "\f1c7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestar_rate .path2:before {
  content: "\f1c8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestars .path1:before {
  content: "\f1c9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestars .path2:before {
  content: "\f1ca";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestay_current_landscape .path1:before {
  content: "\f1cb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestay_current_landscape .path2:before {
  content: "\f1cc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestay_current_portrait .path1:before {
  content: "\f1cd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestay_current_portrait .path2:before {
  content: "\f1ce";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestay_primary_landscape .path1:before {
  content: "\f1cf";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestay_primary_landscape .path2:before {
  content: "\f1d0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestay_primary_portrait .path1:before {
  content: "\f1d1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestay_primary_portrait .path2:before {
  content: "\f1d2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesticky_note_2 .path1:before {
  content: "\f1d3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesticky_note_2 .path2:before {
  content: "\f1d4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestop .path1:before {
  content: "\f1d5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestop .path2:before {
  content: "\f1d6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestop_circle .path1:before {
  content: "\f1d7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestop_circle .path2:before {
  content: "\f1d8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestop_circle .path3:before {
  content: "\f1d9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestop_screen_share .path1:before {
  content: "\f1da";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestop_screen_share .path2:before {
  content: "\f1db";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestop_screen_share .path3:before {
  content: "\f1dc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestorage:before {
  content: "\f1dd";
}
.icon-twotonestore .path1:before {
  content: "\f1de";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestore .path2:before {
  content: "\f1df";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestore_mall_directory .path1:before {
  content: "\f1e0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestore_mall_directory .path2:before {
  content: "\f1e1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestorefront .path1:before {
  content: "\f1e2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestorefront .path2:before {
  content: "\f1e3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestorefront .path3:before {
  content: "\f1e4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestorefront .path4:before {
  content: "\f1e5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestorefront .path5:before {
  content: "\f1e6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestraighten .path1:before {
  content: "\f1e7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestraighten .path2:before {
  content: "\f1e8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestreetview:before {
  content: "\f1e9";
}
.icon-twotonestrikethrough_s:before {
  content: "\f1ea";
}
.icon-twotonestroller .path1:before {
  content: "\f1eb";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestroller .path2:before {
  content: "\f1ec";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestyle .path1:before {
  content: "\f1ed";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonestyle .path2:before {
  content: "\f1ee";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestyle .path3:before {
  content: "\f1ef";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonestyle .path4:before {
  content: "\f1f0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubdirectory_arrow_left:before {
  content: "\f1f1";
}
.icon-twotonesubdirectory_arrow_right:before {
  content: "\f1f2";
}
.icon-twotonesubject:before {
  content: "\f1f3";
}
.icon-twotonesubscript:before {
  content: "\f1f4";
}
.icon-twotonesubscriptions .path1:before {
  content: "\f1f5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesubscriptions .path2:before {
  content: "\f1f6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubtitles .path1:before {
  content: "\f1f7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesubtitles .path2:before {
  content: "\f1f8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubtitles_off .path1:before {
  content: "\f1f9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesubtitles_off .path2:before {
  content: "\f1fa";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesubtitles_off .path3:before {
  content: "\f1fb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubtitles_off .path4:before {
  content: "\f1fc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubtitles_off .path5:before {
  content: "\f1fd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesubway .path1:before {
  content: "\f1fe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesubway .path2:before {
  content: "\f1ff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesuperscript:before {
  content: "\f200";
}
.icon-twotonesupervised_user_circle .path1:before {
  content: "\f201";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupervised_user_circle .path2:before {
  content: "\f202";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupervised_user_circle .path3:before {
  content: "\f203";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesupervisor_account .path1:before {
  content: "\f204";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupervisor_account .path2:before {
  content: "\f205";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupervisor_account .path3:before {
  content: "\f206";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesupport .path1:before {
  content: "\f207";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupport .path2:before {
  content: "\f208";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupport .path3:before {
  content: "\f209";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupport .path4:before {
  content: "\f20a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesupport .path5:before {
  content: "\f20b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesupport_agent:before {
  content: "\f20c";
}
.icon-twotonesurround_sound .path1:before {
  content: "\f20d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesurround_sound .path2:before {
  content: "\f20e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesurround_sound .path3:before {
  content: "\f20f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesurround_sound .path4:before {
  content: "\f210";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesurround_sound .path5:before {
  content: "\f211";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswap_calls:before {
  content: "\f212";
}
.icon-twotoneswap_horiz:before {
  content: "\f213";
}
.icon-twotoneswap_horizontal_circle .path1:before {
  content: "\f214";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswap_horizontal_circle .path2:before {
  content: "\f215";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswap_vert:before {
  content: "\f216";
}
.icon-twotoneswap_vertical_circle .path1:before {
  content: "\f217";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswap_vertical_circle .path2:before {
  content: "\f218";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswitch_camera .path1:before {
  content: "\f219";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswitch_camera .path2:before {
  content: "\f21a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswitch_left .path1:before {
  content: "\f21b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswitch_left .path2:before {
  content: "\f21c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswitch_right .path1:before {
  content: "\f21d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswitch_right .path2:before {
  content: "\f21e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneswitch_video .path1:before {
  content: "\f21f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneswitch_video .path2:before {
  content: "\f220";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesync:before {
  content: "\f221";
}
.icon-twotonesync_alt:before {
  content: "\f222";
}
.icon-twotonesync_disabled:before {
  content: "\f223";
}
.icon-twotonesync_problem:before {
  content: "\f224";
}
.icon-twotonesystem_update .path1:before {
  content: "\f225";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonesystem_update .path2:before {
  content: "\f226";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonesystem_update_alt:before {
  content: "\f227";
}
.icon-twotonetab:before {
  content: "\f228";
}
.icon-twotonetab_unselected:before {
  content: "\f229";
}
.icon-twotonetable_chart .path1:before {
  content: "\f22a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetable_chart .path2:before {
  content: "\f22b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetable_rows .path1:before {
  content: "\f22c";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotonetable_rows .path2:before {
  content: "\f22d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetable_view .path1:before {
  content: "\f22e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetable_view .path2:before {
  content: "\f22f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetablet .path1:before {
  content: "\f230";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetablet .path2:before {
  content: "\f231";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetablet_android .path1:before {
  content: "\f232";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetablet_android .path2:before {
  content: "\f233";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetablet_mac .path1:before {
  content: "\f234";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetablet_mac .path2:before {
  content: "\f235";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetag_faces .path1:before {
  content: "\f236";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetag_faces .path2:before {
  content: "\f237";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetag_faces .path3:before {
  content: "\f238";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetag_faces .path4:before {
  content: "\f239";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetag_faces .path5:before {
  content: "\f23a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetap_and_play:before {
  content: "\f23b";
}
.icon-twotonetapas .path1:before {
  content: "\f23c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetapas .path2:before {
  content: "\f23d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneterrain .path1:before {
  content: "\f23e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneterrain .path2:before {
  content: "\f23f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetext_fields:before {
  content: "\f240";
}
.icon-twotonetext_format:before {
  content: "\f241";
}
.icon-twotonetext_rotate_up:before {
  content: "\f242";
}
.icon-twotonetext_rotate_vertical:before {
  content: "\f243";
}
.icon-twotonetext_rotation_angledown:before {
  content: "\f244";
}
.icon-twotonetext_rotation_angleup:before {
  content: "\f245";
}
.icon-twotonetext_rotation_down:before {
  content: "\f246";
}
.icon-twotonetext_rotation_none:before {
  content: "\f247";
}
.icon-twotonetext_snippet .path1:before {
  content: "\f248";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetext_snippet .path2:before {
  content: "\f249";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetextsms .path1:before {
  content: "\f24a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetextsms .path2:before {
  content: "\f24b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetexture:before {
  content: "\f24c";
}
.icon-twotonetheaters .path1:before {
  content: "\f24d";
  color: rgb(37, 44, 50);
}
.icon-twotonetheaters .path2:before {
  content: "\f24e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonethermostat:before {
  content: "\f24f";
}
.icon-twotonethumb_down .path1:before {
  content: "\f250";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonethumb_down .path2:before {
  content: "\f251";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonethumb_down_alt .path1:before {
  content: "\f252";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonethumb_down_alt .path2:before {
  content: "\f253";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonethumb_up .path1:before {
  content: "\f254";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonethumb_up .path2:before {
  content: "\f255";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonethumb_up_alt .path1:before {
  content: "\f256";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonethumb_up_alt .path2:before {
  content: "\f257";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonethumbs_up_down .path1:before {
  content: "\f258";
  color: rgb(37, 44, 50);
}
.icon-twotonethumbs_up_down .path2:before {
  content: "\f259";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetime_to_leave .path1:before {
  content: "\f25a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetime_to_leave .path2:before {
  content: "\f25b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetime_to_leave .path3:before {
  content: "\f25c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetime_to_leave .path4:before {
  content: "\f25d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetimelapse .path1:before {
  content: "\f25e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetimelapse .path2:before {
  content: "\f25f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetimeline:before {
  content: "\f260";
}
.icon-twotonetimer .path1:before {
  content: "\f261";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetimer .path2:before {
  content: "\f262";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetimer_3:before {
  content: "\f263";
}
.icon-twotonetimer_10:before {
  content: "\f264";
}
.icon-twotonetimer_off .path1:before {
  content: "\f265";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetimer_off .path2:before {
  content: "\f266";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetitle:before {
  content: "\f267";
}
.icon-twotonetoc:before {
  content: "\f268";
}
.icon-twotonetoday .path1:before {
  content: "\f269";
  color: rgb(37, 44, 50);
}
.icon-twotonetoday .path2:before {
  content: "\f26a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetoggle_off .path1:before {
  content: "\f26b";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetoggle_off .path2:before {
  content: "\f26c";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetoggle_on .path1:before {
  content: "\f26d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetoggle_on .path2:before {
  content: "\f26e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetoll .path1:before {
  content: "\f26f";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetoll .path2:before {
  content: "\f270";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetonality .path1:before {
  content: "\f271";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetonality .path2:before {
  content: "\f272";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetopic .path1:before {
  content: "\f273";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetopic .path2:before {
  content: "\f274";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetouch_app .path1:before {
  content: "\f275";
  color: rgb(37, 44, 50);
}
.icon-twotonetouch_app .path2:before {
  content: "\f276";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetour .path1:before {
  content: "\f277";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetour .path2:before {
  content: "\f278";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetoys .path1:before {
  content: "\f279";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetoys .path2:before {
  content: "\f27a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetrack_changes:before {
  content: "\f27b";
}
.icon-twotonetraffic .path1:before {
  content: "\f27c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetraffic .path2:before {
  content: "\f27d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetrain .path1:before {
  content: "\f27e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetrain .path2:before {
  content: "\f27f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetrain .path3:before {
  content: "\f280";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetrain .path4:before {
  content: "\f281";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetram .path1:before {
  content: "\f282";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetram .path2:before {
  content: "\f283";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetransfer_within_a_station:before {
  content: "\f284";
}
.icon-twotonetransform:before {
  content: "\f285";
}
.icon-twotonetransit_enterexit:before {
  content: "\f286";
}
.icon-twotonetranslate:before {
  content: "\f287";
}
.icon-twotonetrending_down:before {
  content: "\f288";
}
.icon-twotonetrending_flat:before {
  content: "\f289";
}
.icon-twotonetrending_up:before {
  content: "\f28a";
}
.icon-twotonetrip_origin:before {
  content: "\f28b";
}
.icon-twotonetty .path1:before {
  content: "\f28c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetty .path2:before {
  content: "\f28d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetune:before {
  content: "\f28e";
}
.icon-twotoneturned_in .path1:before {
  content: "\f28f";
  color: rgb(37, 44, 50);
}
.icon-twotoneturned_in .path2:before {
  content: "\f290";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneturned_in_not:before {
  content: "\f291";
}
.icon-twotonetv .path1:before {
  content: "\f292";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetv .path2:before {
  content: "\f293";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetv_off .path1:before {
  content: "\f294";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonetv_off .path2:before {
  content: "\f295";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonetwo_wheeler:before {
  content: "\f296";
}
.icon-twotoneumbrella .path1:before {
  content: "\f297";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneumbrella .path2:before {
  content: "\f298";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneunarchive .path1:before {
  content: "\f299";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneunarchive .path2:before {
  content: "\f29a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneundo:before {
  content: "\f29b";
}
.icon-twotoneunfold_less:before {
  content: "\f29c";
}
.icon-twotoneunfold_more:before {
  content: "\f29d";
}
.icon-twotoneunpublished .path1:before {
  content: "\f29e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneunpublished .path2:before {
  content: "\f29f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneunsubscribe .path1:before {
  content: "\f2a0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneunsubscribe .path2:before {
  content: "\f2a1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneupdate:before {
  content: "\f2a2";
}
.icon-twotoneupgrade:before {
  content: "\f2a3";
}
.icon-twotoneupload .path1:before {
  content: "\f2a4";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneupload .path2:before {
  content: "\f2a5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneusb:before {
  content: "\f2a6";
}
.icon-twotoneverified .path1:before {
  content: "\f2a7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneverified .path2:before {
  content: "\f2a8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneverified .path3:before {
  content: "\f2a9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneverified_user .path1:before {
  content: "\f2aa";
  color: rgb(37, 44, 50);
}
.icon-twotoneverified_user .path2:before {
  content: "\f2ab";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevertical_align_bottom:before {
  content: "\f2ac";
}
.icon-twotonevertical_align_center:before {
  content: "\f2ad";
}
.icon-twotonevertical_align_top:before {
  content: "\f2ae";
}
.icon-twotonevertical_split .path1:before {
  content: "\f2af";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevertical_split .path2:before {
  content: "\f2b0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevibration .path1:before {
  content: "\f2b1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevibration .path2:before {
  content: "\f2b2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideo_call .path1:before {
  content: "\f2b3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideo_call .path2:before {
  content: "\f2b4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideo_label .path1:before {
  content: "\f2b5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideo_label .path2:before {
  content: "\f2b6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideo_library .path1:before {
  content: "\f2b7";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideo_library .path2:before {
  content: "\f2b8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideo_settings:before {
  content: "\f2b9";
}
.icon-twotonevideocam .path1:before {
  content: "\f2ba";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideocam .path2:before {
  content: "\f2bb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideocam_off .path1:before {
  content: "\f2bc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideocam_off .path2:before {
  content: "\f2bd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideogame_asset .path1:before {
  content: "\f2be";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevideogame_asset .path2:before {
  content: "\f2bf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideogame_asset .path3:before {
  content: "\f2c0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevideogame_asset .path4:before {
  content: "\f2c1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_agenda .path1:before {
  content: "\f2c2";
  color: rgb(37, 44, 50);
}
.icon-twotoneview_agenda .path2:before {
  content: "\f2c3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_array .path1:before {
  content: "\f2c4";
  color: rgb(37, 44, 50);
}
.icon-twotoneview_array .path2:before {
  content: "\f2c5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_carousel .path1:before {
  content: "\f2c6";
  color: rgb(37, 44, 50);
}
.icon-twotoneview_carousel .path2:before {
  content: "\f2c7";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_column .path1:before {
  content: "\f2c8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_column .path2:before {
  content: "\f2c9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_comfy .path1:before {
  content: "\f2ca";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_comfy .path2:before {
  content: "\f2cb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_compact .path1:before {
  content: "\f2cc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_compact .path2:before {
  content: "\f2cd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_day .path1:before {
  content: "\f2ce";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_day .path2:before {
  content: "\f2cf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_headline:before {
  content: "\f2d0";
}
.icon-twotoneview_list .path1:before {
  content: "\f2d1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_list .path2:before {
  content: "\f2d2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_module .path1:before {
  content: "\f2d3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_module .path2:before {
  content: "\f2d4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_quilt .path1:before {
  content: "\f2d5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_quilt .path2:before {
  content: "\f2d6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_sidebar .path1:before {
  content: "\f2d7";
  color: rgb(37, 44, 50);
  opacity: 0.0900;
}
.icon-twotoneview_sidebar .path2:before {
  content: "\f2d8";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_stream .path1:before {
  content: "\f2d9";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneview_stream .path2:before {
  content: "\f2da";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneview_week .path1:before {
  content: "\f2db";
  color: rgb(37, 44, 50);
}
.icon-twotoneview_week .path2:before {
  content: "\f2dc";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevignette .path1:before {
  content: "\f2dd";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevignette .path2:before {
  content: "\f2de";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevisibility .path1:before {
  content: "\f2df";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevisibility .path2:before {
  content: "\f2e0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevisibility_off .path1:before {
  content: "\f2e1";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevisibility_off .path2:before {
  content: "\f2e2";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevoice_chat .path1:before {
  content: "\f2e3";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevoice_chat .path2:before {
  content: "\f2e4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevoice_over_off .path1:before {
  content: "\f2e5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevoice_over_off .path2:before {
  content: "\f2e6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevoicemail:before {
  content: "\f2e7";
}
.icon-twotonevolume_down .path1:before {
  content: "\f2e8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevolume_down .path2:before {
  content: "\f2e9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevolume_mute .path1:before {
  content: "\f2ea";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevolume_mute .path2:before {
  content: "\f2eb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevolume_off .path1:before {
  content: "\f2ec";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevolume_off .path2:before {
  content: "\f2ed";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevolume_up .path1:before {
  content: "\f2ee";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevolume_up .path2:before {
  content: "\f2ef";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevpn_key .path1:before {
  content: "\f2f0";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevpn_key .path2:before {
  content: "\f2f1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonevpn_lock .path1:before {
  content: "\f2f2";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonevpn_lock .path2:before {
  content: "\f2f3";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewallpaper:before {
  content: "\f2f4";
}
.icon-twotonewarning .path1:before {
  content: "\f2f5";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewarning .path2:before {
  content: "\f2f6";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewarning_amber:before {
  content: "\f2f7";
}
.icon-twotonewash .path1:before {
  content: "\f2f8";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewash .path2:before {
  content: "\f2f9";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewatch .path1:before {
  content: "\f2fa";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewatch .path2:before {
  content: "\f2fb";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewatch_later .path1:before {
  content: "\f2fc";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewatch_later .path2:before {
  content: "\f2fd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewater_damage .path1:before {
  content: "\f2fe";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewater_damage .path2:before {
  content: "\f2ff";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewaves:before {
  content: "\f300";
}
.icon-twotonewb_auto .path1:before {
  content: "\f301";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewb_auto .path2:before {
  content: "\f302";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewb_cloudy .path1:before {
  content: "\f303";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewb_cloudy .path2:before {
  content: "\f304";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewb_incandescent .path1:before {
  content: "\f305";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewb_incandescent .path2:before {
  content: "\f306";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewb_iridescent .path1:before {
  content: "\f307";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewb_iridescent .path2:before {
  content: "\f308";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewb_sunny .path1:before {
  content: "\f309";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewb_sunny .path2:before {
  content: "\f30a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewc:before {
  content: "\f30b";
}
.icon-twotoneweb .path1:before {
  content: "\f30c";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneweb .path2:before {
  content: "\f30d";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneweb .path3:before {
  content: "\f30e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneweb .path4:before {
  content: "\f30f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneweb_asset .path1:before {
  content: "\f310";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneweb_asset .path2:before {
  content: "\f311";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneweekend .path1:before {
  content: "\f312";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotoneweekend .path2:before {
  content: "\f313";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewest:before {
  content: "\f314";
}
.icon-twotonewhatshot .path1:before {
  content: "\f315";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewhatshot .path2:before {
  content: "\f316";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewheelchair_pickup:before {
  content: "\f317";
}
.icon-twotonewhere_to_vote .path1:before {
  content: "\f318";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewhere_to_vote .path2:before {
  content: "\f319";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewidgets .path1:before {
  content: "\f31a";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewidgets .path2:before {
  content: "\f31b";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewifi:before {
  content: "\f31c";
}
.icon-twotonewifi_calling .path1:before {
  content: "\f31d";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewifi_calling .path2:before {
  content: "\f31e";
  margin-left: -1em;
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewifi_calling .path3:before {
  content: "\f31f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewifi_calling .path4:before {
  content: "\f320";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonewifi_lock:before {
  content: "\f321";
}
.icon-twotonewifi_off:before {
  content: "\f322";
}
.icon-twotonewifi_protected_setup:before {
  content: "\f323";
}
.icon-twotonewifi_tethering:before {
  content: "\f324";
}
.icon-twotonewine_bar .path1:before {
  content: "\f325";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewine_bar .path2:before {
  content: "\f326";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonework .path1:before {
  content: "\f327";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonework .path2:before {
  content: "\f328";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonework_off .path1:before {
  content: "\f329";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonework_off .path2:before {
  content: "\f32a";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotonework_outline:before {
  content: "\f32b";
}
.icon-twotonewrap_text:before {
  content: "\f32c";
}
.icon-twotonewrong_location:before {
  content: "\f32d";
}
.icon-twotonewysiwyg .path1:before {
  content: "\f32e";
  color: rgb(37, 44, 50);
  opacity: 0.3;
}
.icon-twotonewysiwyg .path2:before {
  content: "\f32f";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-twotoneyoutube_searched_for:before {
  content: "\f330";
}
.icon-twotonezoom_in:before {
  content: "\f331";
}
.icon-twotonezoom_out:before {
  content: "\f332";
}
.icon-twotonezoom_out_map:before {
  content: "\f333";
}
