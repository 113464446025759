@font-face {
  font-family: 'main';
  src:  url('fonts/main.eot?4i8m09');
  src:  url('fonts/main.eot?4i8m09#iefix') format('embedded-opentype'),
    url('fonts/main.ttf?4i8m09') format('truetype'),
    url('fonts/main.woff?4i8m09') format('woff'),
    url('fonts/main.svg?4i8m09#main') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-main"], [class*=" icon-main"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'main' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mainAPP---Parent:before {
  content: "\e900";
  color: #e90030;
}
.icon-mainBenefit-Logo-1:before {
  content: "\e901";
  color: #e90030;
}
.icon-mainCall-Insurance .path1:before {
  content: "\e902";
  color: rgb(225, 52, 85);
  opacity: 0.3;
}
.icon-mainCall-Insurance .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path8:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainCall-Insurance .path9:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCall-Insurance .path10:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainCall-Insurance .path11:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainCall-Insurance .path12:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainCall-Us:before {
  content: "\e90e";
  color: #00a9e7;
}
.icon-mainCar-Crash .path1:before {
  content: "\e90f";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainCar-Crash .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Crash .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Crash .path4:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Crash .path5:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Crash .path6:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Crash .path7:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Crash .path8:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Crash .path9:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Crash .path10:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainCar-Features:before {
  content: "\e919";
}
.icon-mainCar-Insurance .path1:before {
  content: "\e91a";
  color: rgb(250, 141, 127);
  opacity: 0.3;
}
.icon-mainCar-Insurance .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Insurance .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Insurance .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(250, 141, 127);
}
.icon-mainCar-Insurance .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(250, 141, 127);
}
.icon-mainCar-Insurance .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Insurance .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Insurance .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCar-Insurance .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(250, 141, 127);
}
.icon-mainCar-Insurance .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainCard:before {
  content: "\e924";
  color: #fff;
}
.icon-mainCard-1:before {
  content: "\e925";
  color: #fff;
}
.icon-mainCard-2:before {
  content: "\e926";
  color: #fff;
}
.icon-mainChat-with-us:before {
  content: "\e927";
  color: #00a9e7;
}
.icon-mainContact-Us:before {
  content: "\e928";
  color: #00a9e7;
}
.icon-mainDomestic-1 .path1:before {
  content: "\e929";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainDomestic-1 .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-1 .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-1 .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-1 .path5:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-1 .path6:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path1:before {
  content: "\e92f";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainDomestic-2 .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path3:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path4:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path5:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path6:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path7:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path8:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path9:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path10:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path11:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path12:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path13:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-2 .path14:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-3 .path1:before {
  content: "\e93d";
  color: rgb(247, 147, 29);
  opacity: 0.2;
}
.icon-mainDomestic-3 .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-3 .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path4:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path5:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path6:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path7:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path8:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-3 .path9:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path1:before {
  content: "\e946";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainDomestic-4 .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainDomestic-4 .path3:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path4:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path5:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path6:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path7:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path8:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path9:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path10:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-4 .path11:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mainDomestic-4 .path12:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainDomestic-Features:before {
  content: "\e952";
}
.icon-mainDomestic-Insurance .path1:before {
  content: "\e953";
  color: rgb(131, 81, 1);
  opacity: 0.3;
}
.icon-mainDomestic-Insurance .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainDomestic-Insurance .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainDomestic-Insurance .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(131, 81, 1);
}
.icon-mainDomestic-Insurance .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainDomestic-Insurance .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainDomestic-Insurance .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(131, 81, 1);
}
.icon-mainDomestic-Insurance .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(131, 81, 1);
}
.icon-mainDomestic-Insurance .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFamily-Insurance .path1:before {
  content: "\e95c";
  color: rgb(60, 196, 81);
  opacity: 0.3;
}
.icon-mainFamily-Insurance .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFamily-Insurance .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFamily-Insurance .path4:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFamily-Insurance .path5:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFamily-Insurance .path6:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainFamily-Insurance .path7:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainFamily-Insurance .path8:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainFile-a-claim:before {
  content: "\e964";
}
.icon-mainFile-Claim .path1:before {
  content: "\e965";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainFile-Claim .path2:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFile-Claim .path3:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFile-Claim .path4:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFile-Claim .path5:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainFile-Claim .path6:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainFile-Claim .path7:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainFile-Claim .path8:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainFile-Claim .path9:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainFlexi .path1:before {
  content: "\e96e";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainFlexi .path2:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainGold .path1:before {
  content: "\e970";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainGold .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainHome-Feature-1 .path1:before {
  content: "\e972";
  color: rgb(225, 52, 85);
  opacity: 0.3;
}
.icon-mainHome-Feature-1 .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-1 .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-1 .path4:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-1 .path5:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-1 .path6:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-1 .path7:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-2 .path1:before {
  content: "\e979";
  color: rgb(225, 52, 85);
  opacity: 0.3;
}
.icon-mainHome-Feature-2 .path2:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-2 .path3:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-2 .path4:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-2 .path5:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-2 .path6:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-2 .path7:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-2 .path8:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-2 .path9:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-3 .path1:before {
  content: "\e982";
  color: rgb(225, 52, 85);
  opacity: 0.3;
}
.icon-mainHome-Feature-3 .path2:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-3 .path3:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-3 .path4:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Feature-3 .path5:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(225, 52, 85);
}
.icon-mainHome-Feature-4 .path1:before {
  content: "\e987";
  color: rgb(225, 52, 85);
  opacity: 0.3;
}
.icon-mainHome-Feature-4 .path2:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-mainHome-Features:before {
  content: "\e989";
}
.icon-mainHome-Insurance .path1:before {
  content: "\e98a";
  color: rgb(250, 183, 104);
  opacity: 0.3;
}
.icon-mainHome-Insurance .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(250, 183, 104);
}
.icon-mainHome-Insurance .path3:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Insurance .path4:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(250, 183, 104);
}
.icon-mainHome-Insurance .path5:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHome-Insurance .path6:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainHuman-Safety:before {
  content: "\e990";
}
.icon-mainInsurance_Shield .path1:before {
  content: "\e991";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainInsurance_Shield .path2:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainInsurance_Shield .path3:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainInsurance_Shield .path4:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainMasterCard .path1:before {
  content: "\e995";
  color: rgb(35, 31, 32);
}
.icon-mainMasterCard .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 95, 0);
}
.icon-mainMasterCard .path3:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.icon-mainMasterCard .path4:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-mainMasterCard .path5:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-mainMedical-Features:before {
  content: "\e99a";
}
.icon-mainMedical-Insurance .path1:before {
  content: "\e99b";
  color: rgb(145, 243, 174);
  opacity: 0.3;
}
.icon-mainMedical-Insurance .path2:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(145, 243, 174);
}
.icon-mainMedical-Insurance .path3:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainMedical-Insurance .path4:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainMedical-Insurance .path5:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(145, 243, 174);
}
.icon-mainMedical-Insurance .path6:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(145, 243, 174);
}
.icon-mainMedical-Insurance .path7:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(145, 243, 174);
}
.icon-mainOthers:before {
  content: "\e9a2";
}
.icon-mainPackages:before {
  content: "\e9a3";
}
.icon-mainPersonal-Insurance .path1:before {
  content: "\e9a4";
  color: rgb(248, 197, 27);
  opacity: 0.3;
}
.icon-mainPersonal-Insurance .path2:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(248, 197, 27);
}
.icon-mainPersonal-Insurance .path3:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainPersonal-Insurance .path4:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(248, 197, 27);
}
.icon-mainPlatinium .path1:before {
  content: "\e9a8";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainPlatinium .path2:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainPolice-Report:before {
  content: "\e9aa";
  color: #00a9e7;
}
.icon-mainPolicies:before {
  content: "\e9ab";
}
.icon-mainResolve .path1:before {
  content: "\e9ac";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainResolve .path2:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainResolve .path3:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainResolve .path4:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainResolve .path5:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainResolve .path6:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainSafe:before {
  content: "\e9b2";
  color: #f7931d;
}
.icon-mainSearch-Insurance .path1:before {
  content: "\e9b3";
  color: rgb(247, 147, 29);
  opacity: 0.3;
}
.icon-mainSearch-Insurance .path2:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainSearch-Insurance .path3:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainSearch-Insurance .path4:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainSearch-Insurance .path5:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainStopwatch:before {
  content: "\e9b8";
  color: #00a9e7;
}
.icon-mainSupport:before {
  content: "\e9b9";
}
.icon-maintow-truck .path1:before {
  content: "\e9ba";
  color: rgb(0, 169, 231);
  opacity: 0.3;
}
.icon-maintow-truck .path2:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(48, 57, 64);
}
.icon-mainTravel-Feature-1 .path1:before {
  content: "\e9bc";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainTravel-Feature-1 .path2:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path3:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path4:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path5:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path6:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path7:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainTravel-Feature-1 .path8:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(247, 147, 29);
}
.icon-mainTravel-Feature-1 .path9:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-1 .path10:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(37, 44, 50);
}
.icon-mainTravel-Feature-2:before {
  content: "\e9c6";
  color: #ff9e1f;
}
.icon-mainTravel-Feature-3 .path1:before {
  content: "\e9c7";
  color: rgb(255, 158, 31);
  opacity: 0.3;
}
.icon-mainTravel-Feature-3 .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-3 .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-3 .path4:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-3 .path5:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-3 .path6:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainTravel-Feature-3 .path7:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainTravel-Feature-3 .path8:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-3 .path9:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 158, 31);
}
.icon-mainTravel-Feature-4 .path1:before {
  content: "\e9d0";
  color: rgb(35, 148, 251);
  opacity: 0.2;
}
.icon-mainTravel-Feature-4 .path2:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-4 .path3:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(35, 148, 251);
}
.icon-mainTravel-Feature-4 .path4:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(35, 148, 251);
}
.icon-mainTravel-Feature-4 .path5:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(35, 148, 251);
}
.icon-mainTravel-Feature-4 .path6:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(35, 148, 251);
}
.icon-mainTravel-Feature-4 .path7:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Feature-4 .path8:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(35, 148, 251);
}
.icon-mainTravel-Feature-5:before {
  content: "\e9d8";
  color: #f7931d;
}
.icon-mainTravel-Features:before {
  content: "\e9d9";
}
.icon-mainTravel-Insurance .path1:before {
  content: "\e9da";
  color: rgb(128, 212, 243);
  opacity: 0.3;
}
.icon-mainTravel-Insurance .path2:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(128, 212, 243);
}
.icon-mainTravel-Insurance .path3:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path4:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path5:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path6:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path7:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path8:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path9:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainTravel-Insurance .path10:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(128, 212, 243);
}
.icon-mainTrophy:before {
  content: "\e9e4";
  color: #00a9e7;
}
.icon-mainValidate .path1:before {
  content: "\e9e5";
  color: rgb(60, 196, 81);
  opacity: 0.3;
}
.icon-mainValidate .path2:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainValidate .path3:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainValidate .path4:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainValidate .path5:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainValidate .path6:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainValidate .path7:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainValidate .path8:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainValidate .path9:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mainValidate .path10:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(60, 196, 81);
}
.icon-mainVideo-Call:before {
  content: "\e9ef";
  color: #00a9e7;
}
.icon-mainVisa:before {
  content: "\e9f0";
  color: #1434cb;
}
.icon-mainWhatsapp-2 .path1:before {
  content: "\e9f1";
  color: rgb(0, 169, 231);
  opacity: 0.3;
}
.icon-mainWhatsapp-2 .path2:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(91, 104, 113);
}
.icon-mainWhatsapp-2 .path3:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(91, 104, 113);
}
